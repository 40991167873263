import { Button, Container, Grid, Modal, Paper, TypographyStylesProvider } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { MIME_TYPES } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetPhotosQuery } from 'src/store/reducers/all-reducers/galleryApiSlice';
import { useCreateNewsMutation, useUpdateNewsMutation } from 'src/store/reducers/all-reducers/newsApiSlice';
import { NewsFormCreateType, NewsFormResponseType } from 'src/types/news';
import { ButtonGroup, CustomDropzone, CustomInputText, CustomTextEditor } from 'src/ui-kit';

type Props = {
  news?: NewsFormResponseType;
};

export const NewsForm = ({ news }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createNews] = useCreateNewsMutation();
  const [updateNews] = useUpdateNewsMutation();

  const { data: allPhotos } = useGetPhotosQuery(Number(news?.photoAlbum?.id), {
    refetchOnMountOrArgChange: true,
  })

  const [opened, { close, open }] = useDisclosure(false);

  const [htmlContent, setHtmlContent] = useState('');

  const form = useForm<NewsFormCreateType>({
    initialValues: {
      contentEn: news?.content?.titleEn || '',
      contentKg: news?.content?.titleKg || '',
      contentRu: news?.content?.titleRu || '',
      dateFrom: (news && news.dateFrom) || "",
      titleEn: news?.titleEn || '',
      titleKg: news?.titleKg || '',
      titleRu: news?.titleRu || '',
      attachments: allPhotos || null,
      attachmentToDelete: []
    },
    validate: {
      titleKg: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      titleRu: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      titleEn: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      contentRu: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      contentKg: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      contentEn: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      dateFrom: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
    },
  });

  const handleSubmit = async ({ photoAlbum, ...values }: NewsFormCreateType) => {
    if (news) {
      await updateNews({ id: news.id, body: values })
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.success`,
            message: t`notification.updateMsg`,
            color: 'green',
          });
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    } else {
      await createNews({ photoAlbum, ...values, })
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.successMsg`,
            message: '',
            color: 'green',
          });
          form.reset();
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    }
  };

  const handlePreviewButton = (val: string) => {
    setHtmlContent(val);
    open();
  };

  return (
    <Container size="xl">
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Paper shadow="sm" p="xl">
          <Grid>
            <Grid.Col span={12}>
              <DateTimePicker
                {...form.getInputProps('dateFrom')}
                label={t`form.label.dateFrom`}
                error={form.errors.dateFrom}
                value={form.values.dateFrom ? new Date(form.values.dateFrom) : undefined}
                required
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleKg')}
                label={t`form.label.titleKg`}
                required
                error={form.errors.titleKg}
                formData={form.values.titleKg}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleRu')}
                label={t`form.label.titleRu`}
                required
                error={form.errors.titleRu}
                formData={form.values.titleRu}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleEn')}
                label={t`form.label.titleEn`}
                required
                error={form.errors.titleEn}
                formData={form.values.titleEn}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <CustomDropzone
                label={t`form.label.photo_collage`}
                error={form.errors.attachments}
                {...form.getInputProps('attachments')}
                accept={[MIME_TYPES.png, MIME_TYPES.jpeg]}
                photos={allPhotos}
                deletedPhotosId={form.values.attachmentToDelete}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <CustomTextEditor
                label={t`form.label.contentKg`}
                labelButton={
                  <Button
                    variant="subtle"
                    color="blue"
                    size="sm"
                    radius="sm"
                    ml={5}
                    onClick={() => handlePreviewButton(form.values.contentKg)}
                  >
                    {t`button.preview`}
                  </Button>
                }
                error={form.errors.contentKg}
                formData={form.values.contentKg}
                {...form.getInputProps('contentKg')}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <CustomTextEditor
                label={t`form.label.contentRu`}
                labelButton={
                  <Button
                    variant="subtle"
                    color="blue"
                    size="sm"
                    radius="sm"
                    ml={5}
                    onClick={() => handlePreviewButton(form.values.contentRu)}
                  >
                    {t`button.preview`}
                  </Button>
                }
                error={form.errors.contentRu}
                formData={form.values.contentRu}
                {...form.getInputProps('contentRu')}
              />
            </Grid.Col>
            <Grid.Col>
              <CustomTextEditor
                labelButton={
                  <Button
                    variant="subtle"
                    color="blue"
                    size="sm"
                    radius="sm"
                    ml={5}
                    onClick={() => handlePreviewButton(form.values.contentEn)}
                  >
                    {t`button.preview`}
                  </Button>
                }
                label={t`form.label.contentEn`}
                error={form.errors.contentEn}
                formData={form.values.contentEn}
                {...form.getInputProps('contentEn')}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <ButtonGroup onClickCancel={() => navigate(-1)} type="submit" />
            </Grid.Col>
          </Grid>
        </Paper>
      </form>
      <Modal opened={opened} onClose={close} size={'60%'} title="Предпросмотр">
        <TypographyStylesProvider>
          <div style={{ color: '#2D4263' }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </TypographyStylesProvider>
      </Modal>
    </Container>
  );
};
