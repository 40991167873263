import {
  Group,
  Pagination,
  Paper,
  ScrollArea,
  Select,
  Table,
} from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazySearchRequestQuery } from 'src/store/reducers/all-reducers/searchApiSlice';
import { useDeepEqualMemo } from '../../hooks';
import { CustomLoader } from '../custom-loader';
import { HoverText } from '../hover-text';

export type HeadCell<E> = {
  label: string;
  sort?: string;
  display?: boolean;
  render?: (entity: E) => ReactNode;
  afterRender?: Boolean;
};

type Props<E> = {
  headCells: HeadCell<E>[];
  searchApiUrl?: string;
  onRowClick?: (row: E) => void;
  extraFilter?: object;
  doubleClickPath?: (row: E) => void;
};

const initResponse = {
  data: { content: [], page: 0, totalElements: 0, totalPages: 0 },
  loading: false,
};

export const AppTable = <Entity extends {}>({
  headCells,
  searchApiUrl,
  onRowClick,
  extraFilter,
  doubleClickPath,
}: Props<Entity>) => {
  const { t } = useTranslation();

  const [searchRequest, { data: searchData, isLoading }] =
    useLazySearchRequestQuery();

  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const rowsPerPageOptions = ['10', '25'];
  const [[page, rowsPerPage], setPagination] = useState([0, 10]);
  const { content, totalElements } = searchData ?? initResponse.data;
  const localFilter = useDeepEqualMemo(extraFilter);

  const { classes } = useStyles();

  const search = async () => {
    return await searchRequest(
      {
        path: searchApiUrl,
        body: {
          filter: {
            ...localFilter,
          },
          pageRequest: {
            limit: rowsPerPage,
            page: page,
          },
          sorting: {
            sortBy: 'ID',
            sortDirection: 'ASC',
          },
        },
      },
      false,
    );
  };

  useEffect(() => {
    if (extraFilter) {
      setPagination([0, rowsPerPage]);
    }
    // @ts-ignore
  }, [extraFilter?.sidebarCode]);

  useEffect(() => {
    search();
  }, [page, rowsPerPage, localFilter]);

  const getPageRecordInfo = () => {
    const firstRowNum = page * rowsPerPage + 1;
    let lastRowNum = firstRowNum + content.length - 1;

    return `${firstRowNum} - ${lastRowNum} из ${totalElements}`;
  };

  const handleRowClick = (row: Entity) => onRowClick?.(row);

  const handleChangePage = (newPage: number) =>
    setPagination([newPage - 1, rowsPerPage]);

  const handleChangeRowsPerPage = (event: string | null) => {
    if (event) {
      setPagination([0, Number.parseInt(event)]);
    }
  };

  const handleDoubleClick = (row: Entity) => doubleClickPath?.(row);

  const header = (
    <Table.Tr p={10}>
      {headCells.map((headCell) => {
        if (!headCell.display) {
          return <Table.Th key={headCell.label}>{headCell.label}</Table.Th>;
        } else return null;
      })}
    </Table.Tr>
  );

  const bodyRows = content?.map((row: Entity, index: number) => (
    <tr
      key={index}
      onDoubleClick={() => handleDoubleClick(row)}
      style={{
        backgroundColor:
          selectedRow === index ? 'rgba(153,153,153,0.3)' : undefined,
      }}
      onClick={() => {
        handleRowClick(row);
        setSelectedRow(index);
      }}
    >
      {headCells.map((cell) => {
        if (!cell.display) {
          const value = cell.render?.(row);
          return (
            <td
              key={cell.label}
              style={{
                whiteSpace: "nowrap",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                padding: 10, maxWidth: "230px"
              }}
            >
              {cell.label === "№" ? index + 1 + page * rowsPerPage
                : value && value.toString().length > 50 ? (
                  <HoverText
                    width={350}
                    text={`${value.toString().slice(0, 50)}...`}
                    hoverText={value}
                  />
                ) : value}
            </td>
          );
        } else return null;
      })}
    </tr>
  ));

  return (
    <Paper radius="md" className={classes.paper}>
      <ScrollArea
        offsetScrollbars
        scrollbarSize={8}
        style={{ width: '100%', minHeight: '29.1rem' }}
      >
        {isLoading ? <CustomLoader /> : null}
        <Table
          captionSide="bottom"
          striped
          highlightOnHover
          verticalSpacing="sm"
        >
          <thead>{header}</thead>
          <tbody>{bodyRows}</tbody>
        </Table>
      </ScrollArea>
      <Group style={{ display: 'flex', justifyContent: 'right' }}>
        <p style={{ fontSize: 13 }}>{t`table.pagination.rowInPage`}: </p>
        <Select
          style={{ width: '60px', textAlign: 'center' }}
          variant="filled"
          radius="xl"
          size="xs"
          data={rowsPerPageOptions}
          value={rowsPerPage.toString()}
          onChange={(e) => handleChangeRowsPerPage(e)}
        />
        <p style={{ fontSize: 13 }}>{getPageRecordInfo()}</p>
        <Pagination
          total={Math.ceil(totalElements / rowsPerPage)}
          onChange={handleChangePage}
          size="xs"
          radius="lg"
          color={isLoading ? 'gray' : 'indigo'}
        />
      </Group>
    </Paper>
  );
};

const useStyles = createStyles(() => ({
  paper: {
    padding: '1rem',
    minWidth: '100%',
    minHeight: '37.8rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
  },
}));
