import { Avatar, Center, Container, Grid, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { base64ImgJpeg, base64ImgPng } from 'src/constants';
import { isBase64 } from 'src/hooks';
import { useCreateCardsMutation, useUpdateCardMutation } from 'src/store/reducers/all-reducers/cardApiSlice';
import { ButtonGroup, CustomInputNumber, CustomInputText, CustomSelect, CustomTextEditor, ImageUpload } from 'src/ui-kit';

type Props = {
  card?: CardType;
};

export const CardInfoForm = ({ card }: Props) => {
  const [image, setImage] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => { setImage(`${base64ImgPng}${card?.photo}`) }, [card?.photo])

  const [createCard] = useCreateCardsMutation();
  const [updateCard] = useUpdateCardMutation();

  const form = useForm<CardInfoFormType>({
    initialValues: {
      fullNameEn: card?.fullName?.titleEn || '',
      fullNameKg: card?.fullName?.titleKg || '',
      fullNameRu: card?.fullName?.titleRu || '',
      positionEn: card?.position?.titleEn || '',
      positionKg: card?.position?.titleKg || '',
      positionRu: card?.position?.titleRu || '',
      attachment: null,
      active: card?.active || true,
      shortBiographyEn: card?.shortBiography.titleEn || '',
      shortBiographyKg: card?.shortBiography.titleKg || '',
      shortBiographyRu: card?.shortBiography.titleRu || '',
      cardType: card?.cardType || 'PRESENT',
      significance: card?.significance || 0,
      laborActivityKg: card?.laborActivity?.titleKg,
      laborActivityRu: card?.laborActivity?.titleRu,
      laborActivityEn: card?.laborActivity?.titleEn,
      educationEn: card?.education?.titleEn,
      educationKg: card?.education?.titleKg,
      educationRu: card?.education?.titleRu,
    },
    validate: {},
  });

  const handleSubmit = async (values: CardInfoFormType) => {
    if (card) {
      await updateCard({ id: card.id, body: values })
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.success`,
            message: t`notification.updateMsg`,
            color: 'green',
          });
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data?.message,
            color: 'red',
          });
        });
    } else {
      await createCard(values)
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.successMsg`,
            message: '',
            color: 'green',
          });
          form.reset();
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data?.message,
            color: 'red',
          });
        });
    }
  };
  return (
    <Container size="xl">
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Paper shadow="sm" p="xl">
          <Grid>
            <Grid.Col span={{ base: 12, lg: 6, xl: 7 }} px={0}>
              <Grid.Col span={12}>
                <CustomSelect
                  onChange={(el) => {
                    el === t("form.label.personnel-history") ?
                      form.setFieldValue("cardType", "HISTORY") :
                      form.setFieldValue("cardType", "PRESENT")
                  }}
                  error={form.errors.cardType}
                  data={[t("form.label.personnel-history"), t("form.label.personnel-present")]}
                  placeholder={t("form.label.card-type")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <CustomInputNumber
                  max={2}
                  required
                  label={t`form.label.significance`}
                  error={form.errors.significance}
                  formData={form.values.significance}
                  {...form.getInputProps('significance')}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <CustomInputText
                  {...form.getInputProps('fullNameKg')}
                  label={t`form.label.fullNameKg`}
                  required
                  error={form.errors.fullNameKg}
                  formData={form.values.fullNameKg}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <CustomInputText
                  {...form.getInputProps('fullNameRu')}
                  label={t`form.label.fullNameRu`}
                  required
                  error={form.errors.fullNameRu}
                  formData={form.values.fullNameRu}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <CustomInputText
                  {...form.getInputProps('fullNameEn')}
                  label={t`form.label.fullNameEn`}
                  required
                  error={form.errors.fullNameEn}
                  formData={form.values.fullNameEn}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <CustomInputText
                  {...form.getInputProps('positionKg')}
                  label={t`form.label.positionKg`}
                  required
                  error={form.errors.positionKg}
                  formData={form.values.positionKg}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <CustomInputText
                  {...form.getInputProps('positionRu')}
                  label={t`form.label.positionRu`}
                  required
                  error={form.errors.positionRu}
                  formData={form.values.positionRu}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <CustomInputText
                  {...form.getInputProps('positionEn')}
                  label={t`form.label.positionEn`}
                  required
                  error={form.errors.positionEn}
                  formData={form.values.positionEn}
                />
              </Grid.Col>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6, xl: 5 }}>
              <Grid>
                <Grid.Col span={{ base: 12, md: 6, lg: 12 }}>
                  <Center>
                    <Avatar
                      w="auto"
                      h="340px"
                      alt="photo"
                      radius="md"
                      src={image ? (isBase64(image) ? image : `${base64ImgJpeg}${image}`) : "/assets/images/image.webp"}
                    />
                  </Center>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 6, lg: 12 }} style={{ display: "flex", alignItems: "center", width: "100%" }}>
                  <ImageUpload
                    error={form.errors.attachment}
                    {...form.getInputProps('attachment')}
                    setState={setImage}
                    fileType
                    clear
                    cropp
                    imageToCrop={image}
                  />
                </Grid.Col>
              </Grid>
            </Grid.Col>
            {form.values.cardType === 'PRESENT' && (
              <>
                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.shortBiographyRu`}
                    error={form.errors.shortBiographyRu}
                    formData={form.values.shortBiographyRu}
                    {...form.getInputProps('shortBiographyRu')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.shortBiographyKg`}
                    error={form.errors.shortBiographyKg}
                    formData={form.values.shortBiographyKg}
                    {...form.getInputProps('shortBiographyKg')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.shortBiographyEn`}
                    error={form.errors.shortBiographyEn}
                    formData={form.values.shortBiographyEn}
                    {...form.getInputProps('shortBiographyEn')}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.laborActivityRu`}
                    error={form.errors.laborActivityRu}
                    formData={form.values.laborActivityRu}
                    {...form.getInputProps('laborActivityRu')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.laborActivityKg`}
                    error={form.errors.laborActivityKg}
                    formData={form.values.laborActivityKg}
                    {...form.getInputProps('laborActivityKg')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.laborActivityEn`}
                    error={form.errors.laborActivityEn}
                    formData={form.values.laborActivityEn}
                    {...form.getInputProps('laborActivityEn')}
                  />
                </Grid.Col>

                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.educationRu`}
                    error={form.errors.educationRu}
                    formData={form.values.educationRu}
                    {...form.getInputProps('educationRu')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.educationKg`}
                    error={form.errors.educationKg}
                    formData={form.values.educationKg}
                    {...form.getInputProps('educationKg')}
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <CustomTextEditor
                    label={t`form.label.educationEn`}
                    error={form.errors.educationEn}
                    formData={form.values.educationEn}
                    {...form.getInputProps('educationEn')}
                  />
                </Grid.Col>
              </>
            )}
            <Grid.Col span={12}>
              <ButtonGroup onClickCancel={() => navigate(-1)} type="submit" />
            </Grid.Col>
          </Grid>
        </Paper>
      </form>
    </Container >
  );
};
