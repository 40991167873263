import { Box, Center, Grid, Loader } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteCardBg } from 'src/assets';
import { NoDataUi } from 'src/components';
import { useSortByField } from 'src/hooks';
import { useSearchPageMutation } from 'src/store/reducers/all-reducers/pageApiSlice';
import { RouteCard } from './route-card';

export const RouteCards = () => {
  const middleScreen = useMediaQuery("(max-width: 1000px)")
  const [pageData, setPageData] = useState<PageType[]>([]);
  const { i18n } = useTranslation()
  const { classes } = useStyles();
  const [getPagesByType, { isLoading }] = useSearchPageMutation();

  const getSocialLinks = useCallback(async () => {
    try {
      const response = await getPagesByType({
        filter: {
          pageListPosition: 'CARDS',
        },
        pageRequest: {
          limit: 6,
        },
      });
      if ('data' in response && 'content' in response.data)
        setPageData(response.data.content);
    } catch (error) {
      console.log(error);
    }
  }, [getPagesByType]);

  useEffect(() => {
    getSocialLinks();
  }, [getSocialLinks]);

  const sortedData: PageType[] = useSortByField(pageData, "significance")

  return (
    <Box className={classes.container} p={{ base: '40px 5%', xs: '40px 7%' }}>
      <Box className={middleScreen ? classes.bgimageMobile : classes.bgimage}>
        <RouteCardBg width={middleScreen ? 1700 : 1920} height={middleScreen ? 700 : 620} />
      </Box>
      <Grid style={{ minHeight: '50dvh' }} gutter={32}>
        {isLoading ?
          <Grid.Col span={12} mt={100}>
            <Center>
              <Loader color="blue" />
            </Center>
          </Grid.Col>
          :
          <>
            {!sortedData || sortedData.length === 0
              ?
              <Grid.Col span={12}>
                <NoDataUi />
              </Grid.Col>
              :
              sortedData?.map(
                (pages) =>
                  pages.pageListPosition === 'CARDS' && (
                    <Grid.Col
                      key={pages.id}
                      span={{ base: 12, md: 6, xl: 4 }}
                      style={{ display: 'flex', justifyContent: 'center', zIndex: 20 }}
                    >
                      <RouteCard
                        link={pages.link}
                        title={i18n.language === 'ru' ? pages?.titleRu : i18n.language === 'kg' ? pages?.titleKg : pages?.titleEn}
                        pageId={pages.id}
                        photo={pages.file}
                      />
                    </Grid.Col>
                  )
              )
            }
          </>
        }
      </Grid>
    </Box>
  );
};

const useStyles = createStyles(() => ({
  container: {
    position: "relative",
    marginBottom: "5rem",
    alignItems: "center",
    zIndex: 10,
  },
  bgimage: {
    position: 'absolute',
    right: 0,
    top: -5,
    zIndex: -1,
  },
  bgimageMobile: {
    position: 'absolute',
    right: -210,
    top: 150,
    zIndex: -1,
  }
}));
