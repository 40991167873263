import { Box, Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconEye, IconPencil, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTitleByLanguage } from 'src/locales';
import { useDeletePageMutation } from 'src/store/reducers/all-reducers/pageApiSlice';
import { InformationFilterType } from 'src/types/information';
import { AppTable, CustomModal, HeadCell, MenuDropDown } from 'src/ui-kit';
import { Path } from 'src/utils';

type Props = {
  parentId?: number | null;
  opened: boolean;
  filter?: InformationFilterType;
};

export const PageTable = ({ opened, parentId, filter }: Props) => {
  const { t } = useTranslation();

  const [pagesDelete] = useDeletePageMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [id, setId] = useState<number | null>();

  const handleOpen = (id: number) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await pagesDelete(id)
      .unwrap()
      .then(() => {
        showNotification({
          title: t`notification.success`,
          message: t`notification.updateMsg`,
          color: 'green',
        });
      })
      .catch((e: ErrorType) => {
        showNotification({
          title: t`notification.error`,
          message: e?.data.message,
          color: 'red',
        });
      });
    setOpen(false);
  };

  const actions = (id: number) => [
    {
      icon: IconPencil,
      title: t`actions.page.edit`,
      to: `/page/${id}/edit-page`,
    },
    {
      icon: IconEye,
      title: t`actions.news.view`,
      to: `/page/${id}`,
    },
    {
      icon: IconTrash,
      title: t`actions.news.delete`,
      onClick: () => handleOpen(id),
    },
  ];

  const headCells: HeadCell<PageType>[] = [
    {
      label: t`table.title`,
      render: (p) => getTitleByLanguage(p),
    },
    {
      label: t`table.pagePosition`,
      render: (p) => p.pageListPosition,
    },
    {
      label: t`actions.title`,
      render: (p) => (
        <MenuDropDown label={t`actions.title`} item={actions(p.id)} />
      ),
    },
  ];

  return (
    <Box style={{ marginLeft: opened ? 440 : 0, transition: '0.7s' }}>
      <AppTable<PageType>
        headCells={headCells}
        searchApiUrl={Path.Search.pageSearch}
        extraFilter={{
          parentId: parentId,
          ...filter,
        }}
      />
      <CustomModal
        labelSubmit={'delete'}
        open={open}
        handleClose={handleClose}
        onClick={handleDelete}
      >
        <Title
          order={3}
          fw={400}
          mt="5px"
          mb="2rem"
          style={{ textAlign: 'center' }}
        >
          {t`modal.deleted.page`}
        </Title>
      </CustomModal>
    </Box>
  );
};
