import { Box } from "@mantine/core"
import { CSSProperties, MouseEventHandler, ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

interface IProps {
    children: ReactNode
    to: string
    onClick?: MouseEventHandler<HTMLAnchorElement> | undefined
    style?: CSSProperties
    className?: string
}

export const RouteWithLang = ({ children, to, onClick, style, className }: IProps) => {
    const { i18n } = useTranslation()
    const language = i18n.language;
    const formattedTo = to.startsWith("/") ? to : `/${to}`;
    return (
        <Box component={Link} to={`/${language}${formattedTo}`} onClick={onClick} style={{ ...style }} className={className}>
            {children}
        </Box>
    )
}
