import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const googleAnalyticsStatisticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getStatistics: builder.query<GoogleAnalyticDataType, string>({
      query: () => ({
        url: Path.GoogleAnalyticData.getAllData,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetStatisticsQuery } = googleAnalyticsStatisticsApiSlice;
