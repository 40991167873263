import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { Grid } from '@mantine/core';
import {
  ButtonGroup,
  CustomInputText,
  CustomModal,
  CustomSelect,
} from 'src/ui-kit';

type Props = {
  open: boolean;
  filter?: CardInfoFilterType;
  handleClose: () => void;
  onReset: () => void;
  setSearchParams: Function;
};

export const CardInfoFilter = ({
  open,
  filter,
  handleClose,
  onReset,
  setSearchParams,
}: Props) => {
  const { t } = useTranslation();

  const form = useForm<CardInfoFilterType>({
    initialValues: {
      active: true,
      cardType: null,
      cardInfoTypeId: 0,
      email: '',
      fullName: '',
      phone: '',
      prosecutorOfficeId: 0,
      mapPointId: 0,
      regionId: 0,
    },
  });

  useEffect(() => {
    if (filter) {
      form.setValues({
        active: filter.active || true,
        cardInfoTypeId: filter.cardInfoTypeId || 0,
        email: filter.email || '',
        fullName: filter.fullName || '',
        phone: filter.phone || '',
        prosecutorOfficeId: filter.prosecutorOfficeId || 0,
        regionId: filter.regionId || 0,
      });
    }
  }, [filter]);

  const onClickReset = () => {
    form.reset();
    handleClose();
    onReset();
  };

  const handleSubmit = async (values: CardInfoFilterType) => {
    form.reset();
    handleClose();
    for (let key in values) {
      if (values[key as keyof CardInfoFilterType] === '') {
        delete values[key as keyof CardInfoFilterType];
      }
    }
    setSearchParams(values);
  };

  return (
    <CustomModal
      title={t`modal.filter`}
      open={open}
      handleClose={handleClose}
      button={false}
      size="xl"
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={4}>
            <CustomInputText
              {...form.getInputProps('fullName')}
              label={t`form.label.fullName`}
              error={form.errors.fullName}
              formData={form.values.fullName}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomSelect
              {...form.getInputProps('cardType')}
              error={form.errors.cardType}
              formData={form.values.cardType}
              data={['HISTORY', 'PRESENT']}
              placeholder="Карточка исторический или работаеть в данный момент"
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomInputText
              {...form.getInputProps('phone')}
              label={t`form.label.phone`}
              error={form.errors.phone}
              formData={form.values.phone}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomInputText
              {...form.getInputProps('email')}
              label={t`form.label.email`}
              error={form.errors.email}
              formData={form.values.email}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomInputText
              {...form.getInputProps('regionId')}
              label={t`form.label.region`}
              error={form.errors.regionId}
              formData={form.values.regionId}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomInputText
              {...form.getInputProps('prosecutorOfficeId')}
              label={t`form.label.prosecutorOfficeId`}
              error={form.errors.prosecutorOfficeId}
              formData={form.values.prosecutorOfficeId}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <CustomInputText
              {...form.getInputProps('cardInfoTypeId')}
              label={t`form.label.cardInfoTypeId`}
              error={form.errors.cardInfoTypeId}
              formData={form.values.cardInfoTypeId}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <ButtonGroup
              labelCancel="reset"
              labelSubmit="search"
              onClickCancel={onClickReset}
              type="submit"
            />
          </Grid.Col>
        </Grid>
      </form>
    </CustomModal>
  );
};
