import { Box } from '@mantine/core';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer, Header } from 'src/components';

export const Layout = () => {
  const { pathname } = useLocation();
  return (
    <Box className="bg-img" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {pathname !== '/sign-in' && <Header />}
      <Box>
        <Outlet />
      </Box>
      {pathname !== '/sign-in' && <Footer />}
    </Box>
  );
};
