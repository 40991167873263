import { Avatar, Center, Grid } from '@mantine/core';
import { ProfileViewBody } from './profile-view-body';
import { Employee } from 'src/types/employee';

type Props = {
  employeeData: Employee;
};

export const ProfileView = ({ employeeData }: Props) => {
  return (
    <Grid justify="space-between">
      <Grid.Col span={3}>
        <Center>
          <Avatar
            src={employeeData?.photo}
            alt={`photo`}
            radius="lg"
            mb="1rem"
            style={{ width: 260, height: 260 }}
          />
        </Center>
      </Grid.Col>
      <Grid.Col span={9}>
        {employeeData && <ProfileViewBody employeeData={employeeData} />}
      </Grid.Col>
    </Grid>
  );
};
