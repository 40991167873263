import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAppSelector } from '../use-app-selector';

export const useFileDownload = (url?: string) => {
  const token = useAppSelector((state) => state.auth.token);

  const fetchData = async (url: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_TEST}${url}`, {
      method: 'GET',
      headers: {
        authorization: 'Bearer ' + token,
      },
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const data = await response.blob();

    return data;
  };

  const $file = useQuery(['useFileDownload', url], async () => {
    if (!url) return;
    const data = await fetchData(url!);

    return URL.createObjectURL(data);
  });

  useEffect(() => {
    if (!$file.data) return;
    return () => URL.revokeObjectURL($file.data as string);
  }, [$file.data]);

  return {
    loading: $file.isLoading,
    fileUrl: $file.data,
    fetchData,
  };
};
