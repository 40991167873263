import { JSX } from 'react/jsx-runtime';
import {
  AddCardInfo,
  AddLink,
  AddNews,
  AddOffice,
  AddPage,
  CardInfo,
  CardInfoView,
  EditCardInfo,
  EditNews,
  EditOffice,
  EditPage,
  EditSocialLinks,
  HiddenPage,
  MainPage,
  News,
  NewsDetail,
  NewsPage,
  NewsView,
  NotFoundPage,
  Office,
  OfficeView,
  Page,
  PageConstruction,
  Profile,
  SignInPage,
  SocialLinkView,
  SocialLinksPage,
  UniversalPage,
} from './pages';
export interface RouteData {
  path: string;
  page: JSX.Element;
  public: boolean;
  child?: ReadonlyArray<RouteData>;
  children?: ReadonlyArray<RouteData>;
  isChild?: boolean;
}

export const ROUTESARR = (): RouteData[] => {
  return [
    {
      path: '/',
      page: <MainPage />,
      public: true,
    },
    {
      path: '/:lang',
      page: <MainPage />,
      public: true,
    },
    {
      path: '/:lang/public-news',
      page: <NewsPage />,
      public: true,
    },
    {
      path: '/:lang/public-news/:newsId',
      page: <NewsDetail />,
      public: true,
    },
    {
      path: '/:lang/pages/*',
      page: <UniversalPage />,
      public: true,
    },
    {
      path: 'hidden/:code',
      page: <HiddenPage />,
      public: true,
    },
    {
      path: 'office',
      page: <Office />,
      public: false,
    },
    {
      path: '/office/:officeId',
      page: <OfficeView />,
      public: false,
    },
    {
      path: '/office/add',
      page: <AddOffice />,
      public: false,
    },
    {
      path: '/office/:officeId/edit',
      page: <EditOffice />,
      public: false,
    },
    // Закрытая страница Cards
    {
      path: '/card_info',
      page: <CardInfo />,
      public: false,
    },
    {
      path: '/card_info/:cardId',
      page: <CardInfoView />,
      public: false,
    },
    {
      path: '/card_info/add',
      page: <AddCardInfo />,
      public: false,
    },
    {
      path: '/card_info/:cardId/edit',
      page: <EditCardInfo />,
      public: false,
    },
    // Закрытая страница ссылок
    {
      path: '/social_link',
      page: <SocialLinksPage />,
      public: false,
    },
    {
      path: '/social_link/add-link',
      page: <AddLink />,
      public: false,
    },
    {
      path: '/social_link/:socialLinksId/edit-social-link',
      page: <EditSocialLinks />,
      public: false,
    },
    {
      path: '/social_link/:socialLinksId',
      page: <SocialLinkView />,
      public: false,
    },
    // Авторизация
    {
      path: '/sign-in',
      page: <SignInPage />,
      public: true,
    },
    // Профиль
    {
      path: '/profile',
      page: <Profile />,
      public: false,
    },
    // Страница предупреждений
    {
      path: '/warning',
      page: <PageConstruction />,
      public: false,
    },
    // Страница новостей
    {
      path: '/news',
      page: <News />,
      public: false,
    },
    {
      path: '/news/:newsId',
      page: <NewsView />,
      public: false,
    },
    {
      path: '/news/add-news',
      page: <AddNews />,
      public: false,
    },
    {
      path: '/news/:newsId/edit-news',
      page: <EditNews />,
      public: false,
    },
    // Страница страниц
    {
      path: '/page',
      page: <Page />,
      public: false,
    },
    {
      path: '/page/add-page',
      page: <AddPage />,
      public: false,
    },
    {
      path: '/page/:pageId/edit-page',
      page: <EditPage />,
      public: false,
    },
    // страница ошибок
    {
      path: '/:lang/*',
      page: <NotFoundPage />,
      public: true,
    },
  ];
};
