
import { Button, FileButton } from '@mantine/core';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomImageCropper } from '../custom-image-cropper';

type BaseProps = {
  clear?: boolean;
  disabled?: boolean;
  defaultVal?: string | null;
  onChange?: (value: string | File | null) => void;
  fileType?: boolean;
  setState?: (value: string) => void;
};

type PropsWithCropp = BaseProps & {
  cropp: true;
  imageToCrop: string;
};

type PropsWithoutCropp = BaseProps & {
  cropp?: false;
  imageToCrop?: string;
};

type Props = PropsWithCropp | PropsWithoutCropp;

export const ImageUpload: FC<Props> = ({
  clear,
  disabled,
  onChange,
  defaultVal,
  fileType,
  setState,
  imageToCrop,
  cropp
}) => {
  const { t } = useTranslation()
  const [file, setFile] = useState<File | null>(
    defaultVal ? new File([defaultVal], 'image.png') : null,
  );
  const resetRef = useRef<() => void>(null);

  const clearFile = () => {
    setFile(null);
    setState && setState('');
    onChange && onChange(null);
    resetRef.current?.();
  };

  const handleSetImage = (image: File | null) => {
    setFile(image);

    if (image && !fileType) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onChange && onChange(reader.result as string);
      };
      reader.readAsDataURL(image);
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        setState && setState(reader.result as string);
      };
      image && reader.readAsDataURL(image);
      onChange && onChange(image ?? null);
    }
  };

  return (
    <>
      <Button.Group orientation='vertical' style={{ justifyContent: "center", gap: "1rem", width: "100%" }}>
        <FileButton
          resetRef={resetRef}
          onChange={handleSetImage}
          accept="image/png,image/jpeg"
        >
          {(props) => (
            <Button px={'xl'}
              disabled={disabled}
              {...props}
              style={{ backgroundColor: '#438891' }}
            >
              {t('button.uploadPhoto')}
            </Button>
          )}
        </FileButton>
        {cropp &&
          <CustomImageCropper
            imageToCrop={imageToCrop}
            buttonBgColor='#ebaf09'
            aspectRatio='9/16'
            onChange={handleSetImage}
          />
        }
        {clear && (
          <Button disabled={file ? false : true} onClick={clearFile}>
            {t('button.clear')}
          </Button>
        )}
      </Button.Group>
    </>
  );
};
