interface IProps {
    size?: number
    className?: string
}

export const MenuArrowSvg = ({ size = 16, className }: IProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <path d="M5 11L11 5" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            <path d="M4.66675 4.66675H11.3334V11.3334" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        </svg>
    )
}
