import { Box, Burger, Button, Center, Flex, Image, Popover } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IconEyeSvg, LogoKg, LogoRu, logo } from 'src/assets';
import { useAppDispatch } from 'src/hooks';
import { selectTitleForBreadCrumbs } from 'src/store/reducers';
import { useSearchPageMutation } from 'src/store/reducers/all-reducers/pageApiSlice';
import { ChangeLanguage } from 'src/ui-kit';
import { DynamicRoutes, LinksToSocialMedia } from 'src/widgets';
import { UnderHeader } from './under-header';

interface BVI {
  Bvi: any;
  isvek: any;
}

declare global {
  interface Window {
    isvek: BVI;
  }
}

export const Header = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch()

  const [getPagesByType, { data: pageList }] = useSearchPageMutation();

  useEffect(() => {
    try {
      getPagesByType({
        filter: {
          pageListPosition: 'HEADER',
          onlyParent: true,
        },
        pageRequest: {
          limit: 6,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [getPagesByType]);

  const bvi = window.isvek;
  useEffect(() => {
    new bvi.Bvi({
      target: '.bvi-class',

      fontSize: 24,

      theme: 'black',
    });
  }, [bvi.Bvi]);

  useEffect(() => {
    dispatch(selectTitleForBreadCrumbs(pageList))
  }, [dispatch, pageList])

  return (
    <>
      <Center
        h="84px"
        display="flex"
        style={{
          justifyContent: 'space-between',
          padding: '0 7%',
          backgroundColor: '#06297B',
          color: 'white',
          position: "fixed",
          width: "100%",
          zIndex: 100,
        }}
      >
        <Box style={{ display: 'inherit', gap: '5px' }}>
          <Link to={'/'}>
            <Image
              display={{ base: 'none', xs: 'flex' }}
              src={i18n.language === "ru" ? LogoRu : LogoKg}
              height={60}
              width="auto"
            />
            <Image display={{ base: 'block', xs: 'none' }} src={logo} />
          </Link>
        </Box>
        <Box display={{ base: 'none', xs: 'flex' }} style={{ gap: '1.5rem', alignItems: 'center' }}>
          <a href="/" className="bvi-class" style={{ color: '#868e96' }}>
            <Button variant="transparent" p={0}>
              <IconEyeSvg size={24} />
            </Button>
          </a>
          <LinksToSocialMedia />
          <ChangeLanguage />
        </Box>
        <Flex
          display={{ base: 'flex', xs: 'none' }}
          bg="#06297B"
          justify="center"
          style={{ borderRadius: '4px' }}
        >
          <Popover opened={opened} onClose={close} offset={{ mainAxis: 25 }}>
            <Popover.Target>
              <Burger color="white" opened={opened} onClick={opened ? close : open} p="5px" />
            </Popover.Target>
            <Popover.Dropdown w="100%" h="100%">
              <DynamicRoutes data={pageList?.content} close={close} />
              <Box w={"100%"} mt="2rem" style={{ display: "flex", justifyContent: "space-between" }}>
                <Box display={"flex"} style={{ gap: '1.5rem', alignItems: 'center' }}>
                  <a href="/" className="bvi-class" style={{ color: '#868e96' }}>
                    <Button variant="transparent" p={0}>
                      <IconEyeSvg size={24} color='#06297B' />
                    </Button>
                  </a>
                  <LinksToSocialMedia white={false} />
                </Box>
                <ChangeLanguage color='#06297B' />
              </Box>
            </Popover.Dropdown>
          </Popover>
        </Flex>
      </Center>
      <Center
        display={{ base: 'none', xs: 'flex' }}
        h="80px"
        style={{
          justifyContent: 'space-between',
          padding: '0 7%',
          color: '#101828',
          background: 'white',
          marginTop: "84px"
        }}
      >
        <UnderHeader pageList={pageList?.content} />
      </Center>
      <Center display={{ base: 'block', xs: 'none' }} h="80px" />
    </>
  );
};
