import { Box, Button, Center, Image, Modal, Text } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { useState } from "react"
import Cropper from 'react-easy-crop'
import { useTranslation } from "react-i18next"
import getCroppedImg from './crop-image'

type AspectRatio = "3/2" | "4/3" | "9/16" | "16/9" | "21/9"

const aspectRatioList = {
    "3/2": 3 / 2,
    "4/3": 4 / 3,
    "9/16": 9 / 16,
    "16/9": 16 / 9,
    "21/9": 21 / 9,
}

interface IProps {
    icon?: any;
    imageName?: string;
    imageToCrop: string;
    fullWidth?: boolean;
    buttonColor?: string;
    buttonBgColor?: string;
    aspectRatio: AspectRatio;
    onChange?: (value: File | null, index?: number) => void;
}

interface ICropped {
    height: number
    width: number
    x: number
    y: number
}

export const CustomImageCropper = (props: IProps) => {
    const { t } = useTranslation()
    const [opened, { open, close }] = useDisclosure(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedImage, setCroppedImage] = useState<string>()
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<ICropped | null>(null)

    const onCropComplete = (_croppedArea: ICropped, croppedAreaPixels: ICropped) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const showCroppedImage = async () => {
        if (croppedAreaPixels) {
            const croppedBlob = await getCroppedImg(props.imageToCrop, croppedAreaPixels);
            if (croppedBlob) {
                const imageUrl = URL.createObjectURL(croppedBlob);
                setCroppedImage(imageUrl);

                try {
                    const croppedFile = new File([croppedBlob], props.imageName ? props.imageName : "cropped-image.png", {
                        type: "image/png"
                    });

                    if (props.onChange) {
                        props.onChange(croppedFile);
                    }
                } catch (error) {
                    console.error("Error while converting blob to file", error);
                }
            }
        }
    };
    return (
        <>
            <Button fullWidth={props.fullWidth} bg={props.buttonBgColor} c={props.buttonColor} onClick={() => {
                open()
                setCroppedImage('')
            }}>
                {props.icon ? props.icon : t('button.cropp')}
            </Button>
            {props.imageToCrop &&
                <Modal opened={opened} onClose={close} title="CROPPING" centered size="70%" style={{ display: "flex", flexDirection: "column", alignContent: "space-between" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Box h={330} pos="relative" mb="md" w={"49%"}>
                            <Cropper
                                image={props.imageToCrop}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspectRatioList[props.aspectRatio]}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                        </Box>
                        <Box h={330} pos="relative" mb="md" w={"49%"} bg="#f0f0f0">
                            <Center h="100%">
                                {croppedImage ?
                                    <Image src={croppedImage} width="auto" h={330} alt="Cropped" />
                                    :
                                    <Text c="#b5b5b5">RESULT</Text>
                                }
                            </Center>
                        </Box>
                    </Box>
                    <Box>
                        <Button fullWidth onClick={showCroppedImage} mt="sm">
                            {t("button.result")}
                        </Button>
                    </Box>
                </Modal>
            }
        </>
    )
}
