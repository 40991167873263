import { Group } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '.';

type Props = {
  labelCancel?: string;
  labelSubmit?: string;
  onClickCancel?: MouseEventHandler<HTMLButtonElement>;
  onClickSubmit?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

export const ButtonGroup = ({
  labelCancel,
  labelSubmit,
  onClickCancel,
  onClickSubmit,
  type,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Group style={{ display: 'flex', justifyContent: 'center' }} mt={3}>
      <CustomButton
        label={labelCancel ? t(`actions.${labelCancel}`) : t`button.cancel`}
        onClick={onClickCancel}
        className={classes.button}
      />
      <CustomButton
        label={labelSubmit ? t(`actions.${labelSubmit}`) : t`button.submit`}
        onClick={onClickSubmit}
        disabled={disabled}
        type={type}
      />
    </Group>
  );
};

const useStyles = createStyles((theme) => ({
  button: {
    width: 140,
    fontWeight: 500,
    fontSize: 14,
    backgroundColor: '#4c515b',
    '&:hover': {
      color: theme.white,
      backgroundColor: '#383c42',
    },
  },
}));
