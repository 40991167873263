import { Button, Grid, Title } from '@mantine/core';
import { IconTopologyRing3 } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PageFilter, PageStructure, PageTable } from 'src/components';
import { usePageTitle } from 'src/hooks';
import { AppShellCustom } from 'src/ui-kit';

export const Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t`pages.title.page`;
  usePageTitle(title);

  const [open, setOpen] = useState<boolean>(false);
  const [parentId, setParentId] = useState<number | null>(null);
  const [childId, setChildId] = useState<number | null>(null);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<PageFilterType | undefined>(
    Object.fromEntries([...searchParams]),
  );

  useEffect(() => {
    const url = Object.fromEntries([...searchParams]);
    setFilter(url);
  }, [searchParams]);

  const onReset = () => {
    setSearchParams(new URLSearchParams());
  };

  useEffect(() => {
    childId && setParentId(null);
  }, [childId]);

  return (
    <AppShellCustom>
      <Title fw="normal" mb="1rem">
        {title}
      </Title>
      <Button.Group p={5}>
        <Button variant="filled" radius={10} onClick={() => setOpen((e) => !e)}>
          <IconTopologyRing3 size={18} style={{ marginRight: 6 }} />
          {t`button.structure`}
        </Button>
        <Button variant="filled" radius={10} onClick={() => setOpenFilter(true)}>
          {t`button.filter`}
        </Button>
        <Button variant="filled" radius={10} onClick={() => navigate('/page/add-page')}>
          {t`button.add.page`}
        </Button>
      </Button.Group>
      <Grid gutter="xs" w="100%">
        <Grid.Col span={12} style={{ position: 'relative' }}>
          <PageStructure open={open} setParentId={setParentId} setChildId={setChildId} />
          <PageTable opened={open} parentId={parentId || childId} filter={filter} />
        </Grid.Col>
      </Grid>
      <PageFilter
        open={openFilter}
        filter={filter}
        onReset={onReset}
        setSearchParams={setSearchParams}
        handleClose={() => setOpenFilter(false)}
      />
    </AppShellCustom>
  );
};
