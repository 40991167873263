export { authReducer, authSlice } from './auth-slice';
export { breadcrumbsReducer } from './breadcumbs-slice';
export {
  calendarReducer,
  resetDateSlice,
  setDateSlice,
} from './calendar-slice';
export {
  openBreadcrumbsReducer,
  selectTitleForBreadCrumbs,
} from './open-breadcumbs-slice';
export { sidebarReducer } from './sidebar-slice';
