import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import {
  IconChevronLeft,
  IconChevronRight,
  IconPencil,
} from '@tabler/icons-react';
import { Box, Collapse, Group, UnstyledButton } from '@mantine/core';
import { HoverText } from '../../ui-kit';
import { StructureItem } from './structure-item';
import { StructureChildrens } from 'src/types/global';
import { createStyles } from '@mantine/styles';

type parent = {
  id: number;
  title: string;
};

type Props = {
  parent: parent;
  children?: StructureChildrens[];
  setParentId?: (id: number | null) => void;
  setChildId?: (id: number | null) => void;
  active: string;
  setActive: (title: string) => void;
  button?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const StructureGroup = ({
  parent,
  children,
  setParentId,
  setChildId,
  active,
  setActive,
  button,
  onClick,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { classes, theme, cx } = useStyles({ organ: !!setParentId });
  const [opened, setOpened] = useState(false);

  const ChevronIcon = theme.dir === 'ltr' ? IconChevronRight : IconChevronLeft;

  const handleClick = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setOpened(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const onClickButton = (parent: parent) => {
    setActive(parent.title);
    setOpened((o) => !o);
    if (setParentId) {
      setParentId(parent.id);
      setChildId && setChildId(null);
    } else if (setChildId) {
      setChildId(parent.id);
    }
  };

  return (
    <div ref={ref}>
      <UnstyledButton
        onClick={() => onClickButton(parent)}
        className={cx(classes.control, {
          [classes.active]: active === parent.title,
        })}
      >
        <Group ml="xs">
          <Box>
            {parent.title.length > 38 ? (
              <HoverText
                text={parent.title.slice(0, 38) + '...'}
                hoverText={parent.title}
                width={300}
              />
            ) : (
              parent.title
            )}
          </Box>
          {children && (
            <ChevronIcon
              size={14}
              stroke={1.5}
              className={classes.chevron}
              style={{
                transform: opened
                  ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)`
                  : 'none',
              }}
            />
          )}
          {button && onClick && (
            <Box onClick={onClick}>
              <IconPencil
                size={21}
                style={{ color: 'rgba(0, 41, 111, 0.95)' }}
              />
            </Box>
          )}
        </Group>
      </UnstyledButton>
      {children && setChildId && (
        <Collapse in={opened} className={classes.children}>
          <StructureItem
            children={children}
            setChildId={setChildId}
            active={active}
            setActive={setActive}
          />
        </Collapse>
      )}
    </div>
  );
};

const useStyles = createStyles((theme, { organ }: { organ: boolean }) => ({
  control: {
    display: 'block',
    width: '100%',
    height: 30,
    padding: `1px`,
    color: theme.black,
    fontSize: theme.fontSizes.sm,
    fontWeight: organ ? 600 : 500,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
  children: {
    paddingLeft: 7,
    borderLeft: '1px solid #15aabf',
  },
  chevron: {
    transition: 'transform 0.3s ease',
  },
  active: {
    backgroundColor: '#f2f2f2',
  },
}));
