export { SignInForm, ChangePassword, ProfileView } from './profile';
export {
  SocialLinksTable,
  LinksForm,
  SocialLinksFilter,
  SocialLinkDetails,
} from './social-links';
export { NewsTable, NewsDetails, NewsFilter, NewsForm } from './news';
export { PageForm, PageTable, PageFilter, PageStructure } from './create-page';
export {
  CardInfoDetails,
  CardInfoFilter,
  CardInfoForm,
  CardInfoTable,
} from './card-info';
export { OfficeDetails, OfficeFilter, OfficeForm, OfficeTable } from './office';
