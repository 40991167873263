import { ActionIcon, Button, Container, Grid, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconTrash } from '@tabler/icons-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getTitleByLanguage } from 'src/locales';
import { useGetAllTypeCodeQuery } from 'src/store/reducers/all-reducers/commonReferenceApiSlice';
import { useCreateOfficeMutation, useUpdateOfficeMutation } from 'src/store/reducers/all-reducers/officeApiSlice';
import { ButtonGroup, CustomInputText, CustomSelect } from 'src/ui-kit';

type Props = {
  office?: OfficeResponseType;
};

export const OfficeForm = ({ office }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [createOffice] = useCreateOfficeMutation();
  const [updateOffice] = useUpdateOfficeMutation();

  const { data: mapPoints } = useGetAllTypeCodeQuery("REGIONS")

  const mapPointIDSelectData = mapPoints?.map((item) => {
    return {
      value: `${item.id}`,
      label: getTitleByLanguage(item),
    };
  });

  // {
  //   titleEn: office?.titleEn || '',
  //   titleKg: office?.titleKg || '',
  //   titleRu: office?.titleRu || '',
  //   addressEn: office?.addressEn || '',
  //   addressKg: office?.addressKg || '',
  //   addressRu: office?.addressRu || '',
  //   email: office?.email || '',
  //   phone: office?.phone || '',
  // }

  const form = useForm<OfficeRequestType>({
    initialValues: {
      regionId: office?.id || 0,
      titleEn: office?.titleEn || '',
      titleKg: office?.titleKg || '',
      titleRu: office?.titleRu || '',
      branches: office ? [...office.branches.map((item) => {
        return {
          titleEn: item?.titleEn || '',
          titleKg: item?.titleKg || '',
          titleRu: item?.titleRu || '',
          addressEn: item?.addressEn || '',
          addressKg: item?.addressKg || '',
          addressRu: item?.addressRu || '',
          email: item?.email || '',
          phone: item?.phone || '',
        }
      })] : []
    },
    validate: {
      titleEn: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      titleRu: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      titleKg: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      // email: (value: any) => {
      //   if (!value) return t`form.validation.required`;
      // },
      // phone: (value: any) => {
      //   if (!value) return t`form.validation.required`;
      // },
      regionId: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
    },
  });

  const branches = form.values.branches.map((_items, index) => (
    <Fragment key={index}>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.titleEn`)}
          label={t`form.label.officeTitleEn`}
          required
          error={form.errors.titleEn}
          formData={form.values.branches[index].titleEn}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.titleKg`)}
          label={t`form.label.officeTitleKg`}
          required
          error={form.errors.titleKg}
          formData={form.values.branches[index].titleKg}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.titleRu`)}
          label={t`form.label.officeTitleRu`}
          required
          error={form.errors.titleRu}
          formData={form.values.branches[index].titleRu}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.addressEn`)}
          label={t`form.label.detailAddressEn`}
          required
          error={form.errors.addressEn}
          formData={form.values.branches[index].addressEn}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.addressKg`)}
          label={t`form.label.detailAddressKg`}
          required
          error={form.errors.addressKg}
          formData={form.values.branches[index].addressKg}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.addressRu`)}
          label={t`form.label.detailAddressRu`}
          required
          error={form.errors.addressRu}
          formData={form.values.branches[index].addressRu}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.email`)}
          label={t`form.label.email`}
          required
          error={form.errors.email}
          formData={form.values.branches[index].email}
        />
      </Grid.Col>
      <Grid.Col span={4}>
        <CustomInputText
          {...form.getInputProps(`branches.${index}.phone`)}
          label={t`form.label.phone`}
          required
          error={form.errors.phone}
          formData={form.values.branches[index].phone}
        />
      </Grid.Col>
      <Grid.Col span={4} style={{ display: "flex", alignItems: "center", marginTop: "1rem" }}>
        <ActionIcon onClick={() => form.removeListItem('branches', index)}>
          <IconTrash size="5rem" color='red' />
        </ActionIcon>
      </Grid.Col>
      {/* <Divider w={"100%"} mt={20} size={"sm"} /> */}
    </Fragment >
  ))

  const handleSubmit = async (values: OfficeRequestType) => {
    if (office) {
      await updateOffice({ id: office?.id, body: values })
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.success`,
            message: t`notification.updateMsg`,
            color: 'green',
          });
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    } else {
      await createOffice(values)
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.successMsg`,
            message: '',
            color: 'green',
          });
          form.reset();
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    }
  };

  return (
    <Container size="xl">
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Paper shadow="sm" p="xl">
          <Grid>
            <Grid.Col span={12}>
              <CustomSelect
                {...form.getInputProps('regionId')}
                placeholder={t`form.label.regionId`}
                required
                error={form.errors.regionId}
                formData={form.values.regionId}
                data={mapPointIDSelectData ? mapPointIDSelectData : []}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleEn')}
                label={t`form.label.titleEn`}
                required
                error={form.errors.titleEn}
                formData={form.values.titleEn}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleKg')}
                label={t`form.label.titleKg`}
                required
                error={form.errors.titleKg}
                formData={form.values.titleKg}
              />
            </Grid.Col>
            <Grid.Col span={4} mb={20}>
              <CustomInputText
                {...form.getInputProps('titleRu')}
                label={t`form.label.titleRu`}
                required
                error={form.errors.titleRu}
                formData={form.values.titleRu}
              />
            </Grid.Col>

            {branches}

            <Grid.Col span={12}>
              <Button mt={20} variant='admin'
                onClick={() => form.insertListItem('branches', {
                  titleEn: '',
                  titleKg: '',
                  titleRu: '',
                  addressEn: '',
                  addressKg: '',
                  addressRu: '',
                  email: '',
                  phone: '',
                })}>
                {t("button.add-more-offices")}
              </Button>
            </Grid.Col>
            <Grid.Col span={12}>
              <ButtonGroup onClickCancel={() => navigate(-1)} type="submit" />
            </Grid.Col>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};
