import { BackgroundImage, Flex, Paper, Text, UnstyledButton } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { backgoundPhoto } from 'src/assets';
import { RouteWithLang } from 'src/components';
import { base64ImgJpeg } from 'src/constants';
import { AllPublishedNewsTypes } from 'src/types/news';
import { DateWithOptions } from 'src/ui-kit';

interface IProps {
  white?: boolean;
  data: AllPublishedNewsTypes;
}

export const NewsEventsCard = ({ data, white = false }: IProps) => {
  const { i18n, t } = useTranslation();
  const [printDate, setPrintDate] = useState('');
  const { hovered, ref: hoverRef } = useHover()
  const { classes } = useStyles();

  useEffect(() => {
    const datetext = new Date(data.dateFrom);
    setPrintDate(`${datetext}`);
  }, [data]);

  return (
    <RouteWithLang to={`/public-news/${data.id}`}>
      <Paper ref={hoverRef} variant="news-blue-card" h={{ base: 153, lg: 220, xl: 240 }}
        className={classes.paperStyle} p={{ base: '0.24rem 1rem', xs: '2em 1.5rem' }}
        style={{ background: !hovered ? 'rgba(255, 255, 255, 0.7)' : `${white ? "white" : "rgba(211, 216, 230, 0.50)"}` }}
      >
        <Flex gap={{ base: "0.5rem", md: "1rem" }} align="center" h="100%">
          {data.photoAlbum ? (
            <Flex align="center" w={{ lg: '50%' }}>
              <BackgroundImage src={`${base64ImgJpeg}${data.photoAlbum.photo}`} w={{ base: 100, lg: "160px", xl: "260px" }}
                h={{ base: 66, lg: 106, xl: 170 }} style={{ backgroundSize: 'cover' }} />
            </Flex>
          ) : (
            <Flex align="center" w={{ lg: '50%' }}>
              <BackgroundImage src={backgoundPhoto} w={{ base: 100, lg: "160px", xl: "260px" }} h={{ base: 66, lg: 106, xl: 170 }}
                style={{ backgroundSize: 'cover' }} />
            </Flex>
          )}
          <Flex direction="column" w={{ lg: '50%' }} h={"100%"} gap={{ base: "0.5rem", lg: "1rem" }}>
            <Flex direction="column" w={{ lg: '100%' }} h={"100%"} gap={{ base: "0.5rem", lg: "1rem" }}
              style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '430px' }}>
              <DateWithOptions printDate={printDate} style={{ fontWeight: 500 }} />
              <Text fz={{ base: "14px", md: "18px" }} fw={{ base: 400, lg: 500 }} lh={{ base: "17px", md: "19px", lg: "22px" }} c="#101828">
                {(i18n.language === 'ru' ? data?.titleRu : i18n.language === 'kg' ? data?.titleKg : data?.titleEn).slice(0, 130)}
              </Text>
            </Flex>
            <UnstyledButton fz={{ base: "14px", md: "18px" }}>{t("actions.news.view")}..</UnstyledButton>
          </Flex>
        </Flex>
      </Paper>
    </RouteWithLang>
  );
};

const useStyles = createStyles((_theme) => ({
  paperStyle: {
    // background: 'none',
    '&:hover': {
      background: '#f5f5f5',
    },
  },
  date: {
    color: '#8C8C8C',
    fontSize: '18px',
    fontWeight: 500,
    width: 'fit-content',
    borderRadius: '4px',
  },
}));
