import { Box, Flex, Input } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useClickOutside } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MenuArrowSvg, SearchSvg } from 'src/assets';
import { RouteWithLang } from 'src/components';
import { useSearchPagesNewsMutation } from 'src/store/reducers/all-reducers/newsPagesSearchApiSlice';

export const Search = () => {
  const [searchValue, setSearchValue] = useState('');
  const [result, setResult] = useState<PagesNewsSearchResponse | null>(null);
  const { t } = useTranslation()
  const { pathname } = useLocation();
  const { classes } = useStyles();

  const [searchPagesAndNewsByTitle] = useSearchPagesNewsMutation()

  const form = useForm({
    initialValues: {
      searchTitle: ""
    }
  })

  const ref = useClickOutside(() => setResult(null));

  const handleSubmit = async (title: string) => {
    try {
      const response = await searchPagesAndNewsByTitle({ filter: { title: title }, pageRequest: {} });
      if ('data' in response && 'content' in response.data) setResult(response.data as unknown as PagesNewsSearchResponse);
    } catch (error) { console.log(error); }
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleSubmit(searchValue);
  };

  useEffect(() => {
    setSearchValue('');
    setResult(null)
  }, [pathname]);

  return (
    <Flex right="0" align="center" justify="start" pos="relative" display="inline-block">
      <form onSubmit={form.onSubmit(() => handleSubmit(searchValue))}>
        <Input w="100%" radius="lg" size="lg" placeholder={`${t("main_page.button.search")}...`} rightSectionPointerEvents="all"
          value={searchValue} onChange={(event) => {
            setSearchValue(event.currentTarget.value)
            setResult(null)
          }} onKeyDown={handleKeyPress}
          rightSection={<SearchSvg color="rgba(4, 10, 11, 0.50)" onClick={() => handleSubmit(searchValue)} />}
        />
      </form>
      {result ?
        <Box className={classes.searchResultContainer} ref={ref}>
          {result?.content?.map((items) => (
            <RouteWithLang key={items.id} to={`${items.newsPageListType === "PAGE_LIST" ? `/pages/${items.pageListId}` : `/public-news/${items.newsId}`}`}>
              <Box className={classes.searchResult} onClick={() => setResult(null)}>
                <Box className={classes.resultContainer} fz={{ base: 14, md: 18 }}>
                  <MenuArrowSvg /> {items.titleRu}
                </Box>
              </Box>
            </RouteWithLang>
          ))}
          {result?.content.length < 1 && result !== null && <Box className={classes.noResult}>{t("main_page.results.no_result")}</Box>}
        </Box>
        : null
      }
    </Flex >
  );
};

const useStyles = createStyles({
  searchResultContainer: {
    background: '#F0F2F6',
    position: 'absolute',
    minWidth: "29rem",
    marginTop: "0.95rem"
  },
  searchResult: {
    display: "block",
    color: "#101828",
    stroke: "#101828",
    padding: "0.5rem 0.5rem",
    ":hover": {
      color: "#06297B",
      stroke: "#06297B",
    }
  },
  resultContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "1vw",
    gap: "0.5rem"
  },
  noResult: {
    display: "block",
    color: "#101828",
    padding: "0.5rem 0.5rem",
  }
});
