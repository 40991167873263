import { Box, Burger, Button, Flex, Popover, UnstyledButton } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconMessage } from '@tabler/icons-react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLinksSearchMutation } from 'src/store/reducers/all-reducers/socialLinkApiSlice';
import { Search } from 'src/ui-kit/search';
import { DynamicRoutes } from 'src/widgets';

const onlineAppealId = 17

interface IProps {
  pageList: PageType[] | null;
}

export const UnderHeader = ({ pageList }: IProps) => {
  const largeScreen = useMediaQuery(`(max-width: 1400px)`);
  const { t, i18n } = useTranslation()
  const [opened, { open, close }] = useDisclosure(false);
  const [getOnlineAppealBySocialLinkApi, { data: onlineAppeal }] = useLinksSearchMutation()

  useEffect(() => {
    try {
      getOnlineAppealBySocialLinkApi({
        filter: {
          linkTypeId: onlineAppealId,
        },
        pageRequest: {
          limit: 6,
        },
      });
    } catch (error) {
      console.log(error);
    }
  }, [getOnlineAppealBySocialLinkApi])
  return (
    <>
      {!largeScreen && <DynamicRoutes data={pageList} />}
      <Box style={{ display: 'flex', gap: '24px', alignItems: 'center', justifyContent: 'flex-end', width: '100%', boxSizing: 'border-box' }}>
        <Search />
        <Popover width={200} position="bottom" styles={{ dropdown: { border: "none" } }}>
          <Popover.Target>
            <Button leftSection={<IconMessage />} variant="light-blue" px={5} h={46}>
              {t("button.online_reception")}
            </Button>
          </Popover.Target>
          <Popover.Dropdown style={{
            border: 'none', backgroundColor: "#F0F2F6", display: "flex",
            flexDirection: "column", gap: "0.5rem", width: "20%", marginTop: "0.55rem", borderRadius: "0"
          }}>
            {onlineAppeal?.content.map((items, index) => (
              <UnstyledButton c="#101828" key={index} component='a' href={items.link} target='_blank'>
                {i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn}
              </UnstyledButton>
            ))}
          </Popover.Dropdown>
        </Popover>
        <Flex display={{ base: 'flex', xl: 'none' }} justify="center" p={3} bg="#06297B" style={{ borderRadius: '4px' }}>
          <Popover opened={opened} onClose={close} styles={{ dropdown: { border: "none" } }} offset={{ mainAxis: 23 }}>
            <Popover.Target>
              <Burger color="white" opened={opened} onClick={opened ? close : open} p="5px" />
            </Popover.Target>
            <Popover.Dropdown w="25rem" h="100%">
              <DynamicRoutes data={pageList} close={close} />
            </Popover.Dropdown>
          </Popover>
        </Flex>
      </Box >
    </>
  );
};
