import { apiSlice } from '../../apiSlice';
import { Path } from '../../../utils';

export const profileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateLang: builder.mutation({
      query: ({ systemLanguage }) => ({
        url: Path.Profile.updateLang,
        method: 'PUT',
        body: {
          systemLanguage,
        },
      }),
    }),
    updatePassword: builder.mutation({
      query: (data) => ({
        url: Path.Profile.passwordUpdate,
        method: 'PUT',
        body: {
          newPassword: data.newPassword,
          oldPassword: data.oldPassword,
        },
      }),
    }),
  }),
});

export const { useUpdateLangMutation, useUpdatePasswordMutation } =
  profileApiSlice;
