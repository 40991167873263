import { Box, Grid, Text } from '@mantine/core';
import { SystemProp, SpacingValue, createStyles } from '@mantine/styles';
import { useTranslation } from 'react-i18next';

type Props = {
  label: React.ReactNode;
  value?: React.ReactNode;
  display?: boolean;
  my?: SystemProp<SpacingValue>;
  spanFirst?: number;
  spanTwo?: number;
};

export const CustomText = ({
  label,
  value,
  display,
  my = 10,
  spanFirst = 6,
  spanTwo = 6,
}: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (display) {
    return (
      <Grid my={my} className={classes.box}>
        <Grid.Col span={spanFirst} px={8} py={4}>
          <Text fw={600}>{t(`${label}`)}:</Text>
        </Grid.Col>
        <Grid.Col span={spanTwo} px={8} py={4}>
          <Text>{value || '-'}</Text>
        </Grid.Col>
      </Grid>
    );
  }

  return (
    <>
      {
        <Box className={classes.box} py={2}>
          <Text fw={600}>{t(`${label}`)}:</Text>
          <Text>{value || '-'}</Text>
        </Box>
      }
    </>
  );
};

const useStyles = createStyles({
  box: {
    width: '99%',
    borderBottom: '1px solid #d5d3d3',
    fontSize: '13px',
    '& div': {
      wordWrap: 'break-word',
      wordBreak: 'break-word',
    },
  },
});
