import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const officeSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOffice: builder.mutation<OfficeResponseType, OfficeRequestType>({
      query: (body) => ({
        url: Path.Office.create,
        method: 'POST',
        body,
        cache: 'no-cache',
      }),
    }),
    getAllOffices: builder.query<OfficeResponseType[], unknown>({
      query: () => ({
        url: Path.Office.getAllWithoutChilds,
        method: 'GET',
        cache: 'no-cache',
      }),
      providesTags: ['Office'],
    }),
    getOffice: builder.query<OfficeResponseType, string>({
      query: (id) => ({
        url: Path.Office.getById(id),
        method: 'GET',
        cache: 'no-cache',
      }),
    }),
    updateOffice: builder.mutation<
      OfficeRequestType,
      { id: number; body: Partial<OfficeRequestType> }
    >({
      query: ({ id, body }) => ({
        url: Path.Office.update(id),
        method: 'PUT',
        cache: 'no-cache',
        body,
      }),
    }),
    deleteOffice: builder.mutation({
      query: (id) => ({
        url: Path.Office.delete(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Office'],
    }),
    officeSearch: builder.mutation<
      OfficeSearchResponse,
      OfficeSearchRequestType
    >({
      query: (postData) => ({
        url: Path.Office.search,
        method: 'POST',
        body: postData,
        cache: 'no-store',
      }),
      invalidatesTags: ['Office'],
    }),
  }),
});

export const {
  useCreateOfficeMutation,
  useDeleteOfficeMutation,
  useGetAllOfficesQuery,
  useLazyGetOfficeQuery,
  useOfficeSearchMutation,
  useUpdateOfficeMutation,
  useGetOfficeQuery,
} = officeSlice;
