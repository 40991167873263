import { Box, Flex } from '@mantine/core';
import { BlockWithPhoto, ExtraInfo, InfoSystems, NewsEvents, RouteCards, SubOrganizations, TerritorialDepartments } from 'src/components';

export const MainPage = () => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'hidden' }}>
      <BlockWithPhoto />
      <RouteCards />
      <NewsEvents />
      <InfoSystems />
      <Flex direction="column" p={{ base: '0 5%', xs: '0 7%' }}>
        <ExtraInfo />
        <TerritorialDepartments />
        <SubOrganizations />
      </Flex>
    </Box>
  );
};
