import { useTranslation } from 'react-i18next';
import { useGetPageStructureQuery } from 'src/store/reducers/all-reducers/pageApiSlice';
import { Structure } from 'src/ui-kit';

type Props = {
  open: boolean;
  setParentId: (id: number | null) => void;
  setChildId: (id: number | null) => void;
};

export const PageStructure = ({ open, setParentId, setChildId }: Props) => {
  const { t } = useTranslation();
  const { data } = useGetPageStructureQuery({}, { refetchOnMountOrArgChange: true, },);

  const item = data?.map((item: PageType) => ({
    id: item.id,
    key: item.id,
    titleKg: item.titleKg,
    titleRu: item.titleRu,
    children: item.childrens,
  }));

  return (
    <Structure
      title={t`data.employee.structure`}
      data={item}
      open={open}
      setParentId={setParentId}
      setChildId={setChildId}
    />
  );
};
