import { ActionIcon, Box, CopyButton, Tooltip } from '@mantine/core';
import { IconCheck, IconLink } from '@tabler/icons-react';
import {
  facebook,
  facebookB,
  instagramm,
  instagrammB,
  telegram,
  telegramB,
  youTube,
  youTubeB,
} from 'src/assets';
import styles from './styles.module.css';

interface IProps {
  white?: boolean;
  copy?: boolean;
  isBackground?: boolean;
}

export const LinksToSocialMedia = ({
  white = true,
  copy = false,
  isBackground = false,
}: IProps) => {
  const currentUrl = window.location.href;
  return (
    <Box style={{ gap: '18px', display: "flex" }}>
      {copy && (
        <CopyButton value={currentUrl} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? 'Copied' : 'Copy'}
              withArrow
              position="right"
            >
              <ActionIcon color={white ? 'gray' : '#06297B'} onClick={copy}>
                {copied ? <IconCheck /> : <IconLink />}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      )}
      {isBackground ? (
        <>
          <a
            href="https://www.instagram.com/minjustkg_official/"
            target="_blank"
            rel="noreferrer"
            className={styles.links2}
          >
            <img src={white ? instagramm : instagrammB} alt="insta" style={{ height: "20px" }} />
          </a>
          <a
            href="https://www.facebook.com/minjustkg"
            target="_blank"
            rel="noreferrer"
            className={styles.links2}
          >
            <img src={white ? facebook : facebookB} alt="facebok" style={{ height: "20px" }} />
          </a>
          <a
            href="https://t.me/minjustkg"
            target="_blank"
            rel="noreferrer"
            className={styles.links2}
          >
            <img src={white ? telegram : telegramB} alt="twit" style={{ height: "20px" }} />
          </a>
          <a
            href="https://www.youtube.com/user/minjust1"
            target="_blank"
            rel="noreferrer"
            className={styles.links2}
          >
            <img src={white ? youTube : youTubeB} alt="ytub" style={{ height: "20px" }} />
          </a>
        </>
      ) : (
        <>
          <a
            href="https://www.instagram.com/minjustkg_official/"
            target="_blank"
            rel="noreferrer"
            className={styles.links}
          >
            <img src={white ? instagramm : instagrammB} alt="insta" style={{ height: "20px" }} />
          </a>
          <a
            href="https://www.facebook.com/minjustkg"
            target="_blank"
            rel="noreferrer"
            className={styles.links}
          >
            <img src={white ? facebook : facebookB} alt="facebok" style={{ height: "20px" }} />
          </a>
          <a
            href="https://t.me/minjustkg"
            target="_blank"
            rel="noreferrer"
            className={styles.links}
          >
            <img src={white ? telegram : telegramB} alt="twit" style={{ height: "20px" }} />
          </a>
          <a
            href="https://www.youtube.com/user/minjust1"
            target="_blank"
            rel="noreferrer"
            className={styles.links}
          >
            <img src={white ? youTube : youTubeB} alt="ytub" style={{ height: "20px" }} />
          </a>
        </>
      )}
    </Box>
  );
};
