import { Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconEye, IconPencil, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getTitleByLanguage } from 'src/locales';
import { useNewsDeleteMutation } from 'src/store/reducers/all-reducers/newsApiSlice';
import { News, NewsFilterType } from 'src/types/news';
import {
  AppTable,
  CustomChip,
  CustomModal,
  DateFormatTo,
  HeadCell,
  MenuDropDown,
} from 'src/ui-kit';
import { Path } from 'src/utils';

export const NewsTable = ({ filter }: { filter?: NewsFilterType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [newsDelete] = useNewsDeleteMutation();

  const [id, setId] = useState<number | null>();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (id: number) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await newsDelete(id)
      .unwrap()
      .then(() => {
        showNotification({
          title: t`notification.success`,
          message: t`notification.updateMsg`,
          color: 'green',
        });
      })
      .catch((e: ErrorType) => {
        showNotification({
          title: t`notification.error`,
          message: e?.data?.message,
          color: 'red',
        });
      });
    setOpen(false);
  };

  const actions = (id: number) => [
    {
      icon: IconPencil,
      title: t`actions.news.edit`,
      to: `/news/${id}/edit-news`,
    },
    {
      icon: IconEye,
      title: t`actions.news.view`,
      to: `/news/${id}`,
    },
    {
      icon: IconTrash,
      title: t`actions.news.delete`,
      onClick: () => handleOpen(id),
    },
  ];

  const headCells: HeadCell<News>[] = [
    {
      label: t`table.title`,
      render: (n) => getTitleByLanguage(n),
    },
    {
      label: t`table.dateFrom`,
      render: (n) => (
        <CustomChip label={DateFormatTo(n.dateFrom, 'DD-MM-YYYY HH:mm')} />
      ),
    },
    {
      label: t`actions.title`,
      render: (n) => (
        <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
      ),
    },
  ];

  return (
    <>
      <AppTable<News>
        headCells={headCells}
        doubleClickPath={(n) => navigate(`${n.id}`)}
        searchApiUrl={Path.Search.newsSearch}
        extraFilter={filter}
      />
      <CustomModal
        labelSubmit={'news.delete'}
        open={open}
        handleClose={handleClose}
        onClick={handleDelete}
      >
        <Title
          order={3}
          fw={400}
          mt="5px"
          mb="2rem"
          style={{ textAlign: 'center' }}
        >
          {t`modal.deleted.news`}
        </Title>
      </CustomModal>
    </>
  );
};
