import { Carousel } from '@mantine/carousel';
import { Box, Center, Image, Loader, Text, rem } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BirdLogo } from 'src/assets';
import { NoDataUi, RouteWithLang } from 'src/components';
import { useSortByField } from 'src/hooks';
import { useSearchPageMutation } from 'src/store/reducers/all-reducers/pageApiSlice';

export const ExtraInfo = () => {
  const [pageData, setPageData] = useState<PageType[]>([]);
  const { i18n, t } = useTranslation()
  const [getPagesByType, { isLoading }] = useSearchPageMutation();

  const getSocialLinks = useCallback(async () => {
    try {
      const response = await getPagesByType({
        filter: {
          pageListPosition: 'CAROUSEL',
        },
        pageRequest: {
          limit: 30,
        },
      });
      if ('data' in response && 'content' in response.data)
        setPageData(response.data.content);
    } catch (error) {
      console.log(error);
    }
  }, [getPagesByType]);

  useEffect(() => {
    getSocialLinks();
  }, [getSocialLinks]);

  const sortedData: PageType[] = useSortByField(pageData, "significance")

  return (
    <Box style={{ width: '100%', marginBottom: "68px" }}>
      <Text fw={500} fz={{ base: 22, md: 32 }} c="#040A0B" mb="2.917dvw">
        {t("main_page.title.useful_links")}
      </Text>
      {isLoading ?
        <Center w="100%">
          <Loader color="blue" />
        </Center>
        :
        <>
          {!sortedData || sortedData.length === 0
            ?
            <Center w="100%">
              <NoDataUi />
            </Center>
            :
            <Carousel
              loop
              bg="none"
              height={180}
              slideGap="lg"
              align="start"
              style={{
                borderRadius: '2px',
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                MsUserSelect: "none",
                userSelect: "none",
              }}
              p={{ base: "0", sm: "0 2rem" }}
              styles={{ controls: { padding: "0" } }}
              slideSize={{ base: '100%', sm: '50%', md: "33.333333%", xl: '25%' }}
              nextControlIcon={<IconChevronRight style={{ width: rem(25), height: rem(25), color: '#101828' }} />}
              previousControlIcon={<IconChevronLeft style={{ width: rem(25), height: rem(25), color: '#101828' }} />}
            >
              {sortedData?.map((pages, index) =>
                pages.pageListPosition === 'CAROUSEL' && (
                  <Carousel.Slide key={pages.id} style={{ position: 'relative' }}>
                    {pages.link ? (
                      <Center w="100%" h="100%" px={14} style={{ position: "relative", textAlign: 'center', color: '#101828', background: "#FFFFFF", overflow: "hidden", borderRadius: "2px" }} component="a" href={pages.link} target="_blank">
                        <Text style={{ zIndex: 10 }} fz={{ base: 16, md: 20 }} fw={500}>
                          {i18n.language === 'ru' ? pages?.titleRu : i18n.language === 'kg' ? pages?.titleKg : pages?.titleEn}
                        </Text>
                        {index % 2 !== 0 && <Image src={BirdLogo} style={{ position: 'absolute', maxWidth: '14.5rem', top: '15%', zIndex: 0, right: "-15%" }} />}
                      </Center>
                    ) : (
                      <Center w="100%" h="100%" px={14} style={{ position: "relative", textAlign: 'center', color: '#101828', background: "#FFFFFF", overflow: "hidden", borderRadius: "2px" }}
                        component={RouteWithLang} to={`/pages/${pages.id}`} >
                        <Text style={{ zIndex: 10 }} fz={{ base: 16, md: 20 }} fw={500} px={{ base: "sm", sm: "0" }}>
                          {i18n.language === 'ru' ? pages?.titleRu : i18n.language === 'kg' ? pages?.titleKg : pages?.titleEn}
                        </Text>
                        {index % 2 !== 0 && <Image src={BirdLogo} style={{ position: 'absolute', maxWidth: '14.5rem', top: '15%', zIndex: 0, right: "-15%" }} />}
                      </Center>
                    )}
                  </Carousel.Slide>
                ),
              )}
            </Carousel>
          }
        </>
      }
    </Box >
  );
};
