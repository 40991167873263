import { Avatar, Center, Container, Grid, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { base64ImgPng } from 'src/constants';
import { getTitleByLanguage } from 'src/locales';
import { useGetAllTypeCodeQuery } from 'src/store/reducers/all-reducers/commonReferenceApiSlice';
import { useCreateLinkMutation, useUpdateLinksMutation } from 'src/store/reducers/all-reducers/socialLinkApiSlice';
import { ButtonGroup, CustomInputText, CustomSelect, ImageUpload } from 'src/ui-kit';

type Props = {
  links?: LinkResponseType;
};

export const LinksForm = ({ links }: Props) => {
  const [image, setImage] = useState<string>("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data: socialLinkID } = useGetAllTypeCodeQuery('LINK_TYPE', {
    refetchOnMountOrArgChange: true,
  });

  const socialLinksSelectData = socialLinkID?.map((items) => {
    return {
      value: `${items.id}`,
      label: getTitleByLanguage(items),
    };
  });

  useEffect(() => { setImage(`${base64ImgPng}${links?.photo}`) }, [links?.photo])

  const [createLink] = useCreateLinkMutation();
  const [updateLink] = useUpdateLinksMutation();

  const form = useForm<LinksRequestTypes>({
    initialValues: {
      titleKg: links?.titleKg || '',
      titleRu: links?.titleRu || '',
      titleEn: links?.titleEn || '',
      link: links?.link || '',
      linkTypeId: `${links?.linkType.id}` || '7',
      photo: null,
    },
    validate: {
      titleKg: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      titleRu: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      titleEn: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
      link: (value: any) => {
        if (!value) return t`form.validation.required`;
      },
    },
  });

  const handleSubmit = async (values: LinksRequestTypes) => {
    if (links) {
      await updateLink({ id: links?.id, body: values })
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.success`,
            message: t`notification.updateMsg`,
            color: 'green',
          });
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    } else {
      await createLink(values)
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.successMsg`,
            message: ``,
            color: 'green',
          });
          form.reset();
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    }
  };

  return (
    <Container size="xl">
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Paper shadow="sm" p="xl">
          <Grid>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleRu')}
                label={t`form.label.titleRu`}
                required
                error={form.errors.titleRu}
                formData={form.values.titleRu}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleKg')}
                label={t`form.label.titleKg`}
                required
                error={form.errors.titleKg}
                formData={form.values.titleKg}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                {...form.getInputProps('titleEn')}
                label={t`form.label.titleEn`}
                required
                error={form.errors.titleEn}
                formData={form.values.titleEn}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CustomSelect
                {...form.getInputProps('linkTypeId')}
                placeholder={t`form.label.linkTypeId`}
                required
                error={form.errors.linkTypeId}
                formData={form.values.linkTypeId}
                data={socialLinksSelectData ? socialLinksSelectData : []}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CustomInputText
                {...form.getInputProps('link')}
                label={t`form.label.social_linkLink`}
                required
                error={form.errors.link}
                formData={form.values.link}
              />
            </Grid.Col>
            {form.values.linkTypeId === "6" &&
              <Grid.Col span={12}>
                <Center>
                  <Avatar
                    src={image}
                    alt={`photo`}
                    radius="lg"
                    mb="1rem"
                    style={{ width: 300, height: 290 }}
                  />
                </Center>
                <ImageUpload
                  error={form.errors.photo}
                  {...form.getInputProps('photo')}
                  setState={setImage}
                  fileType
                />
              </Grid.Col>
            }
            <Grid.Col span={12}>
              <ButtonGroup onClickCancel={() => navigate(-1)} type="submit" />
            </Grid.Col>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};
