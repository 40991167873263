import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { Grid } from '@mantine/core';
import { ButtonGroup, CustomInputText, CustomModal } from 'src/ui-kit';

type Props = {
  open: boolean;
  filter?: SanctionFilterType;
  handleClose: () => void;
  onReset: () => void;
  setSearchParams: Function;
};

export const PageFilter = ({
  open,
  filter,
  handleClose,
  onReset,
  setSearchParams,
}: Props) => {
  const { t } = useTranslation();

  const form = useForm<PageFilterType>({
    initialValues: {
      title: '',
    },
  });

  useEffect(() => {
    if (filter) {
      form.setValues({
        title: filter.title || '',
      });
    }
  }, [filter]);

  const onClickReset = () => {
    form.reset();
    handleClose();
    onReset();
  };

  const handleSubmit = async (values: SanctionFilterType) => {
    form.reset();
    handleClose();
    for (let key in values) {
      if (values[key as keyof SanctionFilterType] === '') {
        delete values[key as keyof SanctionFilterType];
      }
    }
    setSearchParams(values);
  };

  return (
    <CustomModal
      title={t`modal.filter`}
      open={open}
      handleClose={handleClose}
      button={false}
      size="xl"
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Grid>
          <Grid.Col span={12}>
            <CustomInputText
              {...form.getInputProps('title')}
              label={t`form.label.title`}
              error={form.errors.title}
              formData={form.values.title}
            />
          </Grid.Col>
          <Grid.Col span={12}>
            <ButtonGroup
              labelCancel="reset"
              labelSubmit="search"
              onClickCancel={onClickReset}
              type="submit"
            />
          </Grid.Col>
        </Grid>
      </form>
    </CustomModal>
  );
};
