import { Box, Image, Text } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { NoDataImage } from "src/assets"

export const NoDataUi = () => {
    const { t } = useTranslation()
    return (
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <Image src={NoDataImage} alt="No-Data" w={{ base: 202, md: 265 }} />
            <Text c="#06297B" fz={24}>
                {t("notification.no-data")}
            </Text>
        </Box>
    )
}
