import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@mantine/core';
import { useGetLinkQuery } from 'src/store/reducers/all-reducers/socialLinkApiSlice';
import { CustomLoader, CustomText, TextInfo } from 'src/ui-kit';
import { createStyles } from '@mantine/styles';

type Props = {
  socialLinksId: string;
};

export const SocialLinkDetails = ({ socialLinksId }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data: linkData, isLoading } = useGetLinkQuery(socialLinksId, {
    refetchOnMountOrArgChange: true,
  });

  const organCard = [
    {
      label: t`data.news.title`,
      value: linkData?.titleRu,
    },
  ];

  return isLoading ? (
    <CustomLoader />
  ) : (
    <Paper shadow="sm" p="xl">
      <Paper shadow="sm" p="xl">
        <TextInfo data={organCard} display={true} spanFirst={4} spanTwo={8} />
        {linkData && (
          <Box className={classes.content}>
            <CustomText
              label={t`data.news.content`}
              value={
                <div dangerouslySetInnerHTML={{ __html: linkData.link }} />
              }
            />
          </Box>
        )}
      </Paper>
    </Paper>
  );
};

const useStyles = createStyles({
  content: {
    '& img': {
      maxWidth: '70rem',
    },
  },
});
