export const useSortByField = <T>(objects: T[], field: keyof T): T[] => {
  let sortedObjects: T[] = [];
  if (objects) {
    sortedObjects = objects
      .filter(
        (item) =>
          typeof item[field] === 'number' && (item[field] as number) >= 0,
      )
      .slice()
      .sort((a, b) => {
        const aValue = a[field] as number;
        const bValue = b[field] as number;
        return bValue - aValue;
      });
  }
  return sortedObjects;
};
