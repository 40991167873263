import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: Path.Auth.signIn,
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    logOut: builder.mutation({
      query: () => ({
        url: Path.Auth.signOut,
        method: 'POST',
      }),
    }),
    registration: builder.mutation({
      query: (data) => ({
        url: Path.Auth.registrationAuth,
        method: 'POST',
        body: { ...data },
      }),
    }),
    otpVerification: builder.mutation({
      query: (data) => ({
        url: Path.Auth.OtpVerification,
        method: 'POST',
        body: { ...data },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLogOutMutation,
  useRegistrationMutation,
  useOtpVerificationMutation,
} = authApiSlice;
