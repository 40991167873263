const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // важная часть для работы с изображениями из разных источников
    image.src = url;
  });

export default async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any
): Promise<Blob | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) return null;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // Установка размеров canvas
  canvas.width = safeArea;
  canvas.height = safeArea;

  // Центрирование изображения на canvas
  ctx.drawImage(
    image,
    safeArea / 2 - image.width / 2,
    safeArea / 2 - image.height / 2
  );

  // Обрезка изображения
  const data = ctx.getImageData(
    safeArea / 2 - image.width / 2 + pixelCrop.x,
    safeArea / 2 - image.height / 2 + pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // Установка размеров canvas на размеры обрезанного изображения
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // Вставка данных обратно на canvas
  ctx.putImageData(data, 0, 0);

  // Конвертация canvas в BLOB
  return new Promise<Blob | null>((resolve) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        resolve(null);
      }
    }, "image/jpeg");
  });
}
