import { useEffect, useState } from 'react';
import { Button, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { usePageTitle } from 'src/hooks';
import { AppShellCustom } from 'src/ui-kit';
import { OfficeFilter, OfficeTable } from 'src/components';

export const Office = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const title = t`pages.title.office`;
  usePageTitle(title);

  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<OfficeFilterType | undefined>(
    Object.fromEntries([...searchParams]),
  );

  useEffect(() => {
    const url = Object.fromEntries([...searchParams]);
    setFilter(url);
  }, [searchParams]);

  const onReset = () => {
    setSearchParams(new URLSearchParams());
  };

  return (
    <AppShellCustom>
      <Title fw="normal" mb="1rem">
        {title}
      </Title>
      <Button.Group p={5}>
        <Button variant="filled" radius={10} onClick={() => setOpen(true)}>
          {t`button.filter`}
        </Button>
        <Button
          variant="filled"
          radius={10}
          onClick={() => navigate('/office/add')}
        >
          {t`button.add.office`}
        </Button>
      </Button.Group>
      <OfficeTable filter={filter} />
      <OfficeFilter
        open={open}
        filter={filter}
        onReset={onReset}
        setSearchParams={setSearchParams}
        handleClose={() => setOpen(false)}
      />
    </AppShellCustom>
  );
};
