import { Box, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

export const Bottom = () => {
  const screenSize = useMediaQuery('(max-width: 1200px)');
  const { t } = useTranslation()
  return (
    <Box
      style={{
        color: '#040A0B',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
        width: '100%',
        flexDirection: screenSize ? 'column' : 'row',
        gap: '1rem',
        flexWrap: 'wrap',
      }}
    >
      <Box
        style={{
          color: '#040A0B',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'flex-start',
          flexDirection: screenSize ? 'column' : 'row',
          gap: '1rem',
          flexWrap: 'wrap',
        }}
      >
        <Text fz={13}>
          {t("main_page.footer.info_disclose")}
        </Text>
        <Text fz={13}>
          {t("main_page.footer.terms_use")}
        </Text>
        <Text fz={13}>
          {t("main_page.footer.privacy_policy")}
        </Text>
      </Box>
      <Text fz={13}>
        {t("main_page.footer.minjust_2024")}
      </Text>
    </Box>
  );
};
