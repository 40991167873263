import { useTranslation } from 'react-i18next';
import { getTitleByLanguage } from 'src/locales';
import { Employee } from 'src/types/employee';
import { TextInfo } from 'src/ui-kit';

type Props = {
  employeeData: Employee;
};

export const ProfileViewBody = ({ employeeData }: Props) => {
  const { t } = useTranslation();

  const employeeCard = [
    {
      label: t`data.employee.inn`,
      value: employeeData.inn,
    },
    {
      label: t`data.employee.login`,
      value: employeeData.login,
    },
    {
      label: t`data.employee.full-name`,
      value: employeeData.fullName,
    },
    {
      label: t`data.employee.gender`,
      value: getTitleByLanguage(employeeData.gender),
    },
    {
      label: t`data.employee.dateOfBirth`,
      value: `${employeeData.dateOfBirth}`,
    },
    {
      label: t`data.employee.pointCode`,
      value: t(`point.${employeeData?.pointCode}`),
    },
    {
      label: t`data.employee.roles`,
      value: employeeData.roles
        ?.map((item) => getTitleByLanguage(item))
        .join(', '),
    },
    {
      label: t`data.employee.permissions`,
      value: employeeData.permissions
        ?.map((item) => getTitleByLanguage(item))
        .join(', '),
    },
  ];

  return <TextInfo data={employeeCard} display={true} />;
};
