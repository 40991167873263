import { Flex } from "@mantine/core";
import { IconCategory2 } from "@tabler/icons-react";
import { Dispatch, SetStateAction } from "react";
import { useLocalStorage } from "src/hooks";
import { MenuItem } from "./menu-item";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const Sidebar = ({ open, setOpen }: Props) => {
  const profile: Profile = useLocalStorage("profile").getStorage()
  return (
    <Flex direction="column" align='center' w="100%" p="md">
      <Flex style={{ cursor: "pointer" }} mb={20} justify="flex-end" w={open ? "100%" : 30} onClick={() => setOpen(!open)}>
        <IconCategory2 style={{ transition: "350ms", transform: `rotateZ(${open ? "90deg" : "0deg"})` }} size={30} color="#fff" />
      </Flex>
      <Flex direction="column" w="100%" gap={10} c={"white"}>
        {profile?.menu?.map((item) => <MenuItem key={item.parent} item={item.parent} open={open} setOpen={setOpen} />)}
      </Flex>
    </Flex>
  );
};