import { Center, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { LinksForm } from 'src/components';
import { usePageTitle } from 'src/hooks';
import { useGetLinkQuery } from 'src/store/reducers/all-reducers/socialLinkApiSlice';
import { AppShellCustom, CustomLoader } from 'src/ui-kit';

type Params = {
  socialLinksId: string;
};

export const EditSocialLinks = () => {
  const { t } = useTranslation();
  const title = t`pages.title.edit-links`;
  usePageTitle(title);

  const { socialLinksId } = useParams<Params>();
  const { data, isLoading } = useGetLinkQuery(
    socialLinksId as unknown as string,
    {
      refetchOnMountOrArgChange: !!socialLinksId,
    },
  );

  return (
    <AppShellCustom>
      {isLoading ? (
        <CustomLoader />
      ) : (
        data && (
          <>
            <Center>
              <Title fw="normal" mb="1rem">
                {title}
              </Title>
            </Center>
            <LinksForm links={data} />
          </>
        )
      )}
    </AppShellCustom>
  );
};
