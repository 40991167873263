import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const commonReferenceApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTypeCode: builder.query<GetAllByTypeCodeType[], string>({
      query: (code) => ({
        url: Path.CommonReference.getAllTypeCode(code),
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAllTypeCodeQuery } = commonReferenceApiSlice;
