import { Menu, Text } from '@mantine/core';
import { Link } from 'react-router-dom';
import { MenuItemType } from 'src/types/global';
import styles from './dropdown.module.scss';

type Props = {
  item: MenuItemType;
};

export const MenuItem = ({ item }: Props) => {
  if (item.to) {
    return (
      <Menu.Item
        className={styles.item}
        fz={16}
        fw={400}
        c="primary"
        to={item.to}
        px={0}
        state={item.roadmapId}
        component={Link}
        leftSection={item.icon && <item.icon size={14} />}
      >
        <Text className={styles.item_title}>{item.title}</Text>
      </Menu.Item>
    );
  }

  return (
    <Menu.Item
      className={styles.item}
      fz={16}
      fw={400}
      c="primary"
      leftSection={item.icon && <item.icon size={14} />}
      onClick={item.onClick}
    >
      <Text className={styles.item_title}>{item.title}</Text>
    </Menu.Item>
  );
};
