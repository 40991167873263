import { Box, Button, Flex, Pagination, Popover, Text, Title } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteCardBg } from 'src/assets';
import { CalendarSvg } from 'src/assets/calendar-svg';
import { NewsPageResult } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { resetDateSlice } from 'src/store/reducers';
import { useNewsSearchMutation } from 'src/store/reducers/all-reducers/newsApiSlice';
import { RouteTitles } from 'src/ui-kit';
import { CalendarWithPaper } from 'src/widgets';

export const NewsPage = () => {
  const middleScreen = useMediaQuery("(max-width: 1000px)")
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [opened, { open, close }] = useDisclosure(false);
  const [date, setDate] = useState<Date | null>(null);
  const [page, setPage] = useState(1);
  const calendarDateFromStore = useAppSelector((state) => state.calendarStore)
  const dispatch = useAppDispatch()

  const [searchNews, { data: newsData, isLoading }] = useNewsSearchMutation();

  const handleClickOnTitle = () => {
    dispatch(resetDateSlice())
    setDate(null)
    setPage(1)
  }

  const getSearchNews = (date: Date | null, page: number) => {
    try {
      searchNews({
        filter: { publishedAt: date?.toLocaleDateString() },
        pageRequest: { limit: 5, page: page - 1 },
        sorting: {
          sortBy: "PUBLISHED_AT",
          sortDirection: "DESC"
        }
      });
    } catch (error) {
      console.error('Error in fetching newsData');
    }
  };

  useEffect(() => {
    let date: Date | null = null;
    if (calendarDateFromStore.date) {
      date = new Date(calendarDateFromStore.date);
      setDate(date);
    }
    getSearchNews(date, page);
  }, [calendarDateFromStore.date, page]);

  return (
    <Box className={classes.mainContainer}>
      <RouteTitles />
      <Box>
        <Flex justify="space-between" align="center">
          <Text fz={{ base: 24, md: 32 }} style={{ cursor: "pointer" }} c="#06297B" mb="2.5rem" fw={500} onClick={handleClickOnTitle}>
            {t("main_page.title.news_anons")}
          </Text>
          <Popover position="bottom-end" opened={opened} onClose={close}
            styles={{ dropdown: { border: "none" } }}>
            <Popover.Target>
              <Button
                onClick={opened ? close : open} display={{ base: 'block', lg: 'none' }}
                bg="none"
                mt={-35}
                variant="simple"
                rightSection={<CalendarSvg size={25} />}
              >
                <Title display={{ base: 'none', sm: 'block' }} order={5} fw={400} mr={10} c="#101828">
                  {t("titles.news-dates")}
                </Title>
              </Button>
            </Popover.Target>
            <Popover.Dropdown w={"fit-content"}>
              <CalendarWithPaper
                onClose={close}
                date={date}
                setDate={setDate}
                variant="bg-opacity2"
                searchNews={() => getSearchNews(date, page)}
              />
            </Popover.Dropdown>
          </Popover>
        </Flex>
      </Box>
      <Box className={classes.contentContainer}>
        <Box className={middleScreen ? classes.bgimageMobile : classes.bgimage}>
          <RouteCardBg width={middleScreen ? 1200 : 1920} height={middleScreen ? 500 : 620} />
        </Box>
        <Flex w="100%" direction="column" gap={50} align="center" style={{ zIndex: 10 }}>
          <NewsPageResult isLoading={isLoading} newsData={newsData} />
          {(newsData?.content && newsData?.content.length > 0)
            && <Pagination c="dark" total={newsData?.totalPages || 1} value={page || 1} onChange={setPage} style={{ zIndex: "10" }} />
          }
        </Flex>
        <Box display={{ base: "none", lg: "block" }} className={classes.calendarContainer}>
          <CalendarWithPaper date={date} setDate={setDate} variant="bg-opacity2" searchNews={() => getSearchNews(date, page)} />
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = createStyles(() => ({
  mainContainer: {
    width: '100%',
    minHeight: '100dvh',
    marginBottom: '12.5rem',
    overflow: 'hidden',
    marginTop: "1rem",
    padding: "0 7%"
  },
  bgimage: {
    position: 'fixed',
    right: -15,
    zIndex: 0,
  },
  bgimageMobile: {
    position: 'fixed',
    right: -210,
    zIndex: 0,
  },
  headContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentContainer: {
    display: 'flex',
    gap: '2.5rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '70dvh',
  },
  calendarContainer: {
    zIndex: 1,
    width: '400px',
    right: 0,
    transition: '100ms',
  },
}));
