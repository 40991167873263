export { default as backgoundPhoto } from './backgound-photo.png';
export { default as Car } from './car.png';
export { default as facebookB } from './facebook-b.svg';
export { default as facebook } from './facebook.svg';
export { default as InfoSystemBG } from './info-system-bg.svg';
export { default as instagrammB } from './instagramm-b.svg';
export { default as instagramm } from './instagramm.svg';
export { default as BirdLogo } from './logo-bird.svg';
export { default as LogoBord } from './logo-bord.svg';
export { default as LogoKg } from './logo-kg.svg';
export { default as LogoRu } from './logo-ru.svg';
export { default as logo } from './logo.png';
export { default as NoDataImage } from './no-data-image.webp';
export { default as RoutesCardBG } from './routes-cards-bg.svg';
export { default as Scales } from './scales.svg';
export { default as telegramB } from './telegram-b.svg';
export { default as telegram } from './telegram.svg';
export { default as youTubeB } from './you-tube-b.svg';
export { default as youTube } from './you-tube.svg';

export { default as Calendar } from './calendar.svg';
export { IconEyeSvg } from './eye-svg';
export { MenuArrowSvg } from './menu-arrow';
export { RouteCardBg } from './routes-card-bg';
export { SearchSvg } from './search-icon';
export { default as StatisticSvg } from './statistic.svg';
