import { Box, Button, Center, Flex, Image, Text, Title } from "@mantine/core";
import { IconPrinter } from "@tabler/icons-react";
import jsPDF from "jspdf";
import { useRef } from "react";
import { getTitleByLanguage } from "src/locales";
import { News } from "src/types/news";

interface IHTMLRenderProps {
    htmlContent: string | undefined;
}

const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
    if (!htmlContent) {
        return null;
    }
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

const options: Intl.DateTimeFormatOptions | undefined = {
    day: "numeric",
    month: "long",
    year: "numeric",
};

interface Props {
    data?: News
    color?: string
}
export const PrintPage = ({ data, color = '#06297B' }: Props) => {
    const reportTemplateRef = useRef(null);
    const handleGeneratePdf = () => {
        if (!data) return;

        const doc = new jsPDF({
            format: "a4",
            unit: "px",
        });
        try {
            doc.addFont("/fonts/RubikMedium.ttf", "Rubik", "normal");
            doc.setFont("Rubik");
        } catch (error) {
            console.error("Error loading font:", error);
        }
        doc.autoPrint({ variant: "non-conform" });
        doc.html(reportTemplateRef?.current as any, {
            callback(doc) {
                doc.save(data?.titleRu.slice(0, 50));
            },
        });
    }
    const date = new Date(`${data?.dateFrom}`).toLocaleString("ru", options)

    const content = <HTMLRender htmlContent={getTitleByLanguage(data?.content)} />

    return (
        <>
            <Button onClick={handleGeneratePdf} variant="simple">
                <IconPrinter size={25} color={color} style={{ padding: "0" }} />
            </Button>
            <Box display="none">
                <Flex
                    m={5}
                    p={5}
                    w={430}
                    ta="start"
                    wrap="wrap"
                    align="center"
                    justify="center"
                    direction="column"
                    ref={reportTemplateRef}
                    style={{
                        border: "1px solid black",
                        position: "absolute",
                        zIndex: "-10",
                    }}
                >
                    <Box w="100%">
                        <Center>
                            <Image maw={380} mah={350} src={`data:image/webp;base64,${data?.photoAlbum?.photo}`} alt="printImage" />
                        </Center>
                        <Text w="fit-content" mx="auto" ta="center" style={{ border: "1px solid grey", padding: "0.2rem", fontSize: "0.5rem", marginTop: "1rem" }}>
                            {date}
                        </Text>
                        <Title ta="center" c="primaryDark" lh={1.2} mt="1rem" fz={16}>
                            {getTitleByLanguage(data)}
                        </Title>

                        <Box w="fit-content" mx="auto" ta="center" style={{ padding: "0.5rem", fontSize: "0.8rem", marginTop: "1rem" }}>
                            {content}
                        </Box>
                    </Box>
                </Flex>
            </Box>
        </>
    )
}
