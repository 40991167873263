const baseApi = (url: string) => `${process.env.REACT_APP_API_TEST}api/v1/${url}`;

export class Path {
  static Auth = class {
    static signIn = baseApi('auth/login');
    static signOut = baseApi('auth/logout');
    static refreshToken = baseApi('auth/refresh/token');
    static registrationAuth = baseApi('auth/registration');
    static OtpVerification = baseApi('auth/verify-otp');
  };
  static Office = class {
    static create = baseApi('office');
    static search = baseApi('office/search');
    static getAll = baseApi('office');
    static getAllWithoutChilds = baseApi('office/branches');
    static getById = (id: number | string) => baseApi(`office/${id}`);
    static update = (id: number | string) => baseApi(`office/${id}`);
    static delete = (id: number | string) => baseApi(`office/${id}`);
  };
  static CardInfo = class {
    static create = baseApi('card-info');
    static getAll = baseApi('card-info');
    static search = baseApi('card-info/search');
    static getById = (id: number | string) => baseApi(`card-info/${id}`);
    static update = (id: number | string) => baseApi(`card-info/${id}`);
    static delete = (id: number | string) => baseApi(`card-info/${id}`);
    static postType = (id: number | string) => baseApi(`card-info/type/${id}`);
  };
  static Search = class {
    static newsSearch = baseApi('news/search');
    static pageSearch = baseApi('page-list/search');
  };
  static Profile = class {
    static passwordUpdate = baseApi(`employees/change-password`);
    static updateLang = baseApi(`employees/change-language`);
  };
  static Employee = class {
    static getMe = baseApi(`employees/me`);
    static editPhone = (phone: string) =>
      baseApi(`employees/change-phone?phone=${phone}`);
    static editEmail = (email: string) =>
      baseApi(`employees/change-email?email=${email}`);
  };
  static News = class {
    static get = (id: string) => baseApi(`news/${id}`);
    static delete = (id: number) => baseApi(`news/${id}/delete`);
    static getPublish = baseApi(`news/get-published`);
    static create = baseApi(`news`);
    static update = (id: number) => baseApi(`news/${id}`);
    static search = baseApi(`news/search`);
  };
  static Album = class {
    static getPhotos = (id: number | null) =>
      baseApi(`photos/get-all?photoAlbumId=${id}`);
    static getPhoto = (id: number) => baseApi(`photos/${id}`);
  };
  static Page = class {
    static search = baseApi(`page-list/search`);
    static create = baseApi(`page-list/create`);
    static update = (id: number) => baseApi(`page-list/${id}/update`);
    static get = (id: string) => baseApi(`page-list/${id}`);
    static structure = baseApi(`sidebar/page-list-structure`);
    static allPageWithLimitedFields = baseApi(
      `sidebar/all-page-list-with-limited-fields`,
    );
    static delete = (id: number) => baseApi(`page-list/${id}`);
  };
  static Document = class {
    static divisionBlanks = baseApi('divisions/blanks');
  };
  static Attachments = class {
    static get = (id: number, uuid: string) =>
      baseApi(`attachments/${id}/${uuid}`);
    static preview = (id: number | string, uuid: string | number) =>
      baseApi(`attachments/${id}/${uuid}/preview`);
  };
  static PageList = class {
    static getContent = (id: number | string) => baseApi(`page-list/${id}`);
    static updateContent = (id: number | string) =>
      baseApi(`page-list/${id}/update`);
    static create = baseApi(`page-list/create`);
    static search = baseApi(`page-list/search`);
  };
  static Navbar = class {
    static division = baseApi(`sidebar/divisions-structure`);
    static nomenclature = baseApi(`sidebar/nomenclature-structure`);
    static organ = baseApi(`sidebar/organ-structure`);
    static pageList = baseApi(`sidebar/page-list-structure`);
  };
  static SocialLinks = class {
    static create = baseApi(`link`);
    static getAll = baseApi(`link`);
    static get = (id: number | string) => baseApi(`link/${id}`);
    static delete = (id: number | string) => baseApi(`link/${id}`);
    static linkSearch = baseApi(`link/search`);
    static updateLink = (id: number | string) => baseApi(`link/${id}`);
  };
  static CommonReference = class {
    static getAllTypeCode = (code: number | string) =>
      baseApi(`common-references/get-all-by-type-code/${code}`);
  };
  static HiddenPageList = class {
    static getHiddenPageById = (url: number | string) =>
      baseApi(`page/hidden/${url}`);
  };
  static PagesAndNewsSearch = class {
    static search = baseApi('news-page-list/search');
  };
  static GoogleAnalyticData = class {
    static getAllData = baseApi('google-analytics-data');
  };
}
