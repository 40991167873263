import { Box, Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { BatkenMapSvg, ChuyMapSvg, OshMapSvg, JalalAbadMapSvg, YsykKolMapSvg, TalasMapSvg, NarynMapSvg } from './regions';
import styles from './map.module.scss';

interface KgMapProps {
  selected: number;
  setSelected: (p: number) => void;
}

interface RegionTitleProps {
  isCitySelected?: boolean;
  isRegionSelected?: boolean;
  top: string;
  left: string;
  region: any;
  onClick: () => void;
  city?: string;
}

export const MainMap = ({ selected, setSelected }: KgMapProps) => {
  const { t } = useTranslation();
  return (
    <div className={styles.kg_map_root}>
      <div className={styles.kg_map}>
        <RegionTitle isRegionSelected={selected === 9} top={'60px'} left={'150px'} region={t('regions.talas')} onClick={() => setSelected(9)} />
        <TalasMapSvg fill={selected === 9 ? '#0B1F7C' : 'white'} opacity={selected === 9 ? '0.4' : '1'} onClick={() => setSelected(9)}
          styles={{ position: 'absolute', top: '47px', left: '130px', cursor: 'pointer' }} />

        {/*  onClick={() => setSelected(selected === 8 ? 1 : 8)} */}
        <RegionTitle isRegionSelected={selected === 8} top={'30px'} left={'350px'} region={t('regions.chuy')} onClick={() => setSelected(8)} />
        <ChuyMapSvg fill={selected === 8 || selected === 1 ? '#0B1F7C' : 'white'} opacity={selected === 8 || selected === 1 ? '0.4' : '1'}
          onClick={() => setSelected(selected === 1 ? 8 : 1)} styles={{ cursor: 'pointer', position: 'absolute', top: '0', left: '308px' }} />

        <RegionTitle isRegionSelected={selected === 12} top={'80px'} left={'630px'} region={t('regions.ysyk-kol')} onClick={() => setSelected(12)} />
        <YsykKolMapSvg fill={selected === 12 ? '#0B1F7C' : 'white'} opacity={selected === 12 ? '0.4' : '1'} onClick={() => setSelected(12)}
          styles={{ position: 'absolute', top: '37px', left: '514px', cursor: 'pointer' }} />

        <RegionTitle isRegionSelected={selected === 13} top={'150px'} left={'190px'} region={t('regions.jalal-abad')} onClick={() => setSelected(13)} />
        <JalalAbadMapSvg fill={selected === 13 ? '#0B1F7C' : 'white'} opacity={selected === 13 ? '0.4' : '1'} onClick={() => setSelected(13)}
          styles={{ position: 'absolute', top: '115px', left: '73px', cursor: 'pointer' }} />

        <RegionTitle isRegionSelected={selected === 11} top={'180px'} left={'450px'} region={t('regions.naryn')} onClick={() => setSelected(11)} />
        <NarynMapSvg fill={selected === 11 ? '#0B1F7C' : 'white'} opacity={selected === 11 ? '0.4' : '1'} onClick={() => setSelected(11)}
          styles={{ position: 'absolute', top: '87px', left: '360px', cursor: 'pointer', }} />

        <RegionTitle isRegionSelected={selected === 14} top={'340px'} left={'90px'} region={t('regions.batken')} onClick={() => setSelected(14)} />
        <BatkenMapSvg fill={selected === 14 ? '#0B1F7C' : 'white'} opacity={selected === 14 ? '0.4' : '1'} onClick={() => setSelected(14)}
          styles={{ position: 'absolute', top: '317px', left: '-1px', cursor: 'pointer' }} />

        <RegionTitle isRegionSelected={selected === 10} top={'290px'} left={'270px'} region={t('regions.osh')} onClick={() => setSelected(10)} />
        <OshMapSvg fill={selected === 10 ? '#0B1F7C' : 'white'} opacity={selected === 10 ? '0.4' : '1'} onClick={() => setSelected(10)}
          styles={{ position: 'absolute', top: '230px', left: '181px', cursor: 'pointer' }} />
      </div>
    </div>
  );
};

const RegionTitle = ({ isRegionSelected, top, left, region, onClick, city, isCitySelected }: RegionTitleProps) => {
  return (
    <Flex pos="absolute" display="flex" align="center" justify="center" direction="column" top={top} left={left} onClick={onClick}
      style={{ zIndex: 5, cursor: 'pointer' }}>
      <Flex mb={5} className={styles.map_title_icon} bg="none" display="flex" direction="column" align="center" justify="center"
        style={{ transform: isRegionSelected ? 'scale(1.1)' : 'scale(1)' }}>
        <Box className={styles.map_title_icon_dot} bg="red"></Box>
      </Flex>
      <Text lh={1} w={125} fz="sm" fw="normal" ta="center" c={isCitySelected ? 'primaryDark' : 'secondary'}
        style={{ transform: isCitySelected ? 'scale(1.2)' : 'scale(1)', transition: '300ms' }}>
        {city}
      </Text>
      <Text lh={1} w={125} fz="sm" fw="normal" ta="center" c={isRegionSelected ? 'primaryDark' : 'secondary'}
        style={{ transform: isRegionSelected ? 'scale(1.2)' : 'scale(1)', transition: '300ms' }}>
        {region}
      </Text>
    </Flex>
  );
};
