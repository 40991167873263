import { Center, Image, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { base64ImgJpeg } from 'src/constants';

interface Props {
  link: LinkResponseType;
}

export const LinksToCard = ({ link }: Props) => {
  const { i18n } = useTranslation();
  return (
    <a href={link.link} target='_blank'>
      <Center style={{ display: 'flex', justifyContent: 'space-between', gap: '8px' }} p={{ base: "1rem 5px", sm: "34px 24px" }}>
        <Image style={{ width: "3.125rem", height: "2.188rem" }} alt={link.titleRu} src={`${base64ImgJpeg}${link.photo}`} />
        <Text fw={500} fz={{ base: 14, sm: 16 }} c="#040A0B">
          {i18n.language === 'ru' ? link?.titleRu : i18n.language === 'kg' ? link?.titleKg : link?.titleEn}
        </Text>
      </Center>
    </a>
  );
};
