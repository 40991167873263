import { Box } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { useEffect } from 'react';
import ReactGA from "react-ga";
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import { OpenLangProvider } from './components';
import {scrollToTop, useLocalStorage} from './hooks';
import i18n from './i18n';
import { ROUTESARR, RouteData } from './route-config';
import { Layout } from './ui-kit';
import { RequireAuth } from './utils/require-auth';

const renderRoutes = (route: RouteData) => {
  const arr = [];
  if (route.child) {
    route.child.forEach((it) => {
      arr.push(<Route path={it.path} key={it.path} element={it.page} />);
    });
  } else {
    arr.push(<Route path={route.path} key={route.path} element={route.page} />);
    route.children?.forEach((it) => {
      arr.push(<Route path={it.path} key={it.path} element={it.page} />);
    });
  }
  return arr;
};

export const App = () => {
  const storageLang = useLocalStorage('i18nextLng').getStorage()
  const { t } = useTranslation()
  const { classes } = useStyles();
  const { pathname, search } = useLocation();
  useEffect(() => scrollToTop(0), [pathname]);

  const storage = useLocalStorage('i18nextLng')

  useEffect(() => {
    let lng = storage.getStorage() ?? 'kg'
    if (pathname.includes('/ru/')) {
      lng = 'ru'
    }
    i18n.changeLanguage(lng);
    storage.setStorage(lng);
    const title = document.getElementById("title")
    title && (title.innerText = t("header.title"))
  }, [i18n.language]);

  useEffect(() => {
    ReactGA.pageview(pathname + search)
  }, [pathname, search])

  return (
    <Box className={classes.container}>
      <Routes>
        {ROUTESARR()?.map((it) => {
          if (it.public) {
            return (
              <Route path={'/'} key={it.path} element={
                <OpenLangProvider>
                  <Layout />
                </OpenLangProvider>
              }>
                {renderRoutes(it)}
              </Route>
            );
          } else {
            return (
              <Route key={it.path} element={
                <OpenLangProvider>
                  <RequireAuth />
                </OpenLangProvider>}>
                {renderRoutes(it)}
              </Route>
            );
          }
        })}
      </Routes>
    </Box>
  );
};

const useStyles = createStyles({
  container: {
    backgroundColor: 'rgba(250, 250, 250, 1)',
    transition: 'all 0.3s ease',
  },
});

export default App;
