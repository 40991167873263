interface RingProgressProps {
  value: number;
  size: number;
  thickness: number;
  color: string;
}

export const RingProgress = ({
  value,
  size,
  thickness,
  color,
}: RingProgressProps) => {
  const circumference = 2 * Math.PI * (size / 2);
  const progress = (value / 100) * circumference;

  return (
    <svg
      width={size}
      height={size}
      style={{ position: 'absolute', transform: 'rotate(-90deg)' }}
    >
      <circle
        fill="none"
        stroke="#DBDFF1"
        cx={size / 2}
        cy={size / 2}
        r={(size - thickness) / 2}
        strokeWidth={thickness}
      />
      <circle
        fill="none"
        strokeLinecap="round"
        cx={size / 2}
        cy={size / 2}
        r={(size - thickness) / 2}
        strokeWidth={thickness}
        strokeDasharray={circumference}
        strokeDashoffset={circumference - progress}
        stroke={color}
        style={{
          transition: 'stroke-dashoffset 0.3s',
        }}
      />
    </svg>
  );
};
