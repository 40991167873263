
import { Box, Flex, UnstyledButton } from "@mantine/core";
import {
    IconAdjustments,
    IconBuildingBank,
    IconChartHistogram,
    IconFileBroken,
    IconGavel,
    IconLayoutDashboard,
    IconLink,
    IconMan,
    IconMapPin,
    IconMessageCheck,
    IconMovie,
    IconNews,
    IconPhoto,
    IconPhotoScan,
} from "@tabler/icons-react";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppAction, useAppSelector } from "src/hooks";
import { sidebarPath } from "src/store/reducers/sidebar-slice";
import { NavbarLink } from "./navbar-link";

interface Props {
    item: string;
    open?: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

type iconsArrayType = {
    [key: string]: any
}

const icons: iconsArrayType = {
    "NEWS": IconNews,
    "PAGE": IconGavel,
    "SOCIAL_LINK": IconLink,
    "BANNER": IconPhotoScan,
    "CARD_INFO": IconMan,
    "REFERENCE": IconAdjustments,
    "INFO_BLOCK": IconLayoutDashboard,
    "PROSECUTOR_OFFICE": IconBuildingBank,
    "DIAGRAM": IconChartHistogram,
    "MAP_POINT": IconMapPin,
    "INTER_COOP_FILE": IconFileBroken,
    "ONLINE_APPEAL": IconMessageCheck,
    "TITLE_PHOTO": IconPhoto,
    "VIDEO": IconMovie,
}

export const MenuItem = (
    { item, open }: Props
) => {
    const { t } = useTranslation();
    const active = useAppSelector((state) => state.sidebarStore.active);
    const { setActive } = useAppAction({ setActive: sidebarPath.addActive });
    const Icon = icons[item] || IconMessageCheck
    const navigation = useNavigate()

    const handleClick = () => {
        setActive(item);
        navigation(`/${item.toLowerCase()}`)
    };
    return (
        open ?
            <UnstyledButton
                bg={item === active ? "rgba(var(--primary-dark), 0.4)" : "none"}
                style={{ transition: "300ms", borderRadius: "5px" }}
                py={13}
                px={10}
                w="100%"
                onClick={handleClick}>
                <Flex align="center">
                    <Box h={22}>
                        <Icon color="#FFFFFF" size={22} />
                    </Box>
                    <Box maw={190} fz={16} style={{ wordWrap: "break-word" }} c="sideBarItemsColor" ml="md">
                        {t(`pages.title.${item.toLowerCase()}`).toUpperCase()}
                    </Box>
                </Flex>
            </UnstyledButton>
            :
            <NavbarLink onClick={handleClick} icon={Icon} label={t(`pages.title.admin.${item.toLowerCase()}`)} />
    )
}

