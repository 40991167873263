import { showNotification } from '@mantine/notifications';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { IconFileAlert } from '@tabler/icons-react';

const ShowError = (message: string) => {
  showNotification({
    id: 'error',
    autoClose: 3000,
    title: message,
    color: 'red',
    icon: <IconFileAlert />,
    message: message,
  });
};

export const HandleError = (payload: FetchBaseQueryError | undefined) => {
  const { message } = payload?.data as {
    message?: string | undefined;
  };
  if (!message) {
    console.error("ERROR");
  }
  switch (payload?.status) {
    case 400:
      !!message?.length ? ShowError(message) : ShowError('Неверный запрос');
      break;
    case 401:
      ShowError('Неверный логин или пароль');
      break;
    case 404:
      ShowError(!!message?.length ? message : 'Не найдено');
      break;
    case 500:
      !!message?.length
        ? ShowError(message)
        : ShowError('Ошибка сервера обратитесь к администратору');
      break;
    default:
      !!message?.length
        ? ShowError(message)
        : ShowError('Неизвестная ошибка попробуйте снова');
      break;
  }
};
