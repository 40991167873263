import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { apiSlice } from './apiSlice';
import {
  authReducer,
  breadcrumbsReducer,
  calendarReducer,
  openBreadcrumbsReducer,
  sidebarReducer,
} from './reducers';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  sidebarStore: sidebarReducer,
  breadcrumbsStore: breadcrumbsReducer,
  openBreadcrumbsStore: openBreadcrumbsReducer,
  calendarStore: calendarReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: {
        warnAfter: 128,
      },
    }).concat([apiSlice.middleware]),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
