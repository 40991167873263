import {
  Button,
  Center,
  Container,
  Image,
  SimpleGrid,
  Text,
  Title,
} from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { useNavigate } from 'react-router-dom';

export const PageConstruction = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <Center m={'auto'}>
      <Container className={classes.root}>
        <SimpleGrid spacing={80} cols={2}>
          <Image src={'/404.png'} className={classes.mobileImage} />
          <div style={{ margin: 'auto' }}>
            <Title className={classes.title}>
              Страница находится в разработке!
            </Title>
            <Text color="dark" size="lg">
              Совсем скоро мы ее запустим!
            </Text>
            <Button
              onClick={() => navigate(-1)}
              variant="filled"
              color={'indigo'}
              size="md"
              mt="xl"
              className={classes.control}
            >
              Назад
            </Button>
          </div>
          <Image
            src={'/pageConstruction.png'}
            className={classes.desktopImage}
          />
        </SimpleGrid>
      </Container>
    </Center>
  );
};

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },

  mobileImage: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  desktopImage: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
}));
