import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useSessionStorage } from 'src/hooks';
import { useLogOutMutation } from 'src/store/reducers/all-reducers/authApiSlice';
import { RootState } from 'src/store/store';
import { MenuDropDown } from '..';
import { ChangePassword } from 'src/components';
import { createStyles } from '@mantine/styles';
import { IconLock, IconLogout, IconUser } from '@tabler/icons-react';
import { logOut } from 'src/store/reducers/auth-slice';
import { Box } from '@mantine/core';

export const ProfileActions = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [signOut] = useLogOutMutation();
  const profile = useAppSelector((state: RootState) => state.auth.profile);
  const profileFromStorage: Profile = useSessionStorage('profile').getItem()

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleLogout = async () => {
    await signOut('');
    dispatch({ type: logOut.type });
    navigate('/');
  };

  const actions = [
    {
      icon: IconUser,
      title: t`sidebar.profile`,
      onClick: () => navigate(`/profile`),
    },
    {
      icon: IconLock,
      title: t`button.changePassword`,
      onClick: () => handleOpen(),
    },
    {
      icon: IconLogout,
      title: t`sidebar.logout`,
      onClick: handleLogout,
    },
  ];

  return (
    <Box>
      <MenuDropDown
        label={profile?.initials ? profile.initials : profileFromStorage.initials}
        item={actions}
        className={classes.menu}
      />
      <ChangePassword open={open} setOpen={setOpen} />
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  menu: {
    padding: '5px 10px',
    borderRadius: 5,
    color: theme.colorScheme === 'dark' ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.1)',
    },
  },
}));
