import { createSlice } from '@reduxjs/toolkit';

export interface BreadCrumbsState {
  select?: NavbarPageListType;
}

const initialState: BreadCrumbsState = {
  select: undefined,
};

export const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    selectTitle: (state, action) => {
      state.select = action.payload;
    },
    logoutWithClear: (state) => {
      state.select = undefined;
    },
  },
});

export const { selectTitle } = breadcrumbsSlice.actions;

export const breadcrumbsReducer = breadcrumbsSlice.reducer;
