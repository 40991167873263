import { Center, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageForm } from 'src/components';
import { usePageTitle } from 'src/hooks';
import { useGetPageQuery } from 'src/store/reducers/all-reducers/pageApiSlice';
import { AppShellCustom, CustomLoader } from 'src/ui-kit';

type Params = {
  pageId: string;
};

export const EditPage = () => {
  const { t } = useTranslation();
  const title = t`pages.title.edit-page`;
  usePageTitle(title);

  const { pageId } = useParams<Params>();
  const { data, isLoading } = useGetPageQuery(pageId as unknown as string, {
    refetchOnMountOrArgChange: true,
  });

  return (
    <AppShellCustom>
      {isLoading ? (
        <CustomLoader />
      ) : (
        data && (
          <>
            <Center>
              <Title fw="normal" mb="1rem">
                {title}
              </Title>
            </Center>
            <PageForm page={data} />
          </>
        )
      )}
    </AppShellCustom>
  );
};
