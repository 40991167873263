import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const pageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createPage: builder.mutation<PageType, PageFormTypes>({
      query: ({ attachment, file, ...body }) => {
        const formData = new FormData();
        const data = new Blob([JSON.stringify(body)], {
          type: 'application/json',
        });
        formData.append('request', data);

        file && formData.append('file', file);

        attachment &&
          attachment.map((photo) => formData.append('attachment', photo));

        return {
          url: Path.Page.create,
          method: 'POST',
          body: formData,
        };
      },
      invalidatesTags: ['PageStructure'],
    }),
    updatePage: builder.mutation<
      PageType,
      { id: number; body: Partial<PageFormTypes> }
    >({
      query: ({ id, body }) => {
        const { file, attachment, ...bodyDate } = body;
        const formData = new FormData();
        const data = new Blob([JSON.stringify(bodyDate)], {
          type: 'application/json',
        });
        formData.append('request', data);

        file && formData.append('file', file);

        attachment &&
          attachment.map((photo) => formData.append('attachment', photo));

        return {
          url: Path.Page.update(id),
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['PageStructure'],
    }),
    getPage: builder.query<PageType, string>({
      query: (id) => ({
        url: Path.Page.get(id),
      }),
    }),
    getPageStructure: builder.query({
      query: () => ({
        url: Path.Page.structure,
        method: 'GET',
      }),
      providesTags: ['PageStructure'],
    }),
    getPageStructureWithLimitedFields: builder.query({
      query: () => ({
        url: Path.Page.allPageWithLimitedFields,
        method: 'GET',
      }),
      providesTags: ['PageStructure'],
    }),
    deletePage: builder.mutation({
      query: (id) => ({
        url: Path.Page.delete(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['PageStructure'],
    }),
    searchPage: builder.mutation<PageType, PageRequestSearchType>({
      query: (body) => ({
        url: Path.Page.search,
        method: 'POST',
        body,
        cache: 'no-cache',
      }),
    }),
  }),
});

export const {
  useSearchPageMutation,
  useCreatePageMutation,
  useUpdatePageMutation,
  useGetPageQuery,
  useGetPageStructureQuery,
  useDeletePageMutation,
  useGetPageStructureWithLimitedFieldsQuery,
} = pageApiSlice;
