import { Box, Button, Grid, Image, Text } from '@mantine/core';
import { Dropzone, FileWithPath, MIME_TYPES } from '@mantine/dropzone';
import { IconFile, IconScissors, IconTrash } from '@tabler/icons-react';
import { FC, ReactNode, useEffect, useState } from 'react';
import { base64ImgJpeg } from 'src/constants';
import { HoverText } from '..';
import { CustomImageCropper } from '../custom-image-cropper';

interface NewFileWithPath extends FileWithPath {
  photoId?: number
  newPhoto?: string
}

type Props = {
  label: string;
  isLoading?: boolean;
  defaultValue?: string;
  error?: ReactNode;
  onChange: (files: File[]) => void;
  accept?: string[];
  photos?: PhotoType[];
  deletedPhotosId?: number[];
};

const fileTypeMap: Record<string, string | ((file: File) => string)> = {
  [MIME_TYPES.pdf]: '/pdf.png',
  [MIME_TYPES.docx]: '/docx.png',
  [MIME_TYPES.zip]: '/zip.png',
  [MIME_TYPES.xlsx]: '/excel.png',
  [MIME_TYPES.jpeg]: (file: File) => URL.createObjectURL(file),
  [MIME_TYPES.png]: (file: File) => URL.createObjectURL(file),
};

const imageToCroppMap: Record<string, string | ((file: File) => string)> = {
  [MIME_TYPES.jpeg]: (file: File) => URL.createObjectURL(file),
  [MIME_TYPES.png]: (file: File) => URL.createObjectURL(file),
};

const getUrlFromMap = (file: NewFileWithPath, map: Record<string, string | ((file: File) => string)>): string => {
  const getUrl = map[file.type];
  if (getUrl) {
    return typeof getUrl === 'function' ? getUrl(file) : getUrl;
  } else if (file.newPhoto) {
    return `${base64ImgJpeg}${file.newPhoto}`;
  }
  return '';
};

const getFileUrl = (file: NewFileWithPath): string => getUrlFromMap(file, fileTypeMap);

const getImageToCropp = (file: NewFileWithPath): string => getUrlFromMap(file, imageToCroppMap);

export const CustomDropzone: FC<Props> = ({ ...props }) => {
  const [files, setFiles] = useState<NewFileWithPath[]>([]);
  const [croppedImage, setCroppedImage] = useState<File | null>(null)
  useEffect(() => {
    let photosFromBack: NewFileWithPath[] = []
    if (props.photos !== undefined) {
      photosFromBack = props?.photos?.map((items) => {
        const result: NewFileWithPath = {
          photoId: items.id,
          name: '',
          lastModified: 0,
          webkitRelativePath: '',
          size: 0,
          type: '',
          newPhoto: items.miniature,
          arrayBuffer: function (): Promise<ArrayBuffer> {
            throw new Error('Function not implemented.');
          },
          slice: function (): Blob {
            throw new Error('Function not implemented.');
          },
          stream: function (): ReadableStream<Uint8Array> {
            throw new Error('Function not implemented.');
          },
          text: function (): Promise<string> {
            throw new Error('Function not implemented.');
          }
        }
        return result
      })
    }
    setFiles([...photosFromBack])
  }, [props.photos])

  const handleDrop = (acceptedFiles: NewFileWithPath[]) => {
    setFiles((current) => [...current, ...acceptedFiles]);
  };

  const handleDelete = async (index: number) => {
    setFiles((current) => {
      const newFiles = [...current];
      newFiles.splice(index, 1);
      return newFiles;
    });
    props.deletedPhotosId?.push(Number(files[index].photoId))
  };

  const handleCroppedImages = (file: File | null, index: number) => {
    const changedFilesArray = [...files]
    try {
      if (file) changedFilesArray[index] = file
      else console.log('File is null');
    } catch (error) {
      console.log("Failed to set cropped image");
    }
    setFiles(changedFilesArray)
    setCroppedImage(null)
  }

  const handlePreview = (file: NewFileWithPath, index: number) => {
    const fileUrl = getFileUrl(file)

    return (
      <Box style={{ display: "flex", justifyContent: "center", alignItems: 'center', flexDirection: "column" }}>
        <Image
          defaultValue={props.defaultValue}
          key={index}
          src={fileUrl}
          w={"100%"}
          onLoad={() => URL.revokeObjectURL(fileUrl)}
          onClick={() => window.open(URL.createObjectURL(file), '_blank')}
          style={{ cursor: 'pointer', borderRadius: "4px", }} alt='dropzone_image' />
        {file.name.length > 10 ? (
          <Box mt={8}>
            <HoverText size="xs" width={200} text={file.name.slice(0, 10) + '...'} hoverText={file.name} />
          </Box>
        ) : (
          <Text pt={8} size="xs">{file.name.length >= 1 ? file.name : <>без имени</>}</Text>
        )}
      </Box>
    );
  };

  const previews = files?.map((file, index) => {
    const imageToCrop = getImageToCropp(file)

    return (
      <Grid.Col span={{ base: 12, xs: 6, md: 4, lg: 3, xl: 2 }} p="xs" key={`${file.lastModified}-${file.lastModified}${file.photoId}`}>
        <Grid>
          <Grid.Col span={{ base: 12, lg: 4 }} pb={0}>
            <Button fullWidth onClick={() => handleDelete(index)}>
              <IconTrash width={20} />
            </Button>
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 4 }} pb={0}>
            <CustomImageCropper
              fullWidth
              aspectRatio={'16/9'}
              imageName={file.name}
              imageToCrop={imageToCrop}
              onChange={setCroppedImage}
              icon={<IconScissors width={20} />} />
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 4 }} pb={0}>
            <Button fullWidth onClick={() => handleCroppedImages(croppedImage, index)}>
              <IconFile width={20} />
            </Button>
          </Grid.Col>
          <Grid.Col span={12}>
            {handlePreview(file, index)}
          </Grid.Col>
        </Grid>
      </Grid.Col>
    );
  });

  useEffect(() => {
    if (files) {
      props.onChange(files);
    }
  }, [files]);

  return (
    <div>
      <Dropzone
        loading={props.isLoading}
        accept={props.accept ?? [MIME_TYPES.jpeg, MIME_TYPES.png, MIME_TYPES.pdf, MIME_TYPES.docx, MIME_TYPES.xlsx, MIME_TYPES.zip,]}
        onDrop={handleDrop}
        style={(theme) => ({ border: props.error ? `0.125rem dashed ${theme.colors.red[5]}` : '0.125rem dashed #ced4da', borderRadius: '0.25rem', padding: '1rem', cursor: 'pointer' })}
      >
        <Text style={{ textAlign: 'center' }}>{props.label}</Text>
      </Dropzone>
      {props.error && (
        <Text c="red" mt="xs"
          style={{
            fontSize: 'calc(0.875rem - 0.125rem)',
            lineHeight: '1.2',
          }}
        >
          {props.error}
        </Text>
      )}
      <Grid mt="md">
        {previews}
      </Grid>
    </div>
  );
};
