import { Avatar, Box, Flex, Paper, Text } from '@mantine/core';
import { backgoundPhoto } from 'src/assets';
import { base64ImgPng } from 'src/constants';
import { RouteWithLang } from '../route-with-lang';
import { useTranslation } from "react-i18next"

interface IProps {
  pageId: number;
  title?: string;
  photo?: string;
  text?: string;
  link?: string;
}

interface IHTMLRenderProps {
  htmlContent: string | undefined;
}

const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
  if (!htmlContent) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export const InfoSystemCard = ({ title, photo, text, link, pageId }: IProps) => {
  const base64Format = `${base64ImgPng}${photo}`;
  const { i18n } = useTranslation()
  const language = i18n.language;

  let href = link
  if (!href) {
    href = `/${language}/pages/${pageId}`
  }

  return (
    <>
      {!href ? (
        <RouteWithLang to={`/pages/${pageId}`}>
          <Paper w="100%" h={{ base: "fit-content", sm: 288, xl: 304 }} variant="opacity2">
            <Flex direction={{ base: 'row', sm: 'column' }} gap={{ base: 16, sm: 0 }} h="100%" style={{ overflow: "hidden" }}>
              <Box w={{ base: "fit-content", sm: "100%" }}>
                <Avatar src={photo ? base64Format : backgoundPhoto} alt="image" w={{ base: 70, sm: 96 }} h={{ base: 70, sm: 96 }} />
              </Box>
              <Flex align="center" mt={{ base: 0, sm: 16 }}>
                <Text fz={{ base: 16, sm: 20, xl: 24 }} fw={500}>
                  {title}
                </Text>
              </Flex>
              <Box fz={16} c="#555F71" display={{ base: "none", sm: "flex" }}
                style={{ lineHeight: '24px', alignItems: 'flex-start' }}>
                {text && <HTMLRender htmlContent={text} />}
              </Box>
            </Flex>
          </Paper>
        </RouteWithLang>
      ) : (
        <Paper w="100%" h={{ base: "fit-content", sm: 288, xl: 304 }} variant="opacity2" component="a" href={href} target="_blank">
          <Flex direction={{ base: 'row', sm: 'column' }} gap={{ base: 16, sm: 0 }} h="100%" style={{ overflow: "hidden" }}>
            <Box w={{ base: "fit-content", sm: "100%" }}>
              <Avatar src={photo ? base64Format : backgoundPhoto} alt="image" w={{ base: 70, sm: 96 }} h={{ base: 70, sm: 96 }} />
            </Box>
            <Flex align="center" mt={{ base: 0, sm: 16 }}>
              <Text fz={{ base: 16, sm: 20, xl: 24 }} fw={500}>
                {title}
              </Text>
            </Flex>
            <Box fz={16} c="#555F71" display={{ base: "none", sm: "flex" }}
              style={{ lineHeight: '24px', alignItems: 'flex-start' }}>
              {text && <HTMLRender htmlContent={text} />}
            </Box>
          </Flex>
        </Paper>
      )}
    </>
  );
};
