import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const galleryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPhotos: builder.query<PhotoType[], number>({
      query: (id) => ({
        url: Path.Album.getPhotos(id),
        method: 'GET',
      }),
      providesTags: ['Gallery'],
    }),
    getPhoto: builder.query<PhotoType, number>({
      query: (id) => ({
        url: Path.Album.getPhoto(id),
        method: 'GET',
      }),
      providesTags: ['Gallery'],
    }),
  }),
});

export const { useGetPhotosQuery, useLazyGetPhotoQuery } = galleryApiSlice;
