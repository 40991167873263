// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress_progress__zehQZ[value] {
  width: 300px;
  height: 20px;
}

.progress_progress__zehQZ {
  border: none;
}

.progress_progress__zehQZ[value]::-webkit-progress-bar {
  border-radius: 10px;
  height: 9px;
  width: 100%;
  background-color: #dbdff1;
}

.progress_progress__zehQZ[value]::-webkit-progress-value {
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/ui-kit/progress/progress.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,YAAA;AACF;;AACA;EACE,YAAA;AAEF;;AAAA;EACE,mBAAA;EACA,WAAA;EACA,WAAA;EACA,yBAAA;AAGF;;AADA;EACE,mBAAA;AAIF","sourcesContent":[".progress[value] {\n  width: 300px;\n  height: 20px;\n}\n.progress {\n  border: none;\n}\n.progress[value]::-webkit-progress-bar {\n  border-radius: 10px;\n  height: 9px;\n  width: 100%;\n  background-color: #dbdff1;\n}\n.progress[value]::-webkit-progress-value {\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progress": `progress_progress__zehQZ`
};
export default ___CSS_LOADER_EXPORT___;
