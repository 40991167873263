import { Button } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { CSSProperties, FC, MouseEventHandler, ReactNode } from 'react';
import { Link } from 'react-router-dom';

type Props = {
  label: string;
  fullWidth?: boolean;
  uppercase?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  color?: string;
  style?: CSSProperties;
  mb?: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
  type?: 'submit' | 'button' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  to?: string;
  className?: string;
  variant?: string
};

export const CustomButton: FC<Props> = ({
  label,
  leftIcon,
  rightIcon,
  fullWidth,
  size,
  style,
  className,
  mb,
  disabled,
  loading,
  type,
  onClick,
  to,
  variant = "admin"
}) => {
  const { classes } = useStyles();

  if (to) {
    return (
      <Button
        component={Link}
        leftSection={leftIcon}
        rightSection={rightIcon}
        fullWidth={fullWidth}
        size={size}
        style={style}
        disabled={disabled}
        loading={loading}
        type={type}
        to={to}
        mb={mb}
        variant={variant}
        className={className ? className : classes.button}
      >
        {label}
      </Button>
    );
  }

  return (
    <Button
      leftSection={leftIcon}
      rightSection={rightIcon}
      fullWidth={fullWidth}
      size={size}
      style={style}
      disabled={disabled}
      loading={loading}
      type={type}
      mb={mb}
      onClick={onClick}
      variant={variant}
      className={className ? className : classes.button}
    >
      {label}
    </Button>
  );
};

const useStyles = createStyles((theme) => ({
  button: {
    minWidth: 140,
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'uppercase',
    backgroundColor: 'rgba(15, 39, 81, 0.95)',
    display: 'flex',
    justifyContent: 'center',
    '&:hover': {
      color: theme.white,
      backgroundColor: 'rgba(11, 25, 51, 0.95)',
    },
  },
}));
