import { Input, MantineProvider, createTheme } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ReactNode } from 'react';

interface IProps {
  children: JSX.Element | ReactNode;
}

const generateColors = (color: string) => {
  return Array(10).fill(color) as [
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
    string,
  ];
};

const theme = createTheme({
  fontFamily: 'Rubik, sans-serif',
  breakpoints: {
    xs: '476px',
    sm: '600px',
    md: '992px',
    lg: '1200px',
    xl: '1400px',
    'x2l': '1500px',
  },
  fontSizes: {
    'x3l': '30px',
    'x2l': '24px',
    xl: '20px',
    lg: '18px',
    md: '16px',
    sm: '14px',
  },
  headings: {
    sizes: {
      h1: {
        fontSize: '30px',
        fontWeight: '500',
        lineHeight: '30px',
      },
      h2: {
        fontSize: '24px',
        fontWeight: '500',
        lineHeight: '24px',
      },
      h3: {
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '20px',
      },
      h4: {
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '18px',
      },
      h5: {
        fontSize: '16px',
        fontWeight: '500',
        lineHeight: '16px',
      },
      h6: {
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '14px',
      },
    },
  },
  colors: {
    primaryDark: generateColors('#000D48'),
    primary: generateColors('#ffffff'),
    secondaryDark: generateColors('#0B1F7C'),
    secondary: generateColors('#101828'),
    gray: generateColors('#a8a8a8'),
    weakGray: generateColors('rgba(11, 31, 124, 0.5)'),
    weakWhite: generateColors('rgba(255,255,255,0.04)'),
    weakPrimaryDark: generateColors('rgba(1, 14, 71, 0.5)'),
    lightBlue: generateColors('#1AB1E0'),
    softBlue: generateColors('#106F9E'),
    oceonBlue: generateColors('#187FDB'),
    weakBlue: generateColors('rgba(7, 21, 87, 0.5)'),
  },
  components: {
    Button: {
      defaultProps: {
        variant: 'primary',
      },
      styles: (theme: 'dark' | 'white', { variant }: { variant: string }) => {
        if (variant === 'filled') {
          return {
            root: {
              backgroundColor: '#040F22F2',
              color: 'white',
              padding: "0.5rem",
              border: "1px solid #FFFFFF"
            },
          };
        }
        if (variant === 'primary') {
          return {
            root: {
              backgroundColor: '#06297B',
              color: 'white',
            },
          };
        }
        if (variant === 'admin') {
          return {
            root: {
              backgroundColor: 'rgb(13, 24, 42)',
              color: 'white',
              padding: "0 0.5rem"
            },
          };
        }
        if (variant === 'light-blue') {
          return {
            root: {
              backgroundColor: '#AA2A2E',
              color: 'white',
              fontWeight: '400',
            },
          };
        }
        if (variant === 'simple') {
          return {
            root: {
              backgroundColor: 'rgba(255, 255, 255, 0.45)',
              color: '#101828',
              fontWeight: '400',
              padding: "0 0.5rem"
            },
          };
        }
        if (variant === 'white-for-file') {
          return {
            root: {
              backgroundColor: 'white',
              borderRadius: '0.5rem',
              fontWeight: '500',
              color: '#101828',
              boxSizing: 'border-box',
            },
          };
        }
        if (variant === 'admin') {
          return {
            root: {
              backgroundColor: 'rgba(255, 255, 255, 0.45)',
              color: '#A8A8A8',
              fontWeight: '500',
              border: "1px solid #A8A8A8"
            },
          };
        }
      },
    },
    BackgroundImage: {
      styles: {
        root: {
          backgroundSize: '100%',
          backgroundRepeat: 'no-repeat',
        },
      },
    },
    Paper: {
      defaultProps: {
        variant: 'default-var',
      },
      styles: (theme: 'dark' | 'white', { variant }: { variant: string }) => {
        if (variant === 'default-var') {
          return {
            root: {
              marginTop: '1rem',
              background: 'white',
              boxShadow: '0px 0px 8px gray',
            },
          };
        }
        if (variant === 'bg-white') {
          return {
            root: {
              background: 'white',
            },
          };
        }
        if (variant === 'bg-opacity2') {
          return {
            root: {
              background: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: "blur(20px)",
              WebkitBackdropFilter: "blur(10px)",
              borderRadius: '0.5rem',
              boxShadow: "0px 4px 24px rgba(106, 122, 152, 0.05)"
            },
          };
        }
        if (variant === 'bg-opacity') {
          return {
            root: {
              background: 'rgba(255, 255, 255, 0.5)',
              border: '1px solid #D3D8E6',
              borderColor: '#D3D8E6',
              borderRadius: '4px',
              width: '522px',
              backdropFilter: 'blur(5px)',
              display: 'flex',
              flexDirection: 'column',
              gap: '1.25rem',
            },
          };
        }
        if (variant === 'opacity2') {
          return {
            root: {
              padding: '20px 16px',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              backdropFilter: 'blur(7.5px)',
              color: '#040A0B',
              borderColor: '#D3D8E6',
              border: '1px solid #D3D8E6',
              borderRadius: '4px',
            },
          };
        }
        if (variant === 'opacity3') {
          return {
            root: {
              display: 'flex',
              gap: '1.5rem',
              color: '#06297B',
              minHeight: '224px',
              background: 'rgba(255, 255, 255, 0.5)',
              backdropFilter: 'blur(7.5px)',
              borderColor: '#E2E6EE',
              border: '0.5px solid #E2E6EE',
              borderRadius: '4px',
            },
          };
        }
        if (variant === 'news-blue-card') {
          return {
            root: {
              color: '#06297B',
              background: 'rgba(211, 216, 230, 0.50)',
              boxShadow: '0px 4px 24px 0px rgba(106, 122, 152, 0.05)',
            },
          };
        }
        if (variant === 'news-white-card') {
          return {
            root: {
              height: '240px',
              display: 'flex',
              gap: '16px',
              color: '#06297B',
              padding: '33px 24px',
              background: '#fff',
              boxShadow: '0px 4px 24px 0px rgba(106, 122, 152, 0.05)',
            },
          };
        }
      },
    },
    Input: Input.extend({
      styles: {
        input: {
          border: '1px solid #D3D8E6',
        },
      },
    }),
  },
});

export const CustomMuntineProvider = ({ children }: IProps) => {
  return (
    <MantineProvider theme={theme}>
      <Notifications />
      {children}
    </MantineProvider>
  );
};
