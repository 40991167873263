import { Text } from "@mantine/core";
import { IconAsterisk } from '@tabler/icons-react';
import JoditEditor, { Jodit } from 'jodit-react';
import { useEffect, useMemo, useRef } from 'react';

interface IProps {
  label: string;
  onChange: (value: string) => void;
  formData?: string;
  labelButton?: React.ReactNode;
  required?: boolean;
}

type ToolbarButtonSizeType = 'small';
type DefaultActionOnPasteType = 'insert_clear_html';

const toolbarButtonSize: ToolbarButtonSizeType = "small";
const defaultActionOnPaste: DefaultActionOnPasteType = "insert_clear_html";

export const CustomTextEditor = ({ label, onChange, formData = "", labelButton, required }: IProps) => {
  const editor = useRef<Jodit | null>(null);

  const config = useMemo(() => ({
    readonly: false,
    placeholder: label || 'Start typing...',
    uploader: {
      insertImageAsBase64URI: true
    },
    controls: {
      font: {
        list: [
          'Rubik'
        ]
      },
    },
    disablePlugins: "add-new-line",
    toolbarButtonSize: toolbarButtonSize,
    language: "ru",
    defaultActionOnPaste: defaultActionOnPaste,
    askBeforePasteHTML: false,
    minHeight: 150,
    maxHeight: 500,
    events: {
      afterInsertNode: (node: Node) => {
        if (node instanceof HTMLElement) {
          node.style.fontFamily = 'Rubik, sans-serif';
        }
      },
      afterSetMode: () => {
        const editorInstance = editor.current;
        if (editorInstance) {
          editorInstance.editor?.querySelectorAll<HTMLElement>('*').forEach((element) => {
            element.style.fontFamily = 'Rubik, sans-serif';
          });
        }
      }
    }
  }), [label]);

  useEffect(() => {
    if (editor.current) {
      editor?.current?.events?.on('afterSetMode', () => {
        const editorInstance = editor.current;
        if (editorInstance) {
          editorInstance?.editor?.querySelectorAll<HTMLElement>('*').forEach((element) => {
            element.style.fontFamily = 'Rubik, sans-serif';
          });
        }
      });
    }
  }, []);

  return (
    <>
      <Text style={{ fontWeight: 600, fontSize: 12 }}>
        {label} {required && <IconAsterisk width={10} color="red" />}
        {labelButton}
      </Text>
      <JoditEditor
        ref={editor}
        value={formData ? formData : ''}
        config={config}
        onBlur={newContent => onChange(newContent)}
      />
    </>
  );
};
