import styles from './progress.module.scss';

interface ProgressProps {
  value: number;
  max: number;
  className?: string;
  style?: {
    [key: string]: string | number;
  };
}

export const Progress = ({ value, max, style, className }: ProgressProps) => {
  return (
    <progress
      className={`${className} ${styles.progress}`}
      value={value}
      max={max}
      style={style}
    />
  );
};
