import { Box, Center, Grid, Image, Loader, Text, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { Scales } from 'src/assets';
import { NoDataUi } from 'src/components';
import { useGetAllLinksQuery } from 'src/store/reducers/all-reducers/socialLinkApiSlice';

export const SubOrganizations = () => {
  const screenSize = useMediaQuery('(max-width: 800px)');
  const { i18n, t } = useTranslation();
  const { data, isLoading } = useGetAllLinksQuery([], { refetchOnFocus: true, });

  return (
    <Box style={{ color: '#040A0B', paddingTop: "1rem" }} mb={{ base: "2.5rem", sm: "10rem" }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '2rem', padding: '1rem 0' }}>
        <Text fw={500} fz={{ base: 22, md: 32 }}>
          {t("main_page.title.sub_organizations")}
        </Text>
      </Box>
      <Box style={{ display: 'flex', gap: '32px', flexDirection: screenSize ? 'column' : 'row', width: '100%' }}>
        <Grid w="100%">
          {isLoading ?
            <Grid.Col span={12} mt={100}>
              <Center>
                <Loader color="blue" />
              </Center>
            </Grid.Col>
            :
            <>
              {!data || data.length === 0
                ?
                <Grid.Col span={12}>
                  <NoDataUi />
                </Grid.Col>
                :
                data?.map(
                  (items) =>
                    items.linkType.titleRu === 'Подведомственные подразделения' && (
                      <Grid.Col span={{ base: 12, md: 6, lg: 4 }} key={items.id}>
                        <UnstyledButton
                          component="a"
                          href={items.link}
                          target="_blank"
                          fz={{ base: "1rem", sm: '1.25rem' }}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            fontWeight: '500',
                            textDecoration: 'underline',
                          }}
                        >
                          <Image src={Scales} />
                          {i18n.language === 'ru' ? items?.titleRu : i18n.language === 'kg' ? items?.titleKg : items?.titleEn}
                        </UnstyledButton>
                      </Grid.Col>
                    ),
                )
              }
            </>
          }
        </Grid>
      </Box>
    </Box>
  );
};
