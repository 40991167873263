import { Box, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RouteCardBg } from 'src/assets';
import { getTitleByLanguage } from 'src/locales';
import { usePageContentQuery } from 'src/store/reducers/all-reducers/pageContentApiSlice';
import { RouteTitles } from 'src/ui-kit';
import { EmployeesPage } from './employyes';
import { PagesPage } from './pages-page';

interface IHTMLRenderProps {
  htmlContent: string | undefined;
}

const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
  if (!htmlContent) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

const sortByRating = (objects: PageType[]): PageType[] => {
  let sortedObjects: PageType[] = [];
  if (objects) {
    sortedObjects = objects
      .filter((item) => item.significance >= 0)
      .slice()
      .sort((a, b) => b.significance - a.significance);
  }
  return sortedObjects;
};

export const UniversalPage = () => {
  const middleScreen = useMediaQuery("(max-width: 1000px)")
  const screenSize = useMediaQuery('(max-width: 1200px)');
  const [sortedPages, setSortedPages] = useState<PageType[]>([])
  const { i18n } = useTranslation();
  const { classes } = useStyles();
  const { pathname } = useLocation();
  const id = pathname.split('/').reverse()[0];

  const { data: pageContent } = usePageContentQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const getTitle = (titles: PageType | undefined): string => {
    const title = i18n.language === 'ru' ? titles?.titleRu : i18n.language === 'kg' ? titles?.titleKg : titles?.titleEn;
    return title || '';
  };

  useEffect(() => {
    pageContent?.childrens && setSortedPages(sortByRating(pageContent?.childrens))
  }, [pageContent])

  return (
    <Box className={classes.containerblock}>
      <RouteTitles title={`${getTitleByLanguage(pageContent).slice(0, 20)}..`} isPage={true} />
      <Box className={classes.contentConteiner}>
        <Text fz={screenSize ? 22 : 32} fw={700} mb={{ base: 10, xs: 32 }} c="#06297B">
          {getTitle(pageContent)}
        </Text>
        <Box className={middleScreen ? classes.bgimageMobile : classes.bgimage}>
          <RouteCardBg width={middleScreen ? 1200 : 1920} height={middleScreen ? 500 : 620} />
        </Box>

        {pageContent?.pageListType === 'EMPLOYEES_PAGE' && <EmployeesPage employeeType={pageContent.employeeListType} />}

        {pageContent?.childrens &&
          <Box style={{ display: "flex", flexDirection: "column", gap: "1.2rem", marginTop: "1rem" }}>
            {sortedPages?.map((items) => (<PagesPage items={items} key={items.id} />))}
          </Box>
        }
        {pageContent?.pageListType === 'DEFAULT_PAGE' && (pageContent?.childrens === null || pageContent?.childrens?.length === 0)
          && (
            <Box className={classes.contentBox}>
              <HTMLRender htmlContent={getTitle(pageContent?.content)} />
            </Box>
          )}
      </Box>
    </Box >
  );
};

const useStyles = createStyles(() => ({
  containerblock: {
    minHeight: '80dvh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 7%',
  },
  contentConteiner: {
    padding: '0.5rem 0',
    color: '#111',
    marginBottom: '19rem',
    position: "relative",
  },
  bgimage: {
    position: 'fixed',
    right: -15,
    zIndex: 0,
  },
  bgimageMobile: {
    position: 'fixed',
    right: -210,
    zIndex: 0,
  },
  contentBox: {
    position: "relative",
    zIndex: 5,
    overflow: "hidden"
  }
}));
