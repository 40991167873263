import { Avatar, Center, Container, Grid, Paper } from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { base64ImgPng } from 'src/constants';
import { useCreatePageMutation, useUpdatePageMutation } from 'src/store/reducers/all-reducers/pageApiSlice';
import { ButtonGroup, CustomInputNumber, CustomInputText, CustomSelect, CustomTextEditor, ImageUpload, SearchField } from 'src/ui-kit';
import { Path } from 'src/utils';

type Props = {
  page?: PageType;
};

interface ISelectArr {
  value: string;
  label: string;
}

export const PageForm = ({ page }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [image, setImage] = useState<string>("");

  const [createPage] = useCreatePageMutation();
  const [updatePage] = useUpdatePageMutation();

  useEffect(() => { setImage(`${base64ImgPng}${page?.file}`) }, [page?.file])

  const pageListTypeArray: ISelectArr[] = [
    { label: t('DEFAULT_PAGE'), value: 'DEFAULT_PAGE', },
    { label: t('LINKS_PAGE'), value: 'LINKS_PAGE', },
    { label: t('EMPLOYEES_PAGE'), value: 'EMPLOYEES_PAGE', },
  ];

  const employeeTypeArray: ISelectArr[] = [
    { label: t('form.label.personnel-history'), value: 'HISTORY', },
    { label: t('form.label.personnel-present'), value: 'PRESENT', },
  ]

  const positionArray: ISelectArr[] = [
    { label: t('HEADER'), value: 'HEADER', },
    { label: t('CARDS'), value: 'CARDS', },
    { label: t('CAROUSEL'), value: 'CAROUSEL', },
    { label: t('DIGITAL_MINJUST'), value: 'DIGITAL_MINJUST', },
    { label: t('HIDDEN'), value: 'HIDDEN', },
  ];

  const selecDataForPosition = positionArray?.map((items) => {
    return {
      value: `${items.value}`,
      label: items.label,
    };
  });

  const selecDataForPageListType = pageListTypeArray?.map((items) => {
    return {
      value: `${items.value}`,
      label: items.label,
    };
  });

  const selecDataForEmployeeListType = employeeTypeArray?.map((items) => {
    return {
      value: `${items.value}`,
      label: items.label,
    };
  });

  const form = useForm<PageFormTypes>({
    initialValues: {
      file: null,
      titleKg: page?.titleKg || '',
      titleRu: page?.titleRu || '',
      titleEn: page?.titleEn || '',
      contentKg: page?.content.titleKg || null,
      contentRu: page?.content.titleRu || null,
      contentEn: page?.content.titleEn || null,
      pageListPosition: page?.pageListPosition || null,
      pageListType: page?.pageListType || 'DEFAULT_PAGE',
      position: page?.position || null,
      parentId: page?.parent?.id,
      employeeListType: page?.employeeListType || null,
      rating: page?.rating || null,
      link: page?.link || '',
      significance: page?.significance || 0,
      hiddenUrl: page?.hiddenUrl || undefined
    },
    validate: {
      titleKg: (value) => {
        if (!value) return t`form.validation.required`;
      },
      titleRu: (value) => {
        if (!value) return t`form.validation.required`;
      },
      titleEn: (value) => {
        if (!value) return t`form.validation.required`;
      },
    },
  });

  const handleSubmit = async (values: PageFormTypes) => {
    if (page) {
      await updatePage({ id: page.id, body: values })
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.success`,
            message: t`notification.updateMsg`,
            color: 'green',
          });
          form.reset();
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    } else {
      await createPage(values)
        .unwrap()
        .then(() => {
          showNotification({
            title: t`notification.successMsg`,
            message: '',
            color: 'green',
          });
          form.reset();
          navigate(-1);
        })
        .catch((e: ErrorType) => {
          showNotification({
            title: t`notification.error`,
            message: e?.data.message,
            color: 'red',
          });
        });
    }
  };

  return (
    <Container size="xl">
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Paper shadow="sm" p="xl">
          <Grid>
            <Grid.Col span={6}>
              <CustomSelect
                {...form.getInputProps('pageListPosition')}
                placeholder={t`form.label.pageListPosition`}
                error={form.errors.pageListPosition}
                formData={form.values.pageListPosition}
                data={selecDataForPosition}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <CustomSelect
                {...form.getInputProps('pageListType')}
                placeholder={t`form.label.pageListType`}
                required
                error={form.errors.pageListType}
                formData={form.values.pageListType}
                data={selecDataForPageListType}
              />
            </Grid.Col>
            {form.values.pageListType === "EMPLOYEES_PAGE" &&
              <Grid.Col span={12}>
                <CustomSelect
                  {...form.getInputProps('employeeListType')}
                  placeholder={t`form.label.card-type`}
                  required
                  error={form.errors.employeeListType}
                  formData={form.values.employeeListType}
                  data={selecDataForEmployeeListType}
                />
              </Grid.Col>
            }
            {(form.values.pageListPosition === 'CARDS' ||
              form.values.pageListPosition === 'DIGITAL_MINJUST') && (
                <Grid.Col span={12}>
                  <Center>
                    <Avatar
                      src={image}
                      alt={`photo`}
                      radius="lg"
                      mb="1rem"
                      style={{ width: 200, height: "auto" }}
                    />
                  </Center>
                  <ImageUpload
                    error={form.errors.file}
                    {...form.getInputProps('file')}
                    setState={setImage}
                    fileType
                  />
                </Grid.Col>
              )}
            <Grid.Col span={4}>
              <CustomInputText
                label={t`form.label.titleKg`}
                required
                error={form.errors.titleKg}
                formData={form.values.titleKg}
                {...form.getInputProps('titleKg')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                label={t`form.label.titleRu`}
                required
                error={form.errors.titleRu}
                formData={form.values.titleRu}
                {...form.getInputProps('titleRu')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <CustomInputText
                label={t`form.label.titleEn`}
                required
                error={form.errors.titleEn}
                formData={form.values.titleEn}
                {...form.getInputProps('titleEn')}
              />
            </Grid.Col>
            {form.values.pageListPosition === "HIDDEN" &&
              <Grid.Col span={12}>
                <CustomInputText
                  {...form.getInputProps("hiddenUrl")}
                  required
                  error={form.errors.hiddenUrl}
                  formData={form.values.hiddenUrl}
                  label={t`form.label.hiddenPagePath`}
                />
              </Grid.Col>
            }
            {form.values.pageListPosition !== "HIDDEN" &&
              <>
                <Grid.Col span={4}>
                  <SearchField
                    {...form.getInputProps('parentId')}
                    path={Path.Search.pageSearch}
                    label={t`form.label.parent`}
                    error={form.errors.parentId}
                    formData={form.values.parentId}
                    limit={500}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomInputNumber
                    {...form.getInputProps('rating')}
                    label={t`form.label.rating`}
                    error={form.errors.rating}
                    formData={form.values.rating}
                    max={2}
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <CustomInputNumber
                    {...form.getInputProps('significance')}
                    label={t`form.label.significance`}
                    error={form.errors.significance}
                    formData={form.values.significance}
                    max={2}
                  />
                </Grid.Col>
              </>
            }
            {form.values.pageListType === "LINKS_PAGE" &&
              <Grid.Col span={12}>
                <CustomInputText
                  label={t`form.label.link`}
                  error={form.errors.link}
                  formData={form.values.link}
                  {...form.getInputProps('link')}
                />
              </Grid.Col>
            }
            {form.values.pageListType !== 'EMPLOYEES_PAGE' &&
              <Grid.Col span={12}>
                <Grid.Col span={12} px={0}>
                  <CustomTextEditor
                    label={t`form.label.contentKg`}
                    error={form.errors.contentKg}
                    formData={form.values.contentKg}
                    {...form.getInputProps('contentKg')}
                  />
                </Grid.Col>
                <Grid.Col span={12} px={0}>
                  <CustomTextEditor
                    label={t`form.label.contentRu`}
                    error={form.errors.contentRu}
                    formData={form.values.contentRu}
                    {...form.getInputProps('contentRu')}
                  />
                </Grid.Col>
                <Grid.Col span={12} px={0}>
                  <CustomTextEditor
                    label={t`form.label.contentEn`}
                    error={form.errors.contentEn}
                    formData={form.values.contentEn}
                    {...form.getInputProps('contentEn')}
                  />
                </Grid.Col>
              </Grid.Col>
            }
            <Grid.Col span={12}>
              <ButtonGroup onClickCancel={() => navigate(-1)} type="submit" />
            </Grid.Col>
          </Grid>
        </Paper>
      </form>
    </Container>
  );
};
