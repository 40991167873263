import { Button, Menu } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLanguage, useLocalStorage } from 'src/hooks';
import {useLocation} from 'react-router-dom'

interface IProps {
  color?: string
}

export const ChangeLanguage = ({ color = "white" }: IProps) => {
  const storageLang = useLocalStorage('i18nextLng').getStorage()
  const [language, setLanguage] = useState(storageLang ? storageLang : 'kg');
  const { i18n } = useTranslation();
  const { changeRouteLanguage } = useLanguage()

  useLocalStorage('i18nextLng').setStorage(language)

  useEffect(() => {
    if (storageLang) setLanguage(storageLang)
  }, [storageLang])

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n, language]);

  const handleChangeLangOnRu = () => {
    setLanguage('ru')
    changeRouteLanguage("ru")
  }

  const handleChangeLangOnKg = () => {
    setLanguage('kg')
    changeRouteLanguage("kg")
  }

  return (
    <Menu width={110} trigger="hover" openDelay={100}>
      <Menu.Target>
        <Button variant="subtle" size="md" p={0} c={color} fw={500} rightSection={<IconChevronDown size="1rem" />}>
          <Trans i18Key={language}>
            {language === "ru" ? "Русский" : "Кыргызча"}
          </Trans>
        </Button>
      </Menu.Target>
      <Menu.Dropdown p={"xs"} bg={"white"} style={{ border: "none" }}>
        <Menu.Item onClick={() => handleChangeLangOnRu()} style={{ fontSize: 15 }}>
          Русский
        </Menu.Item>
        <Menu.Item onClick={() => handleChangeLangOnKg()} style={{ fontSize: 15 }}>
          Кыргызча
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
