import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import '@mantine/core/styles.layer.css';
import '@mantine/core/styles/Button.css';
import '@mantine/core/styles/Input.css';
import '@mantine/core/styles/Menu.css';
import '@mantine/core/styles/UnstyledButton.css';
import '@mantine/dates/styles.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/notifications/styles.css';
import ReactDOM from 'react-dom/client';
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import './locales/locales';
import { store } from './store/store';
import { CustomMuntineProvider } from './theme';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
const TRACKING_ID = "G-QMXDV4CXLC"
// const TRACKING_ID = "G-W18CXNYPH0"
ReactGA.initialize(TRACKING_ID)

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <CustomMuntineProvider>
          <App />
        </CustomMuntineProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);
