import { Center, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NewsForm } from 'src/components';
import { usePageTitle } from 'src/hooks';
import { useGetNewsQuery } from 'src/store/reducers/all-reducers/newsApiSlice';
import { AppShellCustom, CustomLoader } from 'src/ui-kit';

type Params = {
  newsId: string;
};

export const EditNews = () => {
  const { t } = useTranslation();
  const title = t`pages.title.edit-news`;
  usePageTitle(title);

  const { newsId } = useParams<Params>();
  const { data, isLoading } = useGetNewsQuery(newsId as unknown as string, {
    refetchOnMountOrArgChange: !!newsId,
  });

  return (
    <AppShellCustom>
      {isLoading ? (
        <CustomLoader />
      ) : (
        data && (
          <>
            <Center>
              <Title fw="normal" mb="1rem">
                {title}
              </Title>
            </Center>
            <NewsForm news={data} />
          </>
        )
      )}
    </AppShellCustom>
  );
};
