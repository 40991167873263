import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const socialLinksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createLink: builder.mutation<LinkResponseType, LinksRequestTypes>({
      query: (body) => {
        const formData = new FormData();
        const { photo, ...bodyData } = body;
        const data = new Blob([JSON.stringify(bodyData)], {
          type: 'application/json',
        });
        formData.append('socialLinkRequest', data);
        photo && formData.append('attachments', photo);
        return {
          url: Path.SocialLinks.create,
          method: 'POST',
          body: formData,
        };
      },
    }),
    getAllLinks: builder.query<LinkResponseType[], unknown>({
      query: () => ({
        url: Path.SocialLinks.getAll,
        cache: 'no-store',
      }),
      providesTags: ['SocialLinks'],
    }),
    getLink: builder.query<LinkResponseType, string>({
      query: (id) => ({
        url: Path.SocialLinks.get(id),
        method: 'GET',
      }),
    }),
    deleteLink: builder.mutation({
      query: (id) => ({
        url: Path.SocialLinks.delete(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['SocialLinks'],
    }),
    linksSearch: builder.mutation<
      SocialLinksSearchResponse,
      SocialLinksSearchRequest
    >({
      query: (postData) => ({
        url: Path.SocialLinks.linkSearch,
        method: 'POST',
        body: postData,
        cache: 'no-store',
      }),
      invalidatesTags: ['SocialLinks'],
    }),
    updateLinks: builder.mutation<
      LinkResponseType,
      { id: number; body: Partial<LinksRequestTypes> }
    >({
      query: ({ id, body }) => {
        const formData = new FormData();
        const { photo, ...bodyDate } = body;
        const data = new Blob([JSON.stringify(bodyDate)], {
          type: 'application/json',
        });
        formData.append('request', data);
        photo && formData.append('attachments', photo);
        return {
          url: Path.SocialLinks.updateLink(id),
          method: 'PUT',
          body: formData,
        };
      },
      invalidatesTags: ['SocialLinks'],
    }),
  }),
});

export const {
  useCreateLinkMutation,
  useGetAllLinksQuery,
  useGetLinkQuery,
  useDeleteLinkMutation,
  useLinksSearchMutation,
  useUpdateLinksMutation,
} = socialLinksApiSlice;
