import { Box, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { createStyles } from "@mantine/styles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useGetHiddenPaheByIdQuery } from "src/store/reducers/all-reducers/hiddenPageApiSlice";
import { EmployeesPage } from "./employyes";
import { PagesPage } from "./pages-page";

interface IHTMLRenderProps {
    htmlContent: string | undefined;
}

const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
    if (!htmlContent) {
        return null;
    }
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export const HiddenPage = () => {
    const screenSize = useMediaQuery('(max-width: 1200px)');
    const { i18n } = useTranslation();
    const { classes } = useStyles();
    const { pathname } = useLocation();
    const code = pathname.split('/').reverse()[0];

    const { data: hiddenPageData } = useGetHiddenPaheByIdQuery(code, {
        refetchOnMountOrArgChange: true,
    })

    const getTitle = (titles: PageType | undefined): string => {
        const title = i18n.language === 'ru' ? titles?.titleRu : i18n.language === 'kg' ? titles?.titleKg : titles?.titleEn;
        return title || '';
    };

    return (
        <Box className={classes.containerblock}>
            <Box className={classes.contentConteiner}>
                <Text fz={screenSize ? 22 : 32} fw={700} c="#06297B">
                    {getTitle(hiddenPageData)}
                </Text>

                {hiddenPageData?.pageListType === 'EMPLOYEES_PAGE' && <EmployeesPage employeeType={hiddenPageData.employeeListType} />}
                {hiddenPageData?.childrens &&
                    <Box style={{ display: "flex", flexDirection: "column", gap: "1.2rem", marginTop: "1rem" }}>
                        {hiddenPageData?.childrens?.map((items) => (
                            <Box key={items.id}>
                                <PagesPage items={items} />
                            </Box>))}
                    </Box>
                }

                {hiddenPageData && (<HTMLRender htmlContent={getTitle(hiddenPageData.content)} />)}
            </Box>
        </Box>
    )
}

const useStyles = createStyles(() => ({
    containerblock: {
        minHeight: '60dvh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '2rem 7%',
    },
    contentConteiner: {
        padding: '0.5rem 0',
        color: '#111',
        marginBottom: '19rem',
        overflow: "hidden"
    },
}));
