import { Container, Paper, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ProfileView } from 'src/components';
import { usePageTitle } from 'src/hooks';
import { useGetEmployeeMeQuery } from 'src/store/reducers/all-reducers/employeeApiSlice';
import { AppShellCustom, CustomLoader } from 'src/ui-kit';

export const Profile = () => {
  const { t } = useTranslation();
  const { data: employeeData } = useGetEmployeeMeQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const title = t`pages.title.profile`;
  usePageTitle(title);

  return (
    <AppShellCustom>
      <Container size="xl">
        <Title fw="normal" mb="1rem">
          {title}
        </Title>
        {!employeeData ? (
          <CustomLoader />
        ) : (
          <Paper shadow="sm" p="xl">
            <ProfileView employeeData={employeeData} />
          </Paper>
        )}
      </Container>
    </AppShellCustom>
  );
};
