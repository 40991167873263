import { Box, Loader } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import { useTranslation } from "react-i18next";
import { NewsCard, NoDataUi, RouteWithLang } from "src/components";
import { NewsSearchResponse } from "src/types/news";

interface IProps {
    isLoading: boolean
    newsData?: NewsSearchResponse
}

export const NewsPageResult = ({ isLoading, newsData }: IProps) => {
    const { i18n } = useTranslation();
    const { classes } = useStyles();

    if (isLoading) {
        return <Loader color="blue" />;
    }

    if (!newsData?.content || newsData?.content.length === 0 && !isLoading) {
        return <NoDataUi />;
    }

    return (
        <Box className={classes.eventsContainer}>
            {newsData?.content?.map((items) => (
                <RouteWithLang to={`/public-news/${items.id}`} key={items.id}>
                    <NewsCard
                        date={items.dateFrom}
                        content={i18n.language === 'ru' ? items.content?.titleRu : i18n.language === 'kg' ? items.content?.titleKg : items.content?.titleEn}
                        title={i18n.language === 'ru' ? items?.titleRu : i18n.language === 'kg' ? items?.titleKg : items?.titleEn}
                        photo={items.photoAlbum?.photo}
                    />
                </RouteWithLang>
            ))}
        </Box>
    )
}
const useStyles = createStyles(() => ({
    eventsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        gap: '1rem',
    },
}));