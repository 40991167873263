type key =
  | 'token'
  | 'refreshToken'
  // | "userInn"
  | 'profile'
  | 'i18nextLng'
  // | "accessToken"
  // | "allData"
  | 'colorScheme'
  | 'i18nextLngOpen';

export const useLocalStorage = (name: key) => {
  const setStorage = (data: string | object) => {
    if (typeof data === 'string') {
      return localStorage.setItem(name, data);
    } else {
      return localStorage.setItem(name, JSON.stringify(data));
    }
  };

  const getStorage = () => {
    const data = localStorage.getItem(name);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return data;
      }
    }
    return null;
  };

  const removeStorage = () => {
    localStorage.removeItem(name);
  };

  const clearAllStorage = () => localStorage.clear();

  return {
    setStorage,
    getStorage,
    removeStorage,
    clearAllStorage,
  };
};
