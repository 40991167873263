// import React, { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';
// import { Button, Group, Indicator, Paper, TextInput } from '@mantine/core';
// import 'dayjs/locale/ru';
// import 'dayjs/locale/ky';
// import { Calendar, TimeInput } from "@mantine/dates";
// import { useLocalStorage } from "../../hooks";
// import { IconCheck, IconClockHour10 } from '@tabler/icons-react';
// import { DateFormatTo } from "../date-format-to";
// import { createStyles } from '@mantine/styles';

// type Props = {
//   label: string;
//   required?: boolean;
//   error?: React.ReactNode;
//   defaultValue?: string;
//   disabled?: boolean;
//   rightSection?: React.ReactNode;
//   formData?: Date | string;
//   onChange: (value: Date | string | undefined) => void;
// }

// export const DateTimePicker: FC<Props> = ({
//   label,
//   required,
//   error,
//   disabled,
//   formData,
//   rightSection,
//   onChange,
// }) => {

//   const ref = useRef<HTMLDivElement>(null)
//   const lang = useLocalStorage('i18nextLng').getStorage()

//   const [open, setOpen] = useState<boolean>(false)
//   const [value, setValue] = useState<Date | null>(null)

//   const { classes } = useStyles({ floating: !!value })

//   const handleClick = (event: MouseEvent) => {
//     if (ref.current && !ref.current.contains(event.target as Node)) {
//       setOpen(false)
//     }
//   }

//   useEffect(() => {
//     if (formData) {
//       setValue(new Date(formData))
//     }

//     document.addEventListener('click', handleClick)
//     return () => {
//       document.removeEventListener('click', handleClick)
//     }
//   }, [formData])

//   const handleValueChange: ChangeEventHandler<HTMLInputElement> = event => {
//     const inputValue: string = event.target.value;
//     const parsedDate =
//       typeof inputValue === "string" ? new Date(inputValue) : inputValue;

//     if (value) {
//       parsedDate.setHours(value.getHours());
//       parsedDate.setMinutes(value.getMinutes());
//     }

//     setValue(parsedDate);
//     onChange(DateFormatTo(parsedDate, "YYYY-MM-DDTHH:mm"));
//     document?.getElementById(`${label}`)?.focus();
//   };

//   const handleTimeChange: ChangeEventHandler<HTMLInputElement> = event => {
//     const inputValue = event.target.value;
//     const parsedDate =
//       typeof inputValue === "string" ? new Date(inputValue) : inputValue;

//     if (value) {
//       value.setHours(parsedDate.getHours());
//       value.setMinutes(parsedDate.getMinutes());
//       setValue(parsedDate);
//       onChange(DateFormatTo(parsedDate, "YYYY-MM-DDTHH:mm"));
//     }
//   };

//   const handleOk = () => {
//     setOpen(false)
//   }

//   return (
//     <div ref={ref}>
//       <TextInput
//         label={label}
//         required={required}
//         error={error}
//         disabled={disabled}
//         rightSection={rightSection}
//         classNames={classes}
//         readOnly={true}
//         value={value ? DateFormatTo(value, 'DD-MM-YYYY HH:mm') : ''}
//         onClick={() => setOpen((e) => !e)}
//         autoComplete='nope'
//         mt="md"
//       />
//       <Paper
//         radius={15}
//         shadow="lg" p="xl"
//         style={{ width: 300, position: 'absolute', zIndex: '5' }}
//         display={open ? 'block' : 'none'}
//       >
//         {/* <Calendar
//           locale={lang === 'RU' ? 'ru' : 'ky'}
//           // initialMonth={new Date()}
//           onChange={handleValueChange}
//           __staticSelector="DateTimePicker"
//           preventFocus={false}
//           mb="sm"
//         /> */}
//         <Calendar
//           onChange={handleValueChange}
//           locale={lang === "ru" ? "ru" : "ky"}
//           maxDate={new Date()}
//           getDayProps={date => ({
//             selected: value === date,
//           })}
//           renderDay={date => {
//             const day = date.getDate();
//             const today =
//               day !== new Date().getDate() ||
//               date.getMonth() !== new Date().getMonth();
//             return (
//               <Indicator size={6} color="green" offset={-2} disabled={today}>
//                 <div>{day}</div>
//               </Indicator>
//             );
//           }}
//         />
//         <Group align="center">
//           <TimeInput
//             id={`${label}`}
//             style={({ flexGrow: 1 })}
//             rightSection={<IconClockHour10 />}
//             value={value?.toString()}
//             onChange={handleTimeChange}
//           />
//           <Button mr="xs" variant='outline' color='gray' p={8} disabled={!value} onClick={handleOk}>
//             <IconCheck size={20} />
//           </Button>
//         </Group>
//       </Paper>
//     </div>
//   )
// }

// const useStyles = createStyles(
//   (theme, { floating }: { floating: boolean }) => ({
//     root: {
//       position: 'relative',
//     },
//     label: {
//       position: 'absolute',
//       zIndex: 2,
//       top: 7,
//       left: theme.spacing.sm,
//       pointerEvents: 'none',
//       color: floating
//         ? theme.colorScheme === 'light'
//           ? theme.colors.dark[4]
//           : theme.white
//         : theme.colorScheme === 'dark'
//           ? theme.colors.dark[3]
//           : theme.colors.gray[5],
//       transition:
//         'transform 150ms ease, color 150ms ease, font-size 150ms ease',
//       transform: floating ? `translate(-${theme.spacing.sm}px, -28px)` : 'none',
//       fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
//       fontWeight: 600,
//     },
//     required: {
//       transition: 'opacity 150ms ease',
//       opacity: floating ? 1 : 0.5,
//     },
//     input: {
//       '&::placeholder': {
//         transition: 'color 150ms ease',
//         color: !floating ? 'transparent' : undefined,
//       },
//     },
//   })
// );

import React, { FC, useEffect, useRef, useState } from 'react';
import 'dayjs/locale/ru';
import 'dayjs/locale/ky';
import { DateValue } from '@mantine/dates';
import { DateFormatTo } from '../date-format-to';

type Props = {
  label: string;
  required?: boolean;
  error?: React.ReactNode;
  defaultValue?: string;
  disabled?: boolean;
  rightSection?: React.ReactNode;
  formData?: string | undefined;
  onChange: (value: DateValue) => void;
};

export const DateTimePicker: FC<Props> = ({
  label,
  required,
  error,
  disabled,
  formData,
  rightSection,
  onChange,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  // const lang = useLocalStorage('i18nextLng').getStorage()
  // const [isLabel, setIsLabel] = useState<boolean>(true)

  // const [open, setOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string | undefined>(undefined);

  // const { classes } = useStyles({ floating: !!value })

  // const handleClick = (event: MouseEvent) => {
  //   if (ref.current && !ref.current.contains(event.target as Node)) {
  //     setOpen(false)
  //   }
  // }

  useEffect(() => {
    if (formData) {
      setValue(formData);
    }

    // document.addEventListener('click', handleClick)
    // return () => {
    //   document.removeEventListener('click', handleClick)
    // }
  }, [formData]);

  const handleValueChange = (date: DateValue) => {
    if (value) {
      date?.setHours(new Date(value).getHours());
      date?.setMinutes(new Date(value).getMinutes());
    }
    setValue(`${date}`);
    date && onChange(new Date(DateFormatTo(date, 'YYYY-MM-DDTHH:mm')));
    document?.getElementById(`${label}`)?.focus();
    // setIsLabel(false)
  };

  // const handleTimeChange = (date: Date) => {
  //   if (value) {
  //     value.setHours(date.getHours())
  //     value.setMinutes(date.getMinutes())
  //     setValue(value)
  //     onChange(DateFormatTo(value, 'YYYY-MM-DDTHH:mm'))
  //   }
  // }

  // const handleOk = () => {
  //   setOpen(false)
  // }

  return (
    <div ref={ref}>
      <DateTimePicker
        onChange={handleValueChange}
        // {...form.getInputProps('dateFrom')}
        error={error}
        formData={formData}
        defaultValue={value}
        required={required}
        label={label}
        // onChange={function (value: string | Date | undefined): void {
        //   throw new Error('Function not implemented.');
        // }}
      />
      {/* <TextInput
        label={isLabel ? label : ""}
        required={required}
        error={error}
        disabled={disabled}
        rightSection={rightSection}
        classNames={classes}
        readOnly={true}
        value={value ? DateFormatTo(value, 'DD-MM-YYYY HH:mm') : ''}
        onClick={() => setOpen((e) => !e)}
        autoComplete='nope'
        mt="md"
      />
      <Paper
        radius={15}
        shadow="lg" p="xl"
        style={{ width: 300, position: 'absolute', zIndex: '5' }}
        display={open ? 'block' : 'none'}
      >
        <DatePicker
          locale={lang === 'RU' ? 'ru' : 'ky'}
          onChange={handleValueChange}
          __staticSelector="DateTimePicker"
          mb="sm"
        />
        <Group align="center">
          <TimeInput
            id={`${label}`}
            sx={({ flexGrow: 1 })}
            icon={<IconClockHour10 />}
            value={value}
            onChange={handleTimeChange}
            format='24'
          />
          <Button mr="xs" variant='outline' color='gray' p={8} disabled={!value} onClick={handleOk}>
            <IconCheck size={20} />
          </Button>
        </Group>
      </Paper> */}
    </div>
  );
};
