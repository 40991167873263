import { Box, UnstyledButton } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteWithLang } from 'src/components';
import { useSearchPageMutation } from 'src/store/reducers/all-reducers/pageApiSlice';

export const Middle = () => {
  const mobileSize = useMediaQuery("(max-width: 380px)")
  const [pageData, setPageData] = useState<PageType[] | null>(null);
  const { i18n } = useTranslation()
  const [getPagesByType] = useSearchPageMutation();

  const getSocialLinks = useCallback(async () => {
    try {
      const response = await getPagesByType({
        filter: {
          pageListPosition: 'HEADER',
          onlyParent: true,
        },
        pageRequest: {
          limit: 6,
        },
      });
      if ('data' in response && 'content' in response.data)
        setPageData(response.data.content);
    } catch (error) {
      console.log(error);
    }
  }, [getPagesByType]);

  useEffect(() => {
    getSocialLinks();
  }, [getSocialLinks]);

  return (
    <Box
      style={{
        backgroundColor: '#06297B',
        width: '100%',
        display: 'flex',
        color: 'white',
        padding: '38px 3%',
        flexWrap: 'wrap',
        gap: '24px',
        borderRadius: '4px',
        flexDirection: mobileSize ? "column" : "row"
      }}
    >
      {pageData?.map((items) => (
        <Box key={items.id}>
          {items.link ? (
            <UnstyledButton
              component="a"
              href={items.link}
              target="_blank"
              fz={{ base: 15, sm: 18 }}
            >
              {i18n.language === 'ru' ? items?.titleRu : i18n.language === 'kg' ? items?.titleKg : items?.titleEn}
            </UnstyledButton>
          ) : (
            <RouteWithLang to={`/pages/${items.id}`}>
              <UnstyledButton c={"white"} fz={{ base: 15, sm: 18 }}>
                {i18n.language === 'ru' ? items?.titleRu : i18n.language === 'kg' ? items?.titleKg : items?.titleEn}
              </UnstyledButton>
            </RouteWithLang>
          )}
        </Box>
      ))}
    </Box>
  );
};
