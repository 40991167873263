import { Box, Center, Container, Grid, Title } from '@mantine/core';
import { createStyles, keyframes } from '@mantine/styles';
// import { LogoSvg } from 'src/statics';

const useStyles = createStyles(
  (
    theme,
    {
      bounceUp,
      bounceDown,
      bounceRotate,
    }: {
      bounceUp: string;
      bounceDown: string;
      bounceRotate: string;
    },
  ) => ({
    card: {
      backgroundColor:
        theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    },
    logoBlock: {
      height: '11rem',
    },
    container: {
      width: '100%',
      margin: 'auto',
    },
    bounceUp: {
      animation: `${bounceUp} 2s ease-in-out`,
    },
    topWrapper: {
      animation: `${bounceDown} 2s ease-in-out`,
    },
    bounceRotate: {
      animation: `${bounceRotate} 2s ease-in-out`,
    },
  }),
);

type Props = {
  children?: React.ReactNode;
};

const SignInDesign = ({ children }: Props) => {
  const bounceUp = keyframes({
    '0%': {
      transform: 'translateY(-15vh)',
      opacity: -1,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  });

  const bounceDown = keyframes({
    '0%': {
      transform: 'translateY(-2vh)',
      opacity: -1,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  });

  const bounceRotate = keyframes({
    '0%': {
      transform: 'rotateY(0deg)',
    },
    '100%': {
      transform: 'rotateY(360deg)',
    },
  });

  const { classes } = useStyles({
    bounceUp: bounceUp,
    bounceDown: bounceDown,
    bounceRotate: bounceRotate,
  });

  return (
    <Box style={{ height: '100dvh', padding: '2rem 0' }}>
      <Grid className={classes.container} columns={24} m={'auto'}>
        <Grid.Col span={9}>
          <Title
            order={5}
            style={{
              fontWeight: 500,
              fontSize: 20,
              paddingTop: '1rem',
              textAlign: 'center',
            }}
          >
            МИНИСТЕРСТВО ЮСТИЦИЙ КР
          </Title>
        </Grid.Col>
        <Grid.Col span={6}>
          <Center>
            <div className={classes.logoBlock}>
              {/* <LogoSvg size={80} /> */}
            </div>
          </Center>
        </Grid.Col>
        <Grid.Col span={9}>
          <Title
            order={5}
            style={{
              fontWeight: 500,
              fontSize: 20,
              paddingTop: '1rem',
              textAlign: 'center',
            }}
          >
            МИНИСТЕРСТВО ЮСТИЦИЙ КР
          </Title>
        </Grid.Col>
      </Grid>
      <Container
        style={{
          width: '100%',
          paddingTop: '3rem',
          display: 'flex',
          justifyContent: 'center',
        }}
        size={'xl'}
      >
        {children}
      </Container>
    </Box>
  );
};

export default SignInDesign;
