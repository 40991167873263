import { createContext, useContext } from 'react';

interface LanguageContextProps {
  changeRouteLanguage: (newLang: string) => void;
}

export const LanguageContext = createContext<LanguageContextProps | undefined>(
  undefined,
);

export const useLanguage = (): LanguageContextProps => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
