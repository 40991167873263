import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const newsPagesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    searchPagesNews: builder.mutation<
      PagesNewsSearchResponse,
      PagesNewsSearchRequest
    >({
      query: (searchData) => ({
        url: Path.PagesAndNewsSearch.search,
        method: 'POST',
        body: searchData,
        cache: 'no-store',
      }),
    }),
  }),
});

export const { useSearchPagesNewsMutation } = newsPagesApiSlice;
