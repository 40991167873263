import { MantineStyleProp, Text } from '@mantine/core';

const options: Intl.DateTimeFormatOptions | undefined = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

interface IProps {
  className?: string | undefined;
  printDate: string;
  style?: MantineStyleProp;
}

export const DateWithOptions = ({ printDate, className, style }: IProps) => {
  return (
    <Text className={className} style={style} fz={{ base: 14, lg: 18 }}>
      {new Date(printDate).toLocaleString('ru', options)}
    </Text>
  );
};
