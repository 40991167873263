import { Center, Image } from '@mantine/core';
import { BirdLogo } from 'src/assets';
import { LinksTo } from 'src/components';
import { Bottom } from './bottom';
import { Middle } from './middle';
import { Top } from './top';

export const Footer = () => {
  return (
    <Center display="flex" style={{ flexDirection: 'column', gap: '1rem', padding: '2.5rem 7%', backgroundColor: 'white', position: 'relative' }}>
      <Top />
      <Image src={BirdLogo} style={{ position: 'absolute', maxWidth: '37.5rem', top: '22%', zIndex: 0 }} />
      <LinksTo />
      <Middle />
      <Bottom />
    </Center>
  );
};
