import React, { FC } from 'react';
import { DatePicker } from '@mantine/dates';
// import { createStyles } from '@mantine/core';
import { useLocalStorage } from '../../hooks';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/ky';

type Props = {
  label: string | null;
  required?: boolean;
  error?: React.ReactNode;
  defaultValue?: string;
  disabled?: boolean;
  readOnly?: boolean;
  formData?: string | Date | null;
  onChange: (value: string | null) => void;
};

export const CustomDatePicker: FC<Props> = ({
  label,
  required,
  error,
  disabled,
  readOnly,
  formData,
  onChange,
}) => {
  // const { classes } = useStyles({
  //   floating: !!formData
  // });

  const lang = useLocalStorage('i18nextLng').getStorage();

  return (
    <DatePicker
      locale={lang === 'RU' ? 'ru' : 'ky'}
      // label={label}
      onChange={(value) => {
        if (value) {
          onChange(dayjs(value).format('YYYY-MM-DD'));
        } else {
          onChange(null);
        }
      }}
      value={formData ? new Date(formData ?? '') : null}
      // readOnly={readOnly}
      // inputFormat="YYYY-MM-DD"
      // required={required}
      // classNames={classes}
      // error={error}
      // disabled={disabled}
      mt={'md'}
      // autoComplete="nope"
    />
  );
};

// const useStyles = createStyles(
//   (theme, { floating }: { floating: boolean }) => ({
//     root: {
//       position: 'relative',
//     },
//     label: {
//       position: 'absolute',
//       zIndex: 2,
//       top: 7,
//       left: theme.spacing.sm,
//       pointerEvents: 'none',
//       color: floating
//         ? theme.colorScheme === 'light'
//           ? theme.colors.dark[4]
//           : theme.white
//         : theme.colorScheme === 'dark'
//           ? theme.colors.dark[3]
//           : theme.colors.gray[5],
//       transition:
//         'transform 150ms ease, color 150ms ease, font-size 150ms ease',
//       transform: floating ? `translate(-${theme.spacing.sm}px, -28px)` : 'none',
//       fontSize: floating ? theme.fontSizes.xs : theme.fontSizes.sm,
//       fontWeight: 600,
//     },
//     required: {
//       transition: 'opacity 150ms ease',
//       opacity: floating ? 1 : 0.5,
//     },
//     input: {
//       '&::placeholder': {
//         transition: 'color 150ms ease',
//         color: !floating ? 'transparent' : undefined,
//       },
//     },
//   })
// );
