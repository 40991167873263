import { Center, Container, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { AppShellCustom } from 'src/ui-kit';
import { usePageTitle } from 'src/hooks';
import { useParams } from 'react-router-dom';
import { OfficeDetails } from 'src/components';

type Params = {
  officeId: string;
};

export const OfficeView = () => {
  const { t } = useTranslation();
  const { officeId } = useParams<Params>();
  const title = t`pages.title.view-offices`;
  usePageTitle(title);

  return (
    <AppShellCustom>
      <Container size="xl">
        <Center>
          <Title fw="normal" mb="1rem">
            {title}
          </Title>
        </Center>
        {officeId && <OfficeDetails officeId={officeId} />}
      </Container>
    </AppShellCustom>
  );
};
