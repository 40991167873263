import { Box, Button, Center, Text } from '@mantine/core';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { backgoundPhoto } from 'src/assets';
import { RouteWithLang } from 'src/components';
import { useSortByField } from 'src/hooks';
import { useGetPageStructureWithLimitedFieldsQuery } from 'src/store/reducers/all-reducers/pageApiSlice';
import { StructureChildrens } from 'src/types/global';

type Data = {
  id: number;
  key: number;
  titleRu: string;
  titleKg: string;
  titleEn: string;
  link: string;
  childrens?: StructureChildrens[];
  rating: number;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const BlockWithPhoto = () => {
  const { data } = useGetPageStructureWithLimitedFieldsQuery({}, { refetchOnMountOrArgChange: true, });
  const { t, i18n } = useTranslation()

  const mewPages: Data[] = useSortByField(data, "rating");

  return (
    <Box h={'85dvh'} py={24} w="100vw" style={{
      marginBottom: '40px', backgroundImage: `url(${backgoundPhoto})`, backgroundSize: 'cover',
      backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat'
    }}>
      <Center h="100%" style={{ display: 'flex', alignItems: 'flex-start', padding: '0 7%', flexDirection: 'column', gap: '80px', }}>
        <Text fz={{ base: 28, xs: 44, lg: 64 }} lh={1} c="white" style={{ fontWeight: '700', marginBottom: '16px' }}>
          {t("titles.minjust")}<br />{t("titles.kyrgyz_republic")}
        </Text>
        <Box w="100%">
          <Text style={{
            backgroundColor: '#950E0E', width: 'fit-content', fontSize: '1rem', color: 'white',
            marginBottom: '0.25rem', padding: '0.688rem 1rem', borderRadius: '0.25rem',
          }}>
            {t("titles.famous_requests")}
          </Text>
          <Box style={{ display: 'flex', gap: '4px', width: '65%', flexWrap: 'wrap' }}>
            {mewPages &&
              mewPages.map((items: Data) => {
                if (items.link) {
                  return (
                    <Button component="a" href={items.link} variant="simple" target="_blank" key={items.id}>
                      {i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn}
                    </Button>
                  );
                }
                return (
                  <RouteWithLang to={`/pages/${items.id}`} key={items.id} style={{ overflow: "hidden" }}>
                    <Button variant="simple">
                      {i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn}
                    </Button>
                  </RouteWithLang>
                );
              })}
          </Box>
        </Box>
      </Center>
    </Box>
  );
};
