import { Button, Center, Flex, Image, Paper, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Car } from 'src/assets';
import { RouteWithLang } from 'src/components';
import { base64ImgPng } from 'src/constants';

interface IProps {
  title?: string;
  photo?: string;
  pageId: number;
  link?: string;
}

export const RouteCard = ({ title, photo, pageId, link }: IProps) => {
  const { t } = useTranslation();
  const base64Format = `${base64ImgPng}${photo}`;
  return (
    <>
      {!link ? (
        <Paper variant="bg-opacity" p={{ base: "1rem", sm: "1rem 0.5rem", md: "1.5rem" }} style={{ padding: "inherit" }} component={RouteWithLang} to={`/pages/${pageId}`}>
          <Center>
            <Image src={photo ? base64Format : Car} h={{ base: 90, sm: 96, lg: 123 }} />
          </Center>
          <Flex gap={4} align="center" justify="space-between">
            <Text c="#101828" fz={{ base: 16, sm: 16, md: 18, lg: 20, x2l: 24 }} fw={500} lh={'24px'} w="auto">
              {title}
            </Text>
            <Button fw={500} fz={16} rightSection={<IconArrowRight size={20} />} px={7} miw={"fit-content"}>
              {t("more")}
            </Button>
          </Flex>
        </Paper>
      ) : (
        <Paper variant="bg-opacity" p={{ base: "1rem", sm: "1rem 0.5rem", md: "1.5rem" }} component="a" href={link} target="_blank">
          <Center>
            <Image src={photo ? base64Format : Car} h={{ base: 90, sm: 96, lg: 123 }} />
          </Center>
          <Flex gap={4} align="center" justify="space-between">
            <Text c="#101828" fz={{ base: 16, sm: 16, md: 18, lg: 20, x2l: 24 }} fw={500} lh={'24px'} w="auto">
              {title}
            </Text>
            <Button fw={500} fz={16} rightSection={<IconArrowRight size={22} />} px={7} miw={"fit-content"}>
              {t("more")}
            </Button>
          </Flex>
        </Paper>
      )}
    </>
  );
};
