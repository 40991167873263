import { Box, Group, Text } from '@mantine/core';
import { ProfileActions } from './profile-actions';
import { DarkTheme } from '../dark-theme';
import { createStyles } from '@mantine/styles';
import { useTranslation } from 'react-i18next';
import { ChangeLanguage } from '..';

export const Navbar = () => {
  const { t } = useTranslation()

  const { classes } = useStyles();
  return (
    <Box h={60} className={classes.navbar}>
      <Group gap={3} display="flex">
        <Text fz={25} className={classes.text}>
          {t("titles.logo")}
        </Text>
      </Group>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <DarkTheme />
        <ChangeLanguage color='black' />
        <ProfileActions />
      </Box>
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  navbar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 2rem',
    boxShadow: '2px 2px 10px rgba(4, 15, 34, 0.34)',
    borderBottom: 'none',
    backgroundColor: theme.colorScheme === 'dark' ? '#25272D' : '#fff',
  },
  text: {
    fontWeight: 600,
    color: theme.colorScheme === 'dark' ? '#fff' : '#000',
  },
}));
