import { SignInForm } from 'src/components';
import SignInDesign from './sign-in-design';

export const SignInPage = () => {
  return (
    <SignInDesign>
      <SignInForm />
    </SignInDesign>
  );
};
