import { Carousel } from "@mantine/carousel"
import { Box, Image } from "@mantine/core"
import { useEffect, useState } from "react"
import { base64ImgJpeg } from "src/constants"

interface IProps {
    photos: PhotoType[]
    className?: string
}

export const PhotoCollage = (props: IProps) => {
    const [photoState, setPhotoState] = useState<string>(`${props.photos[0].original}`)
    const handleClick = (photo: string) => {
        setPhotoState(photo)
    }
    useEffect(() => { setPhotoState(props.photos[0].original) }, [props.photos])
    return (
        <Box>
            <Box style={{ position: "relative", width: "100%", paddingBottom: "56.25%" }} mb="2rem">
                <Image
                    src={`${base64ImgJpeg}${photoState}`}
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover"
                    }}
                />
            </Box>
            <Carousel
                slideSize={{ base: "50%", sm: "40%", md: "30%" }}
                align="start"
                slideGap="2rem"
                withControls={false}
                loop
            >
                {props.photos.map((items, index) => (
                    <Carousel.Slide
                        key={`${items}${index}`}
                        onClick={() => handleClick(items.original)}
                        style={{ cursor: "pointer" }}
                    >
                        <Box style={{ position: "relative", width: "100%", paddingBottom: "56.25%" }}>
                            <Image
                                src={`${base64ImgJpeg}${items.original}`}
                                alt={`${index}`}
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "0.2rem"
                                }}
                            />
                        </Box>
                    </Carousel.Slide>
                ))}
            </Carousel>
        </Box>
    )
}
