import { useEffect } from "react";
import { Button, Menu } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";
import { useCookie } from "../hooks";
import { useLocation } from "react-router-dom";
import { IconChevronDown } from "@tabler/icons-react";
import { createStyles } from "@mantine/styles";

const Language = {
    kg: { nativeName: "Кыргызча" },
    ru: { nativeName: "Русский" },
};

interface IProps {
    white?: boolean;
}

export const ChangeLang = ({ white }: IProps) => {
    const { i18n } = useTranslation();
    const { pathname } = useLocation();
    const { classes } = useStyles({ white, pathname });
    const { getCookie, setCookie } = useCookie("i18next");
    const currentLng = getCookie();

    const changeLng = (lng: string) => {
        i18n.changeLanguage(lng);
        setCookie(lng);
    };

    useEffect(() => {
        if (currentLng) {
            i18n.changeLanguage(currentLng);
        }
    }, [currentLng, i18n]);

    return (
        <Menu
            width={100}
            trigger="hover"
            openDelay={100}
            closeDelay={400}
            zIndex={1000}
        >
            <Menu.Target>
                <Button
                    className={classes.btn}
                    variant="subtle"
                    size="md"
                    p={0}
                    rightSection={<IconChevronDown size="1rem" />}
                >
                    <Trans i18Key={currentLng}>
                        {currentLng === "ru" ? "Русский" : "Кыргызча"}
                    </Trans>
                </Button>
            </Menu.Target>
            <Menu.Dropdown>
                {Object.entries(Language).map(([lng, { nativeName }]) => (
                    <Menu.Item key={lng} onClick={() => changeLng(lng)}>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                fontSize: 13,
                            }}
                        >
                            <Trans i18Key={lng}>{nativeName}</Trans>
                        </div>
                    </Menu.Item>
                ))}
            </Menu.Dropdown>
        </Menu>
    );
};

const useStyles = createStyles(
    (_theme, { white }: { white?: boolean; pathname?: string }) => ({
        btn: {
            borderRadius: "0",
            color: white ? "#fff" : "#111",
            "&:hover": {
                background: "none",
                color: white ? "#adcaf7" : "#2e61b0",
            },
        },
    })
);
