export {
  MainPage,
  NewsPage,
  NewsDetail,
  UniversalPage,
  HiddenPage,
} from './open';

export {
  PrivatePage,
  SocialLinksPage,
  AddLink,
  EditSocialLinks,
  SocialLinkView,
  News,
  AddNews,
  EditNews,
  NewsView,
  Page,
  AddPage,
  EditPage,
  SignInPage,
  Profile,
  AddCardInfo,
  CardInfo,
  CardInfoView,
  EditCardInfo,
  AddOffice,
  EditOffice,
  Office,
  OfficeView,
} from './close';

export { NotFoundPage, PageConstruction } from './errors';
