import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { CustomLoader, CustomText, TextInfo } from 'src/ui-kit';
import { getTitleByLanguage } from 'src/locales';
import { useGetCardQuery } from 'src/store/reducers/all-reducers/cardApiSlice';

type Props = {
  cardId: string;
};

export const CardInfoDetails = ({ cardId }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data: cardsData, isLoading } = useGetCardQuery(cardId, {
    refetchOnMountOrArgChange: true,
  });

  const actsCard = [
    {
      label: t`data.news.title`,
      value: getTitleByLanguage(cardsData?.fullName),
    },
  ];

  return isLoading ? (
    <CustomLoader />
  ) : (
    <Paper shadow="sm" p="xl">
      <Paper shadow="sm" p="xl">
        <TextInfo data={actsCard} display={true} spanFirst={4} spanTwo={8} />
        {cardsData?.fullName && (
          <Box className={classes.content}>
            <CustomText
              label={t`data.news.content`}
              value={
                <div
                  dangerouslySetInnerHTML={{
                    __html: getTitleByLanguage(cardsData.fullName),
                  }}
                />
              }
            />
          </Box>
        )}
      </Paper>
    </Paper>
  );
};

const useStyles = createStyles({
  content: {
    '& img': {
      maxWidth: '70rem',
    },
  },
});
