import { Accordion, Box, Button, Flex, Menu, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { IconChevronDown } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuArrowSvg } from "src/assets";
import { RouteWithLang } from 'src/components';

interface IPorps {
  data?: PageType[] | null;
  close?: () => void
}

const sortByRating = (objects: PageType[]): PageType[] => {
  let sortedObjects: PageType[] = [];
  if (objects) {
    sortedObjects = objects
      .filter((item) => item.significance >= 0)
      .slice()
      .sort((a, b) => b.significance - a.significance);
  }
  return sortedObjects;
};

export const DynamicRoutes = ({ data, close }: IPorps) => {
  const [sortedBySignificance, setSortedBySignificance] = useState<PageType[]>([])
  const largeScreen = useMediaQuery(`(max-width: 1400px)`);
  const { i18n } = useTranslation();
  const { classes } = useStyles();
  useEffect(() => {
    setSortedBySignificance(sortByRating(data ? data : []))
  }, [data])

  return (
    <Box style={{ display: 'flex', alignItems: largeScreen ? 'flex-start' : 'center', gap: '1.5rem', flexDirection: largeScreen ? 'column' : 'row' }}>
      {data && sortedBySignificance.map((items) => {
        if (items?.childrens?.length === 0 || !items.childrens) {
          if (items.link) {
            return (
              <Button c={'#101828'} p={0} m={0} bg="none" fw={400} fz={18} component="a" href={`${items.link}`} key={items.id} target='_blank'>
                {i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn}
              </Button>
            );
          } else
            return (
              <RouteWithLang to={`pages/${items.id}`} key={items.id} >
                <Button c={'#101828'} p={0} m={0} bg="none" fw={400} fz={18} onClick={close}>
                  {i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn}
                </Button>
              </RouteWithLang>
            );
        }
        return (
          <React.Fragment key={items.id}>
            {largeScreen ? (
              <Accordion key={items.id} variant="filled" chevron={<IconChevronDown size={18} color="#101828" />} w={"100%"}>
                <Accordion.Item value={items.id.toString()} style={{ background: 'none' }}>
                  <Accordion.Control w={'100%'}>
                    <RouteWithLang to={`pages/${items.id}`}>
                      <Text c={'#101828'} p={0} m={0} bg="none" fw={400} fz={18} onClick={close}>
                        {i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn}
                      </Text>
                    </RouteWithLang>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Flex direction="column" align="start" ta="start" gap={18}>
                      {items.childrens.map((childrenItem) => {
                        if (childrenItem.link) {
                          return (
                            <a style={{ color: '#101828', display: "flex", gap: "0.5rem", alignItems: "center" }} key={childrenItem.id} href={childrenItem.link}>
                              <MenuArrowSvg />
                              {i18n.language === 'ru' ? childrenItem.titleRu : i18n.language === 'kg' ? childrenItem.titleKg : childrenItem.titleEn}
                            </a>
                          );
                        } else
                          return (
                            <RouteWithLang style={{ color: '#101828', display: "flex", gap: "0.5rem", alignItems: "center" }} key={childrenItem.id} to={`pages/${childrenItem?.id}`} onClick={close}>
                              <MenuArrowSvg />
                              {i18n.language === 'ru' ? childrenItem.titleRu : i18n.language === 'kg' ? childrenItem.titleKg : childrenItem.titleEn}
                            </RouteWithLang>
                          );
                      })}
                    </Flex>
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            ) : (
              <Menu key={items.id} trigger='hover'>
                <Menu.Target>
                  <Box>
                    <Button p={0} m={0} bg="none" fw={400} fz={{ base: 16, xl: 18 }} className={classes.parentButton}
                      component={RouteWithLang} to={`pages/${items.id}`}
                      rightSection={<IconChevronDown size={18} className={classes.parentButtonArrow} />}>
                      {i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn}
                    </Button>
                  </Box>
                </Menu.Target>
                <Menu.Dropdown style={{
                  blur: '4px', padding: '1.5rem 7%', border: 'none', borderRadius: '0',
                  background: '#F0F2F6', width: "100%", marginTop: "0.9rem"
                }}>
                  <Box style={{ display: "flex", flexDirection: 'column', gap: "1.5rem" }}>
                    {items.childrens.map((childrenItem) => {
                      if (childrenItem.link) {
                        return (
                          <Menu.Item component="a" target='_blank' href={childrenItem.link} key={childrenItem.id} fz={"md"} p={0} className={classes.item} leftSection={<MenuArrowSvg />}>
                            {i18n.language === 'ru' ? childrenItem.titleRu : i18n.language === 'kg' ? childrenItem.titleKg : childrenItem.titleEn}
                          </Menu.Item>
                        );
                      } else
                        return (
                          <RouteWithLang to={`pages/${childrenItem.id}`} key={childrenItem.id} className={classes.item}>
                            <Menu.Item leftSection={<MenuArrowSvg />}>
                              {i18n.language === 'ru' ? childrenItem.titleRu : i18n.language === 'kg' ? childrenItem.titleKg : childrenItem.titleEn}
                            </Menu.Item>
                          </RouteWithLang>
                        );
                    })}
                  </Box>
                </Menu.Dropdown>
              </Menu>
            )}
          </React.Fragment>
        );
      })}
    </Box >
  );
};

const useStyles = createStyles({
  item: {
    stroke: "#101828",
    display: "flex",
    gap: "0",
    alignItems: "center",
    color: "#101828",
    fontSize: "1.2vw",
    "&:hover": {
      background: "none",
      stroke: "#06297B",
      color: "#06297B"
    }
  },
  parentButton: {
    color: "#101828!important",
    ":hover": {
      color: "#06297B!important"
    }
  },
  parentButtonArrow: {
    ":hover": {
      color: "#06297B",
      transform: "rotate(180deg)",
      animation: "ease-in"
    }
  }
});
