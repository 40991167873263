import { Carousel } from '@mantine/carousel';
import { Box, Center, Divider, rem } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useGetAllLinksQuery } from 'src/store/reducers/all-reducers/socialLinkApiSlice';
import { LinksToCard } from './links-to-card';

export const LinksTo = () => {
  const { data } = useGetAllLinksQuery([], {
    refetchOnMountOrArgChange: true,
  });
  return (
    <Box style={{ width: '100%', boxShadow: '0px 4px 24px 0px rgba(106, 122, 152, 0.05)' }}>
      <Carousel
        h={{ base: "fit-content", sm: "6.75rem" }}
        slideSize={{ base: '100%', sm: '50%', md: '25%' }}
        loop
        align="start"
        bg="white"
        slideGap={{ base: 0, sm: "lg" }}
        styles={{
          controls: {
            padding: "0"
          }
        }}
        px={{ base: "1.2rem", sm: "2rem" }}
        style={{
          border: '1px solid #E8E8EA',
          borderRadius: '4px',
        }}
        nextControlIcon={<IconChevronRight style={{ width: rem(25), height: rem(25), color: '#626667' }} />}
        previousControlIcon={<IconChevronLeft style={{ width: rem(25), height: rem(25), color: '#626667' }} />}
      >
        {data?.map(
          (link) =>
            link.linkType.titleRu === 'Другие гос. органы' && (
              <Carousel.Slide key={link.id} style={{ position: "relative" }}>
                <Center w="100%" h={{ base: "100%", sm: "6.75rem" }}>
                  <LinksToCard link={link} />
                </Center>
                <Divider
                  display={{ base: "none", sm: "block" }}
                  orientation="vertical"
                  size={1}
                  style={{
                    position: 'absolute',
                    right: '0',
                    height: '80%',
                    top: '10%',
                    borderColor: '#D3D8E6',
                  }}
                />
              </Carousel.Slide>
            ),
        )}
      </Carousel>
    </Box>
  );
};
