interface IProps {
    size?: number
    color?: string
    onClick?: () => void
}

export const SearchSvg = ({ size = 16, color = "#101828", onClick }: IProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => onClick && onClick()} cursor="pointer">
            <path d="M11.0207 10.0778L13.8758 12.9329L12.933 13.8757L10.0779 11.0206C9.05131 11.8419 7.74937 12.3333 6.33337 12.3333C3.02137 12.3333 0.333374 9.64525 0.333374 6.33325C0.333374 3.02125 3.02137 0.333252 6.33337 0.333252C9.64537 0.333252 12.3334 3.02125 12.3334 6.33325C12.3334 7.74925 11.842 9.05118 11.0207 10.0778ZM9.68317 9.58312C10.4984 8.74298 11 7.59698 11 6.33325C11 3.75492 8.91171 1.66659 6.33337 1.66659C3.75504 1.66659 1.66671 3.75492 1.66671 6.33325C1.66671 8.91158 3.75504 10.9999 6.33337 10.9999C7.59711 10.9999 8.74311 10.4983 9.58324 9.68305L9.68317 9.58312Z" fill="#101828" stroke={color} />
        </svg>
    )
}
