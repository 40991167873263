// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_links__Q02m6 {
  display: flex;
  align-items: center;
}
.styles_links2__h7D-A {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(16, 24, 40, 0.1);
  border-radius: 0.5rem;
  z-index: 10;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/links-to-social-media/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,uCAAuC;EACvC,qBAAqB;EACrB,WAAW;AACb","sourcesContent":[".links {\n  display: flex;\n  align-items: center;\n}\n.links2 {\n  width: 2.5rem;\n  height: 2.5rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: rgba(16, 24, 40, 0.1);\n  border-radius: 0.5rem;\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"links": `styles_links__Q02m6`,
	"links2": `styles_links2__h7D-A`
};
export default ___CSS_LOADER_EXPORT___;
