import { Center, Container, Title } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { usePageTitle } from 'src/hooks';
import { AppShellCustom } from 'src/ui-kit';
import { SocialLinkDetails } from 'src/components';

type Params = {
  socialLinksId: string;
};

export const SocialLinkView = () => {
  const { t } = useTranslation();
  const { socialLinksId } = useParams<Params>();
  const title = t`pages.title.social-link-view`;
  usePageTitle(title);

  return (
    <AppShellCustom>
      <Container size="xl">
        <Center>
          <Title fw="normal" mb="1rem">
            {title}
          </Title>
        </Center>
        {socialLinksId && <SocialLinkDetails socialLinksId={socialLinksId} />}
      </Container>
    </AppShellCustom>
  );
};
