import { Flex } from "@mantine/core"
import { createStyles } from "@mantine/styles"
import { IconBrandWhatsapp } from "@tabler/icons-react"
import { Link } from "react-router-dom"
import { facebookB, telegramB } from "src/assets"
import { PrintPage } from "src/components"
import { News } from "src/types/news"

interface Props {
    data?: News
}

export const ShareLinks = ({ data }: Props) => {
    const location = window.location.href;
    const { classes } = useStyles()
    return (
        <Flex my={20} align="center" gap={10}>
            <PrintPage data={data} />
            <Link target="_blank" to={`https://wa.me/?text=${location}`} className={classes.iconsContainer}>
                <IconBrandWhatsapp size={25} color="#06297B" />
            </Link>
            <Link target="_blank" to={`https://www.messenger.com/t/?${location}`} className={classes.iconsContainer}>
                {/* <Image mb={4} w={16} src="/icons/facebook-dark.svg" /> */}
                <img src={facebookB} alt="facebok" style={{ height: "20px" }} />
            </Link>
            <Link target="_blank" to={`https://t.me/share/url?url=${location}`} className={classes.iconsContainer}>
                <img src={telegramB} alt="twit" style={{ height: "20px" }} />
                {/* <Image mb={3} w={23} src="/icons/telegram.svg" /> */}
            </Link>
        </Flex>
    )
}

const useStyles = createStyles({
    iconsContainer: {
        display: "flex", alignItems: "center"
    }
})