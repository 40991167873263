export const CalendarSvg = ({
  size,
  color = '#101828',
}: {
  size: number;
  color?: string;
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.625 4.36377V5.86377H12.75V4.36377H13.5C14.1213 4.36377 14.625 4.86745 14.625 5.48877V7.00048H3.375V5.48877C3.375 4.86745 3.87868 4.36377 4.5 4.36377H5.25V5.86377H6.375V4.36377H11.625ZM3.375 8.12548V14.4888C3.375 15.1101 3.87868 15.6138 4.5 15.6138H13.5C14.1213 15.6138 14.625 15.1101 14.625 14.4888V8.12548H3.375ZM4.5 3.23877H5.25V1.73877H6.375V3.23877H11.625V1.73877H12.75V3.23877H13.5C14.7427 3.23877 15.75 4.24613 15.75 5.48877V14.4888C15.75 15.7314 14.7427 16.7388 13.5 16.7388H4.5C3.25736 16.7388 2.25 15.7314 2.25 14.4888V5.48877C2.25 4.24613 3.25736 3.23877 4.5 3.23877Z"
        fill={color}
      />
    </svg>
  );
};
