interface IProps {
  pathname: string;
  exclude: string[];
}
export const useFilterPathname = ({ pathname, exclude }: IProps) => {
  const filteredPath = pathname
    .split('/')
    .filter((item) => !exclude.includes(item));

  const joinedPath = () => {
    return filteredPath.join('/');
  };

  return {
    filteredPath,
    joinedPath,
  };
};
