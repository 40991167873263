import {
  FetchArgs,
  fetchBaseQuery,
  createApi,
} from '@reduxjs/toolkit/query/react';
import { Path } from 'src/utils';
import { useCookie, useSessionStorage } from 'src/hooks';
import { logOut, setCredentials } from './reducers/auth-slice';

const baseQuery = fetchBaseQuery({
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const token = useSessionStorage('accessToken').getItem();
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: any,
  extraOptions: Record<string, any>,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const refreshToken = useCookie('refreshToken').getCookie();
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    const refreshResult = await baseQuery(
      {
        url: Path.Auth.refreshToken,
        method: 'POST',
        body: {
          refreshToken: refreshToken,
        },
      },
      api,
      extraOptions,
    );
    if (refreshResult?.data) {
      const profile = api.getState().auth.profile;
      api.dispatch(setCredentials({ ...refreshResult.data, profile }));
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: 'authApi',
  tagTypes: [
    'meeting',
    'socialLink',
    'news',
    'banner',
    'act',
    'profileData',
    'Phone',
    'Email',
    'Employee',
    'Position',
    'Banner',
    'Acts',
    'Gallery',
    'News',
    'PageStructure',
    'SocialLinks',
    'SotProfiles',
    'Map',
    'Cards',
    'Office',
    'FilePage',
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
