// /* eslint-disable react-hooks/rules-of-hooks */
// import { createSlice } from "@reduxjs/toolkit";
// import { useLocalStorage } from "src/hooks";
// import { RootState } from "src/store/store";

// export interface AuthState {
//   profile?: Profile;
//   menu?: Menu[];
//   loading?: boolean;
//   error?: string;
//   token?: string;
// }
// const storageProfile = localStorage.getItem("profile");

// const initialState: AuthState = {
//   profile: storageProfile ? (JSON.parse(storageProfile) as Profile) : undefined,
//   menu: storageProfile
//     ? (JSON.parse(storageProfile) as Profile).menu
//     : undefined,
//   loading: undefined,
//   error: undefined,
//   token: undefined,
// };

// export const authSlice = createSlice({
//   name: "authApi",
//   initialState,
//   reducers: {
//     setCredentials: (state, action) => {
//       const { menu, authenticationToken } = action.payload as Profile;
//       state.profile = action.payload;
//       state.menu = menu;
//       state.token = authenticationToken;
//       useLocalStorage("refreshToken").setStorage(action.payload.refreshToken);
//       useLocalStorage("i18nextLng").setStorage(action.payload.systemLanguage);
//     },
//     logOut: (state) => {
//       state.profile = undefined;
//       state.token = undefined;
//       state.menu = undefined;
//       // useLocalStorage("persist:root").removeStorage();
//     },
//   },
// });
// export const { setCredentials, logOut } = authSlice.actions;
// export const authReducer = authSlice.reducer;

// export const selectCurrentProfile = (state: RootState) => state.auth.profile;
// export const selectCurrentToken = (state: RootState) => state.auth.token;
import { useSessionStorage } from 'src/hooks';
import { createSlice } from '@reduxjs/toolkit';
import { useCookie } from 'src/hooks';
import { RootState } from 'src/store/store';

export interface AuthState {
  profile?: Profile;
  menu?: Menu[];
  loading?: boolean;
  error?: string;
  token?: string;
}

const initialState: AuthState = {
  profile: undefined,
  menu: undefined,
  loading: undefined,
  error: undefined,
  token: undefined,
};

export const authSlice = createSlice({
  name: 'authApi',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { menu, authenticationToken } = action.payload as Profile;
      state.profile = action.payload;
      state.menu = menu;
      state.token = authenticationToken;
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSessionStorage('profile').setItem(action.payload);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useSessionStorage('accessToken').setItem(authenticationToken);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useCookie('refreshToken').setCookie(action.payload.refreshToken);
      // eslint-disable-next-line react-hooks/rules-of-hooks
    },
    logOut: (state) => {
      state.profile = undefined;
      state.token = undefined;
      state.menu = undefined;
    },
  },
});
export const { setCredentials, logOut } = authSlice.actions;
export const authReducer = authSlice.reducer;

export const selectCurrentProfile = (state: RootState) => state.auth.profile;
export const selectCurrentToken = (state: RootState) => state.auth.token;
