import { Switch } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import { MantineColor, MantineSize } from '@mantine/styles';
import { ReactNode } from 'react';

type Props = {
  string?: boolean;
  label: ReactNode;
  color?: MantineColor;
  size?: MantineSize;
  onChange: (value: any) => void;
  formData?: boolean;
};

export const CustomSwitch = ({
  string,
  label,
  color = 'teal',
  size = 'md',
  formData,
  onChange,
}: Props) => {
  return (
    <Switch
      checked={formData}
      onChange={(event) => {
        string
          ? onChange(`${event.currentTarget.checked}`)
          : onChange(event.currentTarget.checked);
      }}
      color={color}
      size={size}
      label={label}
      thumbIcon={
        formData ? (
          <IconCheck size={12} color={'green'} stroke={3} />
        ) : (
          <IconX size={12} color={'red'} stroke={3} />
        )
      }
    />
  );
};
