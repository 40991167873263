import { Box, Center, Flex, Image, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { StatisticSvg } from 'src/assets';
import { useGetStatisticsQuery } from 'src/store/reducers/all-reducers/googleAnalyticsStatisticsApiSlice';
import { LinksToSocialMedia } from 'src/widgets';

export const Top = () => {
  const isTabletScreen = useMediaQuery('(max-width: 900px)');
  const isMobileScreen = useMediaQuery('(max-width: 600px)');
  const { t } = useTranslation()

  const { data } = useGetStatisticsQuery('')

  return (
    <Center
      display="flex"
      mb={{ base: "2rem", sm: "8rem" }}
      style={{
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '2rem',
        flexDirection: isMobileScreen ? 'column' : 'row',
        zIndex: 10
      }}
    >
      <Flex direction={isTabletScreen ? 'column' : 'row'} style={{ width: isMobileScreen ? '100%' : '33%', justifyContent: 'center', alignItems: 'flex-start', gap: '2rem' }}>
        <Box
          style={{
            display: 'inherit',
            gap: '32px',
            flexDirection: 'column',
            color: '#040A0B',
          }}
        >
          <Box style={{ display: 'inherit', flexDirection: 'column', gap: '16px' }}>
            <Text fw={500} mb={8}>
              {t("main_page.footer.office")}
            </Text>
            <Text>+996 (312) 62-62-10 (+195)</Text>
            <Text>+996 550 008 951 (+195)</Text>
            <Text>+996 701 941 515 (+195)</Text>
            <Text>+996 777 951 616 (+195)</Text>
            <Text>ep@minjust.gov.kg</Text>
          </Box>
          <Box style={{ display: 'inherit', flexDirection: 'column', gap: '16px' }}>
            <Text fw={500}>
              {t("main_page.footer.adress")}
            </Text>
            <Text>
              {t("main_page.footer.current_adress1")}
              <br />
              {t("main_page.footer.current_adress2")}
            </Text>
            <Text>
              {t("main_page.footer.current_days")}
            </Text>
          </Box>
        </Box>
      </Flex>
      <Flex direction={isTabletScreen ? 'column' : 'row'} style={{ width: isMobileScreen ? '100%' : '33%', justifyContent: 'center', alignItems: 'flex-start', gap: '2rem' }}>
        <Box
          style={{
            display: 'inherit',
            gap: '40px',
            flexDirection: 'column',
            color: '#040A0B'
          }}
        >
          <Box style={{ display: 'inherit', flexDirection: 'column', gap: '16px' }}>
            <Text fw={500} mb={8}>
              {t("main_page.footer.general_phone_numbers")}
            </Text>
            <Text>+996 312 626 210</Text>
            <Text>+996 550 008 951</Text>
            <Text>+996 701 941 515</Text>
            <Text>+996 777 951 616</Text>
          </Box>
          <Box style={{ display: 'inherit', flexDirection: 'column', gap: '16px' }}>
            <Text fw={500} mb={8}>
              {t("main_page.footer.anti_coruption")}
            </Text>
            <Text>+996 312 626 210 (107)</Text>
            <Text>+996 701 941 515 (107)</Text>
            <Text>+996 550 008 951 (107)</Text>
            <Text>+996 777 951 616 (107)</Text>
          </Box>
        </Box>
      </Flex>
      <Flex direction={isTabletScreen ? 'column' : 'row'} style={{ width: isMobileScreen ? '100%' : '33%', justifyContent: 'center', alignItems: 'flex-start', gap: '2rem' }}>
        <Box style={{ display: 'inherit', flexDirection: 'column', gap: '60px', maxWidth: '280px', color: '#040A0B' }}>
          <Box w="100%">
            <Text fw={500} c="#040A0B" style={{ marginBottom: '24px' }}>
              {t("main_page.footer.we_in_social_media")}
            </Text>
            <LinksToSocialMedia white={false} isBackground={true} />
          </Box>
          <Box style={{ display: 'inherit', flexDirection: 'column', gap: '16px' }}>
            <Text fw={500}>
              {t("main_page.footer.mail")}
            </Text>
            <Text>ep@minjust.gov.kg</Text>
            <Text fw={500}>
              {t("main_page.footer.helpline")}
            </Text>
            <Text>+996 (312) 65-10-10</Text>
          </Box>
          <Box style={{ display: 'flex', maxWidth: '290px', gap: "2rem", padding: "0.4rem 1rem", borderRadius: "4px", backgroundColor: 'rgba(156, 165, 198, 0.1)', border: "1px solid #D3D8E6", position: "relative" }}>
            <Box w={"60%"}>
              <Text mb={8} fw={400} c="#101828">
                {t("main_page.footer.visitors_today")}:
              </Text>
              <Text mb={8} fw={400} c="#101828">
                {t("main_page.footer.visitors_week")}:
              </Text>
              <Text fw={400} c="#101828">
                {t("main_page.footer.visitors_total")}:
              </Text>
            </Box>
            <Image src={StatisticSvg} style={{ position: 'absolute', width: "40%", zIndex: 0, right: '1rem', bottom: "0" }} />
            <Box w={"40%"} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", zIndex: 10 }}>
              <Text mb={8} fw={500} c="#101828">
                {data?.activeUsersToday ? data?.activeUsersToday : 0}
              </Text>
              <Text mb={8} fw={500} c="#101828">
                {data?.activeUsersWeek ? data?.activeUsersWeek : 0}
              </Text>
              <Text fw={500} c="#101828">
                {data?.newUsersAllTime ? data?.newUsersAllTime : 0}
              </Text>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Center>
  );
};
