import { Accordion, AccordionItem, Box, Card, Flex, Image, Text, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import logo from 'src/assets/logo-bird.svg';

interface PersonnelCardProps {
  person: CardType;
}

export const PersonnelCard = ({ person }: PersonnelCardProps) => {
  const smallScreen = useMediaQuery("(max-width: 400px )")
  const { i18n } = useTranslation()
  const { classes } = useStyles()

  return (
    <Card style={{ borderRadius: '4px', boxShadow: "none", border: "0.5px solid #E1E4EB" }} mt={{ base: 0, xs: 16 }} bg="rgba(255, 255, 255, 0.8)" p={{ base: 8, xs: 12, sm: 24 }} w={'100%'} h="auto">
      <Flex direction={{ base: 'column', sm: 'row' }} align="start" gap={30} w="100%">
        {person.photo ? (
          <Flex h={340} w={{ base: "100%", sm: 300 }} style={{ overflow: 'hidden' }}>
            <img
              height={340}
              width={smallScreen ? "100%" : 300}
              src={`data:image/webp;base64,${person.photo}`}
              style={{ objectFit: 'cover', objectPosition: '50% 50%', }}
              alt={person.fullName.titleRu}
            />
          </Flex>
        ) : (
          <Flex align="center" justify="center" bg="primaryDark" w={260} h={250}>
            <Image src={logo} />
          </Flex>
        )}
        <Flex w="100%" gap={24} direction="column">
          <Flex gap={{ base: 0, md: 16 }} direction="column">
            {person.cardType === "PRESENT" &&
              <Title fw={500} fz={{ base: 20, xs: 28 }} c="#101828">
                {i18n.language === 'ru' ? person.position?.titleRu : i18n.language === 'kg' ? person.position?.titleKg : person.position?.titleEn}
              </Title>
            }
            <Title fz={{ base: 20, xs: 25 }} c="#101828">
              {i18n.language === 'ru' ? person?.fullName?.titleRu : i18n.language === 'kg' ? person?.fullName?.titleKg : person?.fullName?.titleEn}
            </Title>
          </Flex>
          {person.cardType === "HISTORY" ?
            <Box>
              <Text fz={{ base: 14, md: 18 }}>
                {i18n.language === 'ru' ? person.position?.titleRu : i18n.language === 'kg' ? person.position?.titleKg : person.position?.titleEn}</Text>
            </Box> :
            <Accordion w="95%" classNames={{ control: classes.accrodionControlStyle }}>
              <AccordionItem style={{ borderBottom: '1px solid #E1E4EB' }} value="1">
                <Accordion.Control fz={{ base: 20, xs: 24 }} c="#101828">{t('pages.title.biography')}</Accordion.Control>
                <Accordion.Panel>
                  <Title c="#101828" fw={400} fz={{ base: 16, sm: 16, lg: 18 }} order={3}
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === 'ru' ? person.shortBiography?.titleRu : i18n.language === 'kg' ?
                        person.shortBiography?.titleKg : person.shortBiography?.titleEn,
                    }} />
                </Accordion.Panel>
              </AccordionItem>
              <AccordionItem style={{ borderBottom: '1px solid #E1E4EB' }} value="2">
                <Accordion.Control fz={{ base: 20, xs: 24 }} c="#101828">{t('pages.title.laborActivity')}</Accordion.Control>
                <Accordion.Panel>
                  <Title c="#101828" fw={400} fz={{ base: 16, sm: 16, lg: 18 }} order={3}
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === 'ru' ? person.laborActivity?.titleRu : i18n.language === 'kg' ?
                        person.laborActivity?.titleKg : person.laborActivity?.titleEn,
                    }} />
                </Accordion.Panel>
              </AccordionItem>
              <AccordionItem style={{ borderBottom: '1px solid #E1E4EB' }} value="3">
                <Accordion.Control fz={{ base: 20, xs: 24 }} c="#101828">{t('pages.title.education')}</Accordion.Control>
                <Accordion.Panel>
                  <Title c="#101828" fw={400} fz={{ base: 16, sm: 16, lg: 18 }} order={3}
                    dangerouslySetInnerHTML={{
                      __html: i18n.language === 'ru' ? person.education?.titleRu : i18n.language === 'kg' ?
                        person.education?.titleKg : person.education?.titleEn,
                    }} />
                </Accordion.Panel>
              </AccordionItem>
            </Accordion>
          }
        </Flex>
      </Flex>
    </Card>
  );
};

const useStyles = createStyles(() => ({
  accrodionControlStyle: {
    ":hover": {
      background: "#f2f2f2!important"
    },
  }
}))
