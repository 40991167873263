import { Center, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { NewsForm } from 'src/components';
import { usePageTitle } from 'src/hooks';
import { AppShellCustom } from 'src/ui-kit';

export const AddNews = () => {
  const { t } = useTranslation();
  const title = t`pages.title.add-news`;
  usePageTitle(title);

  return (
    <AppShellCustom>
      <Center>
        <Title fw="normal" mb="1rem">
          {title}
        </Title>
      </Center>
      <NewsForm />
    </AppShellCustom>
  );
};
