import { createSlice } from '@reduxjs/toolkit';

export interface CalendarState {
  date: Date | null;
}

const initialState: CalendarState = {
  date: null,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setDateSlice: (state, action) => {
      state.date = action.payload;
    },
    resetDateSlice: (state) => {
      state.date = null;
    },
  },
});

export const { setDateSlice, resetDateSlice } = calendarSlice.actions;

export const calendarReducer = calendarSlice.reducer;
