import { Flex, Button, Paper } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode } from 'react';
import { RouteTitles } from 'src/ui-kit';

interface LayoutWithSubHeaderProps {
  children: ReactNode;
  setActive: (a: number) => void;
  active: number;
  header: {
    id: number;
    title: string;
  }[];
}

export const LayoutWithSubHeader = ({
  children,
  header,
  setActive,
  active,
}: LayoutWithSubHeaderProps) => {
  const smallScreen = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <Paper
        className="box-shadow"
        w="100vw"
        px={smallScreen ? '3vw' : '5vw'}
        mt={80}
        mb={20}
        py={10}
      >
        <Flex ml={-5} gap={30} wrap="wrap">
          {header?.map((el, idx) => (
            <Button
              key={idx}
              onClick={() => setActive(el.id)}
              fz="md"
              fw={active === el.id ? 500 : 400}
              c="primaryDark"
              variant="simple"
            >
              {el.title}
            </Button>
          ))}
        </Flex>
      </Paper>
      <Flex className="container" mx="auto" direction="column">
        <RouteTitles styles={{ mb: '20px' }} />
        {children}
      </Flex>
    </>
  );
};
