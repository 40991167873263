import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@mantine/core';
import { CustomLoader, CustomText, DateFormatTo, TextInfo } from 'src/ui-kit';
import { getTitleByLanguage } from 'src/locales';
import { useGetNewsQuery } from 'src/store/reducers/all-reducers/newsApiSlice';
import { createStyles } from '@mantine/styles';

type Props = {
  newsId: string;
};

export const NewsDetails = ({ newsId }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data: newsData, isLoading } = useGetNewsQuery(newsId, {
    refetchOnMountOrArgChange: true,
  });

  const organCard = [
    {
      label: t`data.news.dateFrom`,
      value: DateFormatTo(newsData?.dateFrom, 'DD-MM-YYYY HH:mm'),
    },
    {
      label: t`data.news.title`,
      value: getTitleByLanguage(newsData),
    },
  ];

  return isLoading ? (
    <CustomLoader />
  ) : (
    <Paper shadow="sm" p="xl">
      <Paper shadow="sm" p="xl">
        <TextInfo data={organCard} display={true} spanFirst={4} spanTwo={8} />
        {newsData?.content && (
          <Box className={classes.content}>
            <CustomText
              label={t`data.news.content`}
              value={
                <div
                  dangerouslySetInnerHTML={{
                    __html: getTitleByLanguage(newsData.content),
                  }}
                />
              }
            />
          </Box>
        )}
      </Paper>
    </Paper>
  );
};

const useStyles = createStyles({
  content: {
    '& img': {
      maxWidth: '70rem',
    },
  },
});
