import { apiSlice } from 'src/store/apiSlice';
import {
  AllPublishedNewsTypes,
  News,
  NewsFormCreateType,
  NewsFormResponseType,
  NewsSearchRequest,
  NewsSearchResponse,
} from 'src/types/news';
import { Path } from 'src/utils';

export const newsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNews: builder.mutation<NewsFormResponseType, NewsFormCreateType>({
      query: ({ attachments, ...body }) => {
        const formData = new FormData();
        const data = new Blob([JSON.stringify(body)], {
          type: 'application/json',
        });
        formData.append('requestDto', data);

        attachments &&
          attachments?.map((file: string | Blob) =>
            formData.append('attachments', file),
          );
        return {
          url: Path.News.create,
          method: 'POST',
          body: formData,
          cache: 'no-cache',
        };
      },
    }),
    updateNews: builder.mutation<
      News,
      { id: number; body: Partial<NewsFormCreateType> }
    >({
      query: ({ id, body }) => {
        const formData = new FormData();
        const data = new Blob([JSON.stringify(body)], {
          type: 'application/json',
        });
        formData.append('requestDto', data);

        body.attachments &&
          body.attachments?.map((file: string | Blob) =>
            formData.append('attachments', file),
          );
        return {
          url: Path.News.update(id),
          method: 'PUT',
          body: formData,
          cache: 'no-cache',
        };
      },
      invalidatesTags: ['News'],
    }),
    getPublishNews: builder.query<AllPublishedNewsTypes[], unknown>({
      query: () => Path.News.getPublish,
      providesTags: ['News'],
    }),
    getNews: builder.query<News, string>({
      query: (id) => ({
        url: Path.News.get(id),
      }),
      providesTags: ['News'],
    }),
    newsDelete: builder.mutation({
      query: (id) => ({
        url: Path.News.delete(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['News'],
    }),
    newsSearch: builder.mutation<NewsSearchResponse, NewsSearchRequest>({
      query: (postData) => ({
        url: Path.News.search,
        method: 'POST',
        body: postData,
        cache: 'no-store',
      }),
      invalidatesTags: ['News'],
    }),
  }),
});

export const {
  useGetPublishNewsQuery,
  useGetNewsQuery,
  useCreateNewsMutation,
  useUpdateNewsMutation,
  useNewsDeleteMutation,
  useNewsSearchMutation,
} = newsApiSlice;
