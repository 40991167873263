import { Box, Button, Center, Flex, Grid, Loader, Popover, Text, UnstyledButton } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconChevronRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarSvg } from 'src/assets/calendar-svg';
import { NewsEventsCard, NoDataUi, RouteWithLang } from 'src/components';
import { useAppDispatch } from 'src/hooks';
import { setDateSlice } from 'src/store/reducers';
import { useNewsSearchMutation } from 'src/store/reducers/all-reducers/newsApiSlice';
import { CalendarWithPaper } from 'src/widgets';

export const NewsEvents = () => {
  const largeScreen = useMediaQuery('(max-width: 1200px)');
  const { t } = useTranslation()
  const [date, setDate] = useState<Date | null>(null);
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useAppDispatch()

  const [searchNews, { data: newsData, isLoading }] = useNewsSearchMutation();

  const handleFilterNews = () => {
    try {
      searchNews({
        filter: {},
        pageRequest: { limit: 4, page: 0 },
        sorting: {
          sortBy: "PUBLISHED_AT",
          sortDirection: "DESC"
        }
      });
    } catch (error) {
      console.error('Error in fetching newsData');
    }
  }

  useEffect(() => {
    handleFilterNews()
  }, []);

  useEffect(() => {
    if (date) {
      dispatch(setDateSlice(date.toISOString()));
    }
  }, [date, dispatch]);

  return (
    <Box style={{ marginBottom: '80px', color: '#040A0B' }} p={{ base: '20px 5%', xs: '20px 7%' }}>
      <Flex justify="space-between" w="100%" mb="3.5rem">
        <RouteWithLang to={`/public-news`}>
          <Text fw={500} fz={{ base: 22, md: 32 }} c="#040A0B">
            {t("main_page.title.news_events")}
          </Text>
        </RouteWithLang>

        <Flex align="center">
          <Popover position="bottom-end" opened={opened} onClose={close}
            styles={{ dropdown: { border: "none" } }}>
            <Popover.Target>
              <Button onClick={opened ? close : open} display={{ base: 'block', lg: 'none' }} bg="none" variant="simple">
                <CalendarSvg size={25} />
              </Button>
            </Popover.Target>
            <Popover.Dropdown w={"fit-content"}>
              <CalendarWithPaper
                screenSize={largeScreen}
                onClose={close}
                date={date}
                setDate={setDate}
                variant="bg-opacity2"
              />
            </Popover.Dropdown>
          </Popover>

          <Flex display={{ base: 'none', md: 'flex' }}>
            <RouteWithLang to={'/public-news'}>
              <UnstyledButton style={{ display: 'flex', alignItems: 'center', gap: '14px', fontSize: '18px', fontWeight: '500', color: '#040A0B', }}>
                {t("main_page.button.all_events")}
                <IconChevronRight color="#040A0B" />
              </UnstyledButton>
            </RouteWithLang>
          </Flex>
        </Flex>
      </Flex>
      <Flex pos="relative" mb={30} gap={32} direction={{ base: 'column', lg: 'row' }} justify="space-between">
        <Grid gutter={32} w="100%" style={{ display: "flex", alignItems: "center" }}>
          {isLoading ?
            <Grid.Col span={12} >
              <Center>
                <Loader color="blue" />
              </Center>
            </Grid.Col>
            :
            <>
              {!newsData?.content || newsData?.content.length === 0
                ?
                <Grid.Col span={12}>
                  <NoDataUi />
                </Grid.Col>
                :
                newsData?.content.map((el) => (
                  <Grid.Col key={el.id} span={{ base: 12, sm: 6 }}>
                    <NewsEventsCard data={el} />
                  </Grid.Col>
                ))
              }
            </>
          }
        </Grid>
        <Box display={{ base: "none", lg: "block" }}>
          <CalendarWithPaper
            screenSize={largeScreen}
            date={date}
            setDate={setDate}
            variant="bg-opacity2"
            isMainPage={true}
          />
        </Box>
      </Flex>
      <Flex display={{ base: 'flex', md: 'none' }} justify="end">
        <RouteWithLang to={'/public-news'}>
          <UnstyledButton style={{ display: 'flex', alignItems: 'center', gap: '14px', fontSize: '18px', fontWeight: '500', color: '#040A0B' }}>
            {t("main_page.button.all_events")}
            <IconChevronRight color="#040A0B" />
          </UnstyledButton>
        </RouteWithLang>
      </Flex>
    </Box>
  );
};
