import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

export const pageContentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    pageContent: builder.query<PageType, string>({
      query: (id) => ({
        url: Path.PageList.getContent(id),
        method: 'GET',
      }),
    }),
  }),
});

export const { usePageContentQuery } = pageContentApiSlice;
