import { AppShell, Flex, ScrollArea } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { ReactNode, useState } from 'react';
import { Navbar, Sidebar } from '..';

type Props = {
  children: ReactNode;
};

const maxWidth = 280
const minWidth = 90

export const AppShellCustom = ({ children }: Props) => {
  const [open, setOpen] = useState<boolean>(true);
  const { classes } = useStyles();

  return (
    <ScrollArea.Autosize mah={'100dvh'} mx="auto" scrollbarSize={6}>
      <AppShell
        padding={20}
        className={classes.appShell}
        header={{ height: 60 }}
        navbar={{ width: maxWidth + 20, breakpoint: "sm" }}
      >
        <AppShell.Header>
          <Navbar />
        </AppShell.Header>

        <AppShell.Navbar w={open ? maxWidth : minWidth} style={{ transition: "300ms", background: "rgb(13 24 42)" }}>
          <Sidebar open={open} setOpen={setOpen} />
        </AppShell.Navbar>
        <AppShell.Main pl={open ? maxWidth + 20 : minWidth + 20}>
          <Flex direction="column" align="start" p="2rem">
            {children}
          </Flex>
        </AppShell.Main>
      </AppShell>
    </ScrollArea.Autosize>
  );
};

const useStyles = createStyles({
  appShell: {
    '& main': {
      transition: 'all 0.3s ease',
    },
  },
});
