import { useTranslation } from 'react-i18next';
import { Box, Paper } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { CustomLoader, CustomText, TextInfo } from 'src/ui-kit';
import { useGetOfficeQuery } from 'src/store/reducers/all-reducers/officeApiSlice';

type Props = {
  officeId: string;
};

export const OfficeDetails = ({ officeId }: Props) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const { data: officeData, isLoading } = useGetOfficeQuery(officeId, {
    refetchOnMountOrArgChange: true,
  });

  const officeCard = [
    {
      label: t`data.news.title`,
      value: officeData?.titleRu,
    },
  ];

  return isLoading ? (
    <CustomLoader />
  ) : (
    <Paper shadow="sm" p="xl">
      <Paper shadow="sm" p="xl">
        <TextInfo data={officeCard} display={true} spanFirst={4} spanTwo={8} />
        {officeData && (
          <Box className={classes.content}>
            <CustomText
              label={t`data.news.content`}
              value={
                <div dangerouslySetInnerHTML={{ __html: officeData.titleRu }} />
              }
            />
          </Box>
        )}
      </Paper>
    </Paper>
  );
};

const useStyles = createStyles({
  content: {
    '& img': {
      maxWidth: '70rem',
    },
  },
});
