import { Container, Center, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { NewsDetails } from 'src/components';
import { usePageTitle } from 'src/hooks';
import { AppShellCustom } from 'src/ui-kit';

type Params = {
  newsId: string;
};

export const NewsView = () => {
  const { t } = useTranslation();
  const { newsId } = useParams<Params>();
  const title = t`pages.title.news-view`;
  usePageTitle(title);

  return (
    <AppShellCustom>
      <Container size="xl">
        <Center>
          <Title fw="normal" mb="1rem">
            {title}
          </Title>
        </Center>
        {newsId && <NewsDetails newsId={newsId} />}
      </Container>
    </AppShellCustom>
  );
};
