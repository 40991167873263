import { Dispatch, SetStateAction } from 'react';
import { Grid } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useForm } from '@mantine/form';
import { ButtonGroup, CustomInputPassword, CustomModal } from 'src/ui-kit';
import { useUpdatePasswordMutation } from 'src/store/reducers/profile-api-slice/profileApiSlice';
import { showNotification } from '@mantine/notifications';

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

export const ChangePassword = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const [updatePassword] = useUpdatePasswordMutation();

  const handleClose = () => {
    setOpen(false);
  };

  const form = useForm<PasswordForm>({
    initialValues: {
      newPassword: '',
      oldPassword: '',
      confirmPassword: '',
    },
    validate: {
      oldPassword: (value) => {
        if (!value) return t`form.validation.required`;
        if (value.length < 6) return t`form.validation.hasSixCharacters`;
      },
      newPassword: (value) => {
        if (!value) return t`form.validation.required`;
        if (value.length < 6) return t`form.validation.hasSixCharacters`;
        if (!/[0-9]/.test(value)) return t`form.validation.includesNumber`;
        if (!/[a-z]/.test(value))
          return t`form.validation.includesLowercaseLetter`;
        if (!/[A-Z]/.test(value))
          return t`form.validation.includesUppercaseLetter`;
      },
      confirmPassword: (value) => {
        if (!value) return t`form.validation.required`;
        if (value !== form.values.newPassword)
          return t`form.validation.passwordMismatch`;
      },
    },
  });

  const handleSubmit = async (values: PasswordForm) => {
    await updatePassword(values)
      .unwrap()
      .then(() => {
        showNotification({
          title: t`notification.successMsg`,
          message: '',
          color: 'green',
        });
        handleClose();
        form.reset();
      })
      .catch((e: ErrorType) => {
        showNotification({
          title: t`notification.error`,
          message: e?.data.message,
          color: 'red',
        });
      });
  };

  return (
    <>
      <CustomModal
        title={t`modal.changePassword`}
        open={open}
        handleClose={handleClose}
        button={false}
      >
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Grid>
            <Grid.Col span={12}>
              <CustomInputPassword
                {...form.getInputProps('oldPassword')}
                label={t`form.label.oldPassword`}
                required
                error={form.errors.oldPassword}
                formData={form.values.oldPassword}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <CustomInputPassword
                {...form.getInputProps('newPassword')}
                label={t`form.label.newPassword`}
                required
                error={form.errors.newPassword}
                formData={form.values.newPassword}
                bar={true}
              />
            </Grid.Col>
            <Grid.Col span={12} mb={7}>
              <CustomInputPassword
                {...form.getInputProps('confirmPassword')}
                label={t`form.label.confirmPassword`}
                required
                error={form.errors.confirmPassword}
                formData={form.values.confirmPassword}
              />
            </Grid.Col>
            <Grid.Col span={12}>
              <ButtonGroup onClickCancel={handleClose} type="submit" />
            </Grid.Col>
          </Grid>
        </form>
      </CustomModal>
    </>
  );
};
