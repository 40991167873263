import { Box } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { useEffect } from 'react';
import { PersonnelCard } from 'src/components';
import { useSearchCardMutation } from 'src/store/reducers/all-reducers/cardApiSlice';

interface IProps {
  employeeType: string
}

export const EmployeesPage = ({ employeeType = "PRESENT" }: IProps) => {
  const { classes } = useStyles();
  const [searchCardsInfo, { data: personnelCards }] = useSearchCardMutation();

  useEffect(() => {
    try {
      searchCardsInfo({
        filter: { cardType: employeeType, active: true },
        pageRequest: { limit: 99 }
      });
    } catch (error) {
      console.log(error);
    }
  }, [employeeType, searchCardsInfo]);

  return (
    <Box className={classes.contentContainer}>
      {personnelCards?.content.map((item) => (
        <PersonnelCard key={item.id} person={item} />
      ))}
    </Box>
  );
};

const useStyles = createStyles(() => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '70dvh',
  },
}));
