export {
  BlockWithPhoto,
  ExtraInfo,
  Footer,
  Header,
  InfoSystems,
  LinksTo,
  NewsCard,
  NewsEvents,
  NewsEventsCard,
  NewsPageResult,
  NoDataUi,
  OpenLangProvider,
  PersonnelCard,
  PrintPage,
  RouteCards,
  RouteWithLang,
  ShareLinks,
  SubOrganizations,
  TerritorialDepartments,
} from './open';

export {
  CardInfoDetails,
  CardInfoFilter,
  CardInfoForm,
  CardInfoTable,
  ChangePassword,
  LinksForm,
  NewsDetails,
  NewsFilter,
  NewsForm,
  NewsTable,
  OfficeDetails,
  OfficeFilter,
  OfficeForm,
  OfficeTable,
  PageFilter,
  PageForm,
  PageStructure,
  PageTable,
  ProfileView,
  SignInForm,
  SocialLinkDetails,
  SocialLinksFilter,
  SocialLinksTable,
} from './close';
