import { createSlice } from '@reduxjs/toolkit';

export interface BreadCrumbsState {
  select?: PageType;
}

const initialState: BreadCrumbsState = {
  select: undefined,
};

export const openBreadcrumbsSlice = createSlice({
  name: 'openBreadcrumbs',
  initialState,
  reducers: {
    selectTitleForBreadCrumbs: (state, action) => {
      state.select = action.payload;
    },
    closePage: (state) => {
      state.select = undefined;
    },
  },
});

export const { selectTitleForBreadCrumbs } = openBreadcrumbsSlice.actions;

export const openBreadcrumbsReducer = openBreadcrumbsSlice.reducer;
