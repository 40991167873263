import { Box, Button, Drawer } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { IconArrowUpRight, IconChevronDown } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { RouteWithLang } from 'src/components';
import { excludedItemsForPages } from 'src/constants';
import { useFilterPathname } from 'src/hooks';

interface IHTMLRenderProps {
  htmlContent: string | undefined;
}

const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
  if (!htmlContent) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

interface IProps {
  items: PageType;
}

export const PagesPage = ({ items }: IProps) => {
  const screenSizeMd = useMediaQuery('(max-width: 800px)');
  const { classes } = useStyles();
  const { pathname } = useLocation()
  const [opened, { open, close }] = useDisclosure(false);
  const { i18n } = useTranslation();
  const { joinedPath } = useFilterPathname({ pathname, exclude: excludedItemsForPages })
  const newPathname = joinedPath()

  const getTitle = (titles: PageType | undefined): string => {
    const title = i18n.language === 'ru' ? titles?.titleRu : i18n.language === 'kg' ? titles?.titleKg : titles?.titleEn;
    return title || '';
  };

  return (
    <>
      {items.childrens || items.pageListType !== "DEFAULT_PAGE" ?
        <>
          {items.link ?
            <Button
              fullWidth
              target='_blank'
              variant="white-for-file"
              justify="space-between"
              fz={{ base: 16, lg: 18 }}
              p={{ base: "1rem", sm: "1.5rem", md: "2rem 1.5rem" }}
              component="a" href={`${items.link}`}
              w={{ base: "100%", sm: "80%", lg: "50rem" }}
              style={{ minHeight: "2.25rem", height: "auto" }}
              rightSection={<IconArrowUpRight color="#101828" />}
            >
              <span style={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left', lineHeight: "1.4rem" }}>
                {getTitle(items)}
              </span>
            </Button>
            :
            <RouteWithLang to={`${newPathname}/${items.id}`}>
              <Button
                fullWidth
                variant="white-for-file"
                justify="space-between"
                fz={{ base: 16, lg: 18 }}
                p={{ base: "1rem", sm: "1.5rem", md: "2rem 1.5rem" }}
                w={{ base: "100%", sm: "80%", lg: "50rem" }}
                style={{ minHeight: "2.25rem", height: "auto" }}
              >
                <span style={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left', lineHeight: "1.4rem" }}>
                  {getTitle(items)}
                </span>
              </Button>
            </RouteWithLang>
          }
        </>
        :
        <Button
          fullWidth
          onClick={open}
          variant="white-for-file"
          justify="space-between"
          fz={{ base: 16, lg: 18 }}
          p={{ base: "1rem", sm: "1.5rem", md: "2rem 1.5rem" }}
          w={{ base: "100%", sm: "80%", lg: "50rem" }}
          style={{ minHeight: "2.25rem", height: "auto" }}
          rightSection={<IconChevronDown color="#101828" />}
        >
          <span style={{ flex: 1, whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'left', lineHeight: "1.4rem" }}>
            {getTitle(items)}
          </span>
        </Button>
      }
      <Drawer.Root opened={opened} onClose={close} position="right" style={{ overflow: 'scroll' }} size={screenSizeMd ? '100%' : '60dvw'}>
        <Drawer.Overlay opacity={0.01} />
        <Drawer.Content bg={"rgba(255, 255, 255, 1)"} style={{
          boxShadow: 'none', marginTop: '2.63rem', maxHeight: '100dvh', borderRadius: "4px",
          marginRight: screenSizeMd ? "0" : "7%"
        }}>
          <Box style={{
            minHeight: "100%",
            borderRight: "1px solid rgba(211, 216, 230, 1)",
            borderLeft: "1px solid rgba(211, 216, 230, 1)"
          }}>
            <Drawer.Header style={{
              background: 'rgba(255, 255, 255, 0.3)',
              paddingTop: '40px',
              paddingBottom: '40px',
              WebkitBackdropFilter: 'blur(10px)',
              backdropFilter: 'blur(10px)',
            }}>
              <Drawer.Title fw={500} fz="lg">{getTitle(items)}</Drawer.Title>
              <Drawer.CloseButton size="xl" c="#555F71" className={classes.closeButton} />
            </Drawer.Header>
            <Drawer.Body pb={100} style={{ minHeight: "100%" }}>
              {items?.content && <HTMLRender htmlContent={getTitle(items.content)} />}
            </Drawer.Body>
          </Box>
        </Drawer.Content>
      </Drawer.Root >
    </>
  );
};


const useStyles = createStyles(() => ({
  closeButton: {
    ":hover": {
      background: "none"
    }
  },
  bodyContainer: {
    minHeight: "100%",
    overflow: "hidden",
  },
  boxButtonStyle: {
    backgroundColor: 'white',
    borderRadius: '0.5rem',
    fontWeight: 500,
    color: '#101828',
    padding: '1.5rem',
    boxSizing: 'content-box',
    cursor: 'pointer'
  }
}));