import { BackgroundImage, Box, Paper, Text } from '@mantine/core';
import { useHover, useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { useEffect, useState } from 'react';
import { backgoundPhoto } from 'src/assets';
import { base64ImgPng } from 'src/constants';
import { DateWithOptions } from 'src/ui-kit';

interface IHTMLRenderProps {
  htmlContent: string | undefined;
}

const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
  if (!htmlContent) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

interface IProps {
  date?: string;
  title?: string;
  photo?: string;
  isPhoto?: boolean;
  content?: string;
}

export const NewsCard = ({
  date = new Date().toISOString(),
  title,
  photo,
  content,
  isPhoto = true,
}: IProps) => {
  const screenSize = useMediaQuery('(max-width: 1100px)');
  const smallScreen = useMediaQuery('(max-width: 768px)');
  const [printDate, setPrintDate] = useState('');
  const { hovered, ref } = useHover()
  const { classes } = useStyles(screenSize);

  useEffect(() => {
    const datetext = new Date(date);
    setPrintDate(`${datetext}`);
  }, [date]);

  return (
    <Paper variant="opacity3" className={isPhoto ? classes.paperWithPhoto : classes.paperWithoutPhoto} style={{ background: hovered ? '#f5f5f5' : `` }} ref={ref}>
      {isPhoto ? (
        <Box display={{ base: 'none', sm: 'block' }} w={{ base: '100%', lg: '50%' }}>
          {photo ? (
            <BackgroundImage src={`${base64ImgPng}${photo}`} w="100%" h={screenSize ? '173px' : '100%'} style={{ backgroundSize: 'cover' }} />
          ) : (
            <BackgroundImage src={backgoundPhoto} w="100%" h={screenSize ? '173px' : '100%'} style={{ backgroundSize: 'cover' }} />
          )}
        </Box>
      ) : null}
      <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%', gap: '16px', }}>
        <DateWithOptions printDate={printDate} style={{ color: '#06297B', fontSize: '16px', fontWeight: 500, }} />
        <Box style={{ height: '90%' }}>
          <Text mb={{ base: '0.1rem', sm: '0.5rem' }} style={{ color: '#101828', fontSize: '18px', fontWeight: 500, lineHeight: '2rem', }}>
            {title}
          </Text>
          <Box className={classes.content}>
            {content && (<HTMLRender htmlContent={content.slice(0, smallScreen ? 120 : 150)} />)}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = createStyles((_theme, screenSize: boolean | undefined) => ({
  paperWithPhoto: {
    flexDirection: 'row',
    padding: screenSize ? '1rem' : '1.25rem 10% 1.25rem 0',
    overflow: 'hidden',
    height: '15rem',
  },
  paperWithoutPhoto: {
    flexDirection: 'row',
    padding: screenSize ? '0' : '1rem 1.2rem',
    overflow: 'hidden',
  },
  content: {
    color: '#555F71',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '1.5rem',
    marginBottom: '0.5rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));
