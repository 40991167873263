import { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "src/hooks";

interface IProps {
    children: ReactNode;
}

export const OpenLangProvider = ({ children }: IProps) => {
    const { lang } = useParams<{ lang: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const [language, setLanguage] = useState(lang || 'kg')

    useEffect(() => {
        if (lang && lang !== language) {
            setLanguage(lang)
        }
    }, [lang, language])

    const changeRouteLanguage = (newLang: string) => {
        const currentPath = location.pathname;
        const newPath = currentPath.replace(`/${language}`, `${newLang}`)
        setLanguage(newPath);
        navigate(newPath);
    };

    return (
        <LanguageContext.Provider value={{ changeRouteLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}
