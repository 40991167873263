import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useAppSelector } from '../use-app-selector';

export const useFilePostDownload = (
  _open: boolean,
  url: string | null,
  body: RegistrationFormType,
) => {
  const token = useAppSelector((state) => state.auth.token);

  const fetchData = async (url: string, body: RegistrationFormType) => {
    const response = await fetch(`${process.env.REACT_APP_API_TEST}${url}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return await response.blob();
  };

  const $file = useQuery(['useFileDownload', url], async () => {
    if (!url) return;
    const data = await fetchData(url, body);
    return URL.createObjectURL(data);
  });

  useEffect(() => {
    if (!$file.data) return;
    return () => URL.revokeObjectURL($file.data as string);
  }, [$file.data]);

  return {
    loading: $file.isLoading,
    fileUrl: $file.data,
    fetchData,
  };
};
