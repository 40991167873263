import { useState } from 'react';
import { Title } from '@mantine/core';
import { IconEye, IconPencil, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomModal, MenuDropDown } from 'src/ui-kit';
import { AppTable, HeadCell } from 'src/ui-kit/app-table';
import { Path } from 'src/utils';
import { showNotification } from '@mantine/notifications';
import { useDeleteLinkMutation } from 'src/store/reducers/all-reducers/socialLinkApiSlice';

export const SocialLinksTable = ({
  filter,
}: {
  filter?: SocialLinksFilterType;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [linkDelete] = useDeleteLinkMutation();

  const [id, setId] = useState<number | null>();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (id: number) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await linkDelete(id)
      .unwrap()
      .then(() => {
        showNotification({
          title: t`notification.success`,
          message: t`notification.updateMsg`,
          color: 'green',
        });
      })
      .catch((e: ErrorType) => {
        showNotification({
          title: t`notification.error`,
          message: e?.data.message,
          color: 'red',
        });
      });
    setOpen(false);
  };

  const actions = (id: number) => [
    {
      icon: IconPencil,
      title: t`actions.news.edit`,
      to: `/social_link/${id}/edit-social-link`,
    },
    {
      icon: IconEye,
      title: t`actions.news.view`,
      to: `/social_link/${id}`,
    },
    {
      icon: IconTrash,
      title: t`actions.news.delete`,
      onClick: () => handleOpen(id),
    },
  ];

  const headCells: HeadCell<LinkResponseType>[] = [
    {
      label: t`table.title`,
      render: (n) => n.titleRu,
    },
    {
      label: t`actions.title`,
      render: (n) => (
        <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
      ),
    },
  ];

  return (
    <>
      <AppTable<LinkResponseType>
        headCells={headCells}
        doubleClickPath={(n) => navigate(`${n.id}`)}
        searchApiUrl={Path.SocialLinks.linkSearch}
        extraFilter={filter}
      />
      <CustomModal
        labelSubmit={'delete'}
        open={open}
        handleClose={handleClose}
        onClick={handleDelete}
      >
        <Title
          order={3}
          fw={400}
          mt="5px"
          mb="2rem"
          style={{ textAlign: 'center' }}
        >
          {t`modal.deleted.socialLinks`}
        </Title>
      </CustomModal>
    </>
  );
};
