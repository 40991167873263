import { Button, Indicator, Paper, Text } from '@mantine/core';
import { DatePicker, DateValue } from '@mantine/dates';
import { IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { RouteWithLang } from 'src/components';

interface IProps {
  variant?: string;
  date: Date | null;
  setDate: (date: DateValue) => void;
  onClose?: (a: boolean) => void;
  screenSize?: boolean | undefined;
  isMainPage?: boolean;
  searchNews?: () => void
}

export const CalendarWithPaper = ({
  date,
  setDate,
  onClose,
  variant = 'bg-white',
  screenSize,
  isMainPage = false,
  searchNews
}: IProps) => {
  const { t } = useTranslation()
  return (
    <Paper
      pos="relative"
      variant={variant}
      p={{ base: '2.5rem 1.5rem', md: '1.5rem 0.5rem', lg: '1.5rem 1.8rem', xl: '1.5rem 3.5rem' }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        maxHeight: '100%',
        maxWidth: '24rem',
        height: "100%",
      }}
    >
      {screenSize &&
        <Button
          pos="absolute"
          top={5}
          right={10}
          bg="none"
          onClick={() => onClose && onClose(false)}
        >
          <IconX color="#101828" size={25} />
        </Button>}
      <Text
        fw={500}
        fz={24}
        style={{
          textAlign: 'center',
          color: '#101828',
          marginBottom: '1.5rem',
        }}
      >
        {t("main_page.title.events_calendar")}
      </Text>
      <DatePicker
        allowDeselect
        locale="ru"
        mb="1.5rem"
        onChange={(e) => {
          setDate(e)
        }}
        value={date}
        maxDate={new Date()}
        renderDay={(date) => {
          const day = date.getDate();
          const today =
            day !== new Date().getDate() ||
            date.getMonth() !== new Date().getMonth();
          return (
            <Indicator size={6} color="green" offset={-2} disabled={today}>
              <div>{day}</div>
            </Indicator>
          );
        }}
      />
      {isMainPage
        ?
        <RouteWithLang to={"/public-news"}>
          <Button fullWidth h={50}>
            {t("main_page.button.news_archive")}
          </Button>
        </RouteWithLang>
        :
        <Button fullWidth h={50} onClick={() => {
          searchNews && searchNews()
          onClose && onClose(true)
        }}>
          {t("main_page.button.search")}
        </Button>
      }
    </Paper >
  );
};
