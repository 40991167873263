import { Accordion, Box, Flex, Paper, Text, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllOfficesQuery } from 'src/store/reducers/all-reducers/officeApiSlice';
import { MainMap } from './main-map';
import styles from './map.module.scss';

export const TerritorialDepartments = () => {
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(8);
  const [offices, setOffices] = useState<OfficeType[] | undefined>([]);
  const { data: officesData } = useGetAllOfficesQuery([])

  useEffect(() => {
    const regions = officesData?.map((items) => {
      return {
        content: [items],
        regionId: items.region?.id,
        mapPointName: i18n.language === 'ru' ? items.region?.titleRu : i18n.language === 'kg' ? items.region?.titleKg : items.titleEn,
      }
    })

    const filteredData = regions?.reduce((acc: OfficeType[], currentObj: OfficeType) => {
      const existId = acc.find((obj) => obj.regionId === currentObj.regionId)

      if (existId) {
        existId.content = existId.content.concat(currentObj.content)
      } else {
        acc.push({ regionId: currentObj.regionId, mapPointName: currentObj.mapPointName, content: [...currentObj.content] })
      }
      return acc
    }, [])

    setOffices(filteredData as unknown as OfficeType[])
  }, [officesData])

  return (
    <Box mb={{ base: 20, xs: 80 }}>
      <Title mb={30} fz={{ base: 22, md: 32 }} c="#040A0B">
        {t('titles.territory-organs')}
      </Title>
      <Flex align="start" justify={{ base: 'center', xl: 'space-between' }} pos="relative" w="100%" direction={{ base: 'column', xl: 'row' }}>
        <MainMap selected={selected} setSelected={setSelected} />
        {offices?.map((office) => office.regionId === selected && (
          <Paper mx="auto" key={office.regionId} variant="bg-white" w={{ base: '100%', md: '80%', xl: '60%' }}
            style={{ padding: '1.25rem', boxShadow: '0px 4px 24px 0px rgba(106, 122, 152, 0.15)', }} mah={{ base: 300, md: 430 }}>
            <Title fz={{ base: "1rem", xs: "1.15rem", md: "1.5rem" }} c="secondary" order={2} fw="bold" mb="2rem">
              {office.mapPointName}
            </Title>
            <Accordion classNames={{ chevron: styles.chevron, label: styles.label, control: styles.control }} style={{ overflow: "scroll" }} mah={{ base: 200, md: 350 }}>
              {office?.content?.map((item: OfficeResponseType) => (
                <Accordion.Item key={item.id} value={`${item.id}`}>
                  <Accordion.Control>
                    <Title fz={{ base: "0.8rem", xs: "1rem", md: "1.25rem" }} style={{ color: '#101828' }} fw={500}>
                      {i18n.language === 'ru' ? item?.titleRu : i18n.language === 'kg' ? item?.titleKg : item?.titleEn}
                    </Title>
                  </Accordion.Control>
                  <Accordion.Panel style={{ color: '#101828' }}>
                    <Accordion variant="">
                      {item.branches.map((offices, index) => (
                        <Accordion.Item key={index} value={`${offices.titleRu}`}>
                          <Accordion.Control>
                            <Title fz={{ base: "0.7rem", xs: "0.9rem", md: "1.15rem" }} style={{ color: '#101828' }} fw={500}>
                              {i18n.language === 'ru' ? offices?.titleRu : i18n.language === 'kg' ? offices?.titleKg : offices?.titleEn}
                            </Title>
                            <Text fz={{ base: "0.65rem", xs: "0.85rem", md: "1.05rem" }} lh={1} c="#a7a8ab">
                              {i18n.language === 'ru' ? offices?.addressRu : i18n.language === 'kg' ? offices?.addressKg : offices?.addressEn}
                            </Text>
                          </Accordion.Control>
                          <Accordion.Panel style={{ color: '#101828' }}>
                            <Text fz={{ base: "0.60rem", xs: "0.8rem", md: "1rem" }} style={{ color: '#101828' }}>{offices.phone}</Text>
                            <Text fz={{ base: "0.60rem", xs: "0.8rem", md: "1rem" }} style={{ color: '#101828' }}>{offices.email}</Text>
                          </Accordion.Panel>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Paper>
        ))}
      </Flex>
    </Box>
  );
};
