import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { IconMoon, IconSunFilled } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

export const DarkTheme = () => {
  const { t } = useTranslation();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'light';
  const { classes } = useStyles({ dark });

  useHotkeys([['ctrl+k', () => toggleColorScheme()]]);

  return (
    <ActionIcon
      variant="subtle"
      className={classes.icon}
      onClick={() => toggleColorScheme()}
      title={dark ? t`lightTheme` : t`darkTheme`}
    >
      {dark ? <IconSunFilled size={20} /> : <IconMoon size={20} />}
    </ActionIcon>
  );
};

const useStyles = createStyles((_theme, { dark }: { dark: boolean }) => ({
  icon: {
    color: dark ? 'yellow' : '#000',
    '&:hover': {
      backgroundColor: 'rgba(128, 128, 128, 0.1)',
    },
  },
}));
