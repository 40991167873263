// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown_item__EW6lN {
  transition: 350ms;
}
.dropdown_item__EW6lN:hover {
  background: var(--weak-white);
}`, "",{"version":3,"sources":["webpack://./src/ui-kit/menu-drop-down/dropdown.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;AAAE;EACE,6BAAA;AAEJ","sourcesContent":[".item {\n  transition: 350ms;\n  &:hover {\n    background: var(--weak-white);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": `dropdown_item__EW6lN`
};
export default ___CSS_LOADER_EXPORT___;
