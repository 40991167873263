export { PrivatePage } from './private-page/PrivatePage';
export {
  SocialLinksPage,
  AddLink,
  EditSocialLinks,
  SocialLinkView,
} from './social-links';
export { News, AddNews, EditNews, NewsView } from './news';
export { Page, AddPage, EditPage } from './create-page';
export { SignInPage } from './sign-in-page';
export { Profile } from './profile';
export {
  AddCardInfo,
  CardInfo,
  CardInfoView,
  EditCardInfo,
} from './cards-info';

export { AddOffice, EditOffice, Office, OfficeView } from './office';
