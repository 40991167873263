import { Navigate, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'src/hooks';

type PrivatePageProps = {
  isAllowed: Role[];
  children: JSX.Element;
};

export const PrivatePage = ({ isAllowed, children }: PrivatePageProps) => {
  const { profile } = useTypedSelector((state) => state.auth);
  const location = useLocation();

  const isAllowedTrue = (element: string) => {
    return isAllowed.includes(element);
  };

  return profile && profile?.roles?.some(isAllowedTrue) ? (
    children
  ) : (
    <Navigate to="/sign-in" state={{ from: location }} />
  );
};
