import { Flex, FlexProps, Text } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { RouteWithLang } from "src/components";
import { excludedItemsForBreadCrumbs } from "src/constants";
import { useFilterPathname } from "src/hooks";

interface IProps {
  title?: string;
  styles?: FlexProps;
  isPage?: boolean;
}

export const RouteTitles = ({
  title = "",
  styles = { mt: 20, mb: 32 },
  isPage = false
}: IProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { filteredPath } = useFilterPathname({ pathname, exclude: excludedItemsForBreadCrumbs })
  filteredPath[0] = "main";
  if (title) {
    filteredPath[filteredPath.length - 1] = title
  }

  return (
    <Flex mx="0" align="center" gap={10} {...styles}>
      {filteredPath.map((el, idx) => {
        const isNotLast = filteredPath.length !== idx + 1;
        const isIdLink = !isNaN(Number(el)) && t(`pages.${el}`) === "";
        const pathTitle = isIdLink || (title && !isNotLast) ? title : t(`pages.title.${/^\d+$/.test(el) ? 'previous' : el}`)
        return (
          <Flex key={el} align="center" justify="center" gap={{ base: 5, sm: 15 }}>
            <RouteWithLang to={el === "main" ? `/` : isPage ? `/pages/${el}` : `/${el}`}
              onClick={(event) => !isNotLast && event.preventDefault()} style={{ cursor: isNotLast ? "pointer" : "default" }}>
              <Text fz={{ base: 12, xs: 16 }} c={isNotLast ? "rgb(6, 41, 123)" : "dark"}>
                {pathTitle}
              </Text>
            </RouteWithLang>
            {isNotLast ? (<IconChevronRight color="#797E8B" size={20} />) : null}
          </Flex>
        );
      })}
    </Flex>
  );
};
