import { Box, Center, Grid, Image, Loader, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { createStyles } from '@mantine/styles';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoSystemBG } from 'src/assets';
import { useSortByField } from 'src/hooks';
import { useSearchPageMutation } from 'src/store/reducers/all-reducers/pageApiSlice';
import { NoDataUi } from '../no-data-ui';
import { InfoSystemCard } from './info-systems-card';

export const InfoSystems = () => {
  const middleScreen = useMediaQuery("(max-width: 991px)")
  const mobileScreen = useMediaQuery("(max-width: 600px)")
  const [pageData, setPageData] = useState<PageType[]>([]);
  const { classes } = useStyles();
  const { i18n, t } = useTranslation()

  const [getPagesByType, { isLoading }] = useSearchPageMutation();

  const getSocialLinks = useCallback(async () => {
    try {
      const response = await getPagesByType({ filter: { pageListPosition: 'DIGITAL_MINJUST', }, pageRequest: { limit: 20, }, });
      if ('data' in response && 'content' in response.data) setPageData(response.data.content);
    } catch (error) {
      console.log(error);
    }
  }, [getPagesByType]);

  useEffect(() => {
    getSocialLinks();
  }, [getSocialLinks]);

  const sortedData: PageType[] = useSortByField(pageData, "significance")
  return (
    <Box className={classes.mainContainer}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '2.5rem' }} p={{ base: '1rem 5%', xs: '1rem 7%' }}>
        <Text fw={500} fz={{ base: 22, md: 32 }}>
          {t("main_page.title.digital_minjust")}
        </Text>
      </Box>
      {mobileScreen
        ? <Image src={InfoSystemBG} className={classes.bgimage3} />
        : <Image src={InfoSystemBG} className={middleScreen ? classes.bgimage2 : classes.bgimage} />
      }
      <Grid gutter={{ base: "1rem" }} mih="70dvh" p={{ base: '0 5%', xs: '0 7%' }}>
        {isLoading ?
          <Grid.Col span={12} mt={100}>
            <Center>
              <Loader color="blue" />
            </Center>
          </Grid.Col>
          :
          <>
            {!sortedData || sortedData.length === 0
              ?
              <Grid.Col span={12}>
                <NoDataUi />
              </Grid.Col>
              :
              sortedData?.map(
                (pages) =>
                  pages.pageListPosition === 'DIGITAL_MINJUST' && (
                    <Grid.Col key={pages.id} span={{ base: 12, sm: 6, md: 4, lg: 3 }} style={{ display: 'flex', justifyContent: 'center', zIndex: 20 }}>
                      <InfoSystemCard
                        link={pages.link}
                        text={i18n.language === 'ru' ? pages.content?.titleRu : i18n.language === 'kg' ? pages.content?.titleKg : pages.content?.titleEn}
                        title={i18n.language === 'ru' ? pages?.titleRu : i18n.language === 'kg' ? pages?.titleKg : pages?.titleEn}
                        pageId={pages.id}
                        photo={pages.file}
                      />
                    </Grid.Col>
                  ),
              )
            }
          </>
        }
      </Grid>
    </Box>
  );
};

const useStyles = createStyles(() => ({
  mainContainer: {
    marginBottom: '60px',
    color: '#040A0B',
    padding: '0.625rem 0 1.875rem 0',
    position: "relative",
    zIndex: 10,
  },
  bgimage: {
    position: 'absolute',
    zIndex: -1,
  },
  bgimage2: {
    position: 'absolute',
    zIndex: -1,
    top: "50%"
  },
  bgimage3: {
    position: 'absolute',
    zIndex: -1,
    bottom: 0
  },
}));
