import { Carousel } from '@mantine/carousel';
import { Box, Flex, Image, Title } from '@mantine/core';
import { createStyles } from '@mantine/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RoutesCardBG } from 'src/assets';
import { NewsCard, RouteWithLang, ShareLinks } from 'src/components';
import { getTitleByLanguage } from 'src/locales';
import { useGetPhotosQuery } from 'src/store/reducers/all-reducers/galleryApiSlice';
import { useGetNewsQuery, useNewsSearchMutation } from 'src/store/reducers/all-reducers/newsApiSlice';
import { PhotoCollage, RouteTitles } from 'src/ui-kit';

interface IHTMLRenderProps {
  htmlContent: string | undefined;
}

const HTMLRender = ({ htmlContent }: IHTMLRenderProps) => {
  if (!htmlContent) {
    return null;
  }
  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export const NewsDetail = () => {
  const [allPhotos, setAllPhotos] = useState<PhotoType[] | undefined>(undefined);
  const { i18n } = useTranslation();
  const { newsId } = useParams<string>();
  const { classes } = useStyles();

  const [searchNews, { data: newsData }] = useNewsSearchMutation();
  const { data: newsById } = useGetNewsQuery(`${newsId}`, { refetchOnMountOrArgChange: true, });
  const { data: allPhotosData } = useGetPhotosQuery(Number(newsById?.photoAlbum?.id), { skip: !newsById?.photoAlbum?.id })

  useEffect(() => {
    try {
      searchNews({
        filter: {},
        pageRequest: { limit: 6, page: 0 },
        sorting: {
          sortBy: "PUBLISHED_AT",
          sortDirection: "DESC"
        }
      });
    } catch (error) {
      console.error('Error in fetching newsData');
    }
    if (newsById?.photoAlbum && newsById?.photoAlbum?.id) {
      setAllPhotos(allPhotosData);
    } else {
      setAllPhotos(undefined);
    }
  }, [searchNews, newsById, allPhotosData]);

  return (
    <Box mb={50} className={classes.mainContainer}>
      <RouteTitles title={`${getTitleByLanguage(newsById).slice(0, 20)}...`} />
      <Title fz={{ base: 26, md: 32 }} c="#101828" mb="2rem" fw={500} style={{ lineHeight: '40px' }}>
        {i18n.language === 'ru' ? newsById?.titleRu : i18n.language === 'kg' ? newsById?.titleKg : newsById?.titleEn}
      </Title>
      <Flex direction={{ base: 'column', lg: 'row' }} className={classes.contentContainer}>
        <Image src={RoutesCardBG} className={classes.bgimage} />
        <Box w={{ base: '100%', lg: '66%' }} className={classes.eventsContainer}>
          {allPhotos && <PhotoCollage photos={allPhotos} className={classes.bgimage} />}
          <Box fz="22px" fw={400} c="#101828" style={{ lineHeight: '28px', paddingTop: '2.5rem', overflow: "hidden" }}>
            {newsById && (
              <HTMLRender htmlContent={i18n.language === 'ru' ? newsById.content.titleRu : i18n.language === 'kg' ? newsById.content.titleKg : newsById.content.titleEn} />
            )}
          </Box>
          <Box>
            <ShareLinks data={newsById} />
          </Box>
        </Box>
        <Box display={{ base: 'none', lg: 'flex' }} className={classes.newsContainer}>
          {newsData?.content.map((items) => (
            <RouteWithLang to={`/public-news/${items.id}`} key={items.id}>
              <NewsCard date={items.dateFrom} title={i18n.language === 'ru' ? items.titleRu : i18n.language === 'kg' ? items.titleKg : items.titleEn} isPhoto={false} />
            </RouteWithLang>
          ))}
        </Box>
        <Carousel
          display={{ base: 'block', lg: 'none' }}
          orientation="horizontal"
          withControls={false}
          withIndicators={false}
          slideSize={'460px'}
          w="100%"
          slideGap="md"
          loop
          align="start"
          slidesToScroll={1}
        >
          {newsData?.content.map((item) => (
            <Carousel.Slide key={item.id}>
              <RouteWithLang to={`/public-news/${item.id}`} key={item.id}>
                <NewsCard date={item.dateFrom} title={i18n.language === 'ru' ? item.titleRu : i18n.language === 'kg' ? item.titleKg : item.titleEn} isPhoto={false} />
              </RouteWithLang>
            </Carousel.Slide>
          ))}
        </Carousel>
      </Flex>
    </Box>
  );
};

const useStyles = createStyles(() => ({
  breadCrumb: {
    padding: '3rem 0 5rem 0',
  },
  mainContainer: {
    width: '100%',
    minHeight: '50dvh',
    padding: '0 7% 0 7%',
  },
  bgimage: {
    position: 'fixed',
    width: '717px',
    right: -15,
    zIndex: 0,
  },
  headContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  contentContainer: {
    gap: '2.5rem',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    position: 'relative',
    overflow: 'hidden',
    minHeight: '70dvh',
  },
  eventsContainer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    marginBottom: "8dvw"
  },
  newsContainer: {
    zIndex: 1,
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
}));
