import React, { useEffect, useRef, useState } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import BlotFormatter from 'quill-blot-formatter';
import { Text } from '@mantine/core';

type Props = {
  label?: string | null;
  labelButton?: React.ReactNode;
  onChange: (value: string) => void;
  error?: React.ReactNode;
  required?: boolean;
  formData?: string | null;
  style?: { [key: string]: string | number };
};

export const TextEditor = ({
  label,
  required,
  onChange,
  formData,
  error,
  labelButton,
  style,
}: Props) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: { image: { resize: {} } } },
  });

  const editorContent = useRef<string | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  if (Quill && !quill) {
    Quill.register('modules/blotFormatter', BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      const handleTextChange = () => {
        const editorHtml = quill.root.innerHTML;
        onChange(editorHtml);
      };
      quill.on('text-change', handleTextChange);

      if (formData && isInitialLoad) {
        setIsInitialLoad(false);
        editorContent.current = formData || null;
        if (quill && editorContent.current) {
          const delta = quill.clipboard.convert(editorContent.current);
          quill.setContents(delta);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quill]);

  return (
    <>
      <Text style={{ fontWeight: 600, fontSize: 12 }}>
        {label}
        <span style={{ color: 'red' }}>{required && '*'}</span>
        {labelButton}
      </Text>
      <div>
        <div style={style} ref={quillRef} />
      </div>
      {error && (
        <Text
          color="red"
          style={{
            lineHeight: '1.2',
            fontSize: 'calc(0.875rem - 0.125rem)',
          }}
        >
          {error}
        </Text>
      )}
    </>
  );
};
