import { useState } from 'react';
import { IconEye, IconPencil, IconTrash } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Title } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useNavigate } from 'react-router-dom';
import { AppTable, CustomModal, MenuDropDown } from 'src/ui-kit';
import { Path } from 'src/utils';
import { getTitleByLanguage } from 'src/locales';
import { useDeleteCardMutation } from 'src/store/reducers/all-reducers/cardApiSlice';
import { HeadCell } from 'src/types/global';

export const CardInfoTable = ({ filter }: { filter?: CardInfoFilterType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [cardDelete] = useDeleteCardMutation();

  const [id, setId] = useState<number | null>();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (id: number) => {
    setOpen(true);
    setId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    await cardDelete(id)
      .unwrap()
      .then(() => {
        showNotification({
          title: t`notification.success`,
          message: t`notification.updateMsg`,
          color: 'green',
        });
      })
      .catch((e: ErrorType) => {
        showNotification({
          title: t`notification.error`,
          message: e?.data.message,
          color: 'red',
        });
      });
    setOpen(false);
  };

  const actions = (id: number) => [
    {
      icon: IconPencil,
      title: t`actions.news.edit`,
      to: `/card_info/${id}/edit`,
    },
    {
      icon: IconEye,
      title: t`actions.news.view`,
      to: `/card_info/${id}`,
    },
    {
      icon: IconTrash,
      title: t`actions.news.delete`,
      onClick: () => handleOpen(id),
    },
  ];

  const headCells: HeadCell<CardType>[] = [
    {
      label: t`table.fullName`,
      render: (n) => getTitleByLanguage(n.fullName),
    },
    {
      label: t`table.position`,
      render: (n) => getTitleByLanguage(n.position),
    },
    {
      label: t`actions.title`,
      render: (n) => (
        <MenuDropDown label={t`actions.title`} item={actions(n.id)} />
      ),
    },
  ];

  return (
    <>
      <AppTable<CardType>
        headCells={headCells}
        doubleClickPath={(n) => navigate(`${n.id}`)}
        searchApiUrl={Path.CardInfo.search}
        extraFilter={filter}
      />
      <CustomModal
        labelSubmit={'card.delete'}
        open={open}
        handleClose={handleClose}
        onClick={handleDelete}
      >
        <Title
          order={3}
          fw={400}
          mt="5px"
          mb="2rem"
          style={{ textAlign: 'center' }}
        >
          {t`modal.deleted.cards`}
        </Title>
      </CustomModal>
    </>
  );
};
