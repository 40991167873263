import { apiSlice } from 'src/store/apiSlice';
import { Path } from 'src/utils';

const hiddenPagaApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getHiddenPaheById: builder.query<PageType, string>({
      query: (code) => ({
        url: Path.HiddenPageList.getHiddenPageById(code),
      }),
    }),
  }),
});

export const { useGetHiddenPaheByIdQuery } = hiddenPagaApiSlice;
